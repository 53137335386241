import {
  accessoriesActions,
  ADD_ACCESSORY_REQUEST,
  EDIT_ACCESSORY_REQUEST
} from "../actions/accessories.actions";
import {
  ADD_CLIENT_REQUEST,
  clientsActions,
  EDIT_CLIENT_REQUEST
} from "../actions/clients.actions";
import { csvActions, DOWNLOAD_CSV_REQUEST, UPLOAD_CSV_REQUEST, UPLOAD_ESTIMATIF_CSV_REQUEST } from "../actions/csv.actions";
import {
  ADD_INSULATION_REQUEST,
  DISABLE_INSULATION_REQUEST,
  EDIT_INSULATION_REQUEST,
  insulationsActions
} from "../actions/insulations.actions";
import { ADD_MEDIA_REQUEST, mediaActions } from "../actions/media.actions";
import { EDIT_PRICE_REQUEST, pricesActions } from "../actions/price.actions";
import {
  EDIT_PRICEESTIMATED_REQUEST,
  pricesEstimatedActions
} from "../actions/priceEstimated.actions";
import {
  EDIT_PRODUCT_REQUEST,
  productsActions
} from "../actions/product.actions";
import {
  ADD_PROJECT_REQUEST,
  EDIT_PROJECT_REQUEST,
  PROJECT_THERMO_CALCULATE_ESTIMATE_REQUEST,
  projectsActions
} from "../actions/projects.actions";
import {
  EDIT_QUOTATION_REQUEST,
  quotationsActions
} from "../actions/quotations.actions";
import {
  EDIT_SETTING_REQUEST,
  settingsActions
} from "../actions/setting.actions";
import {
  EDIT_SHIPPING_REQUEST,
  shippingsActions
} from "../actions/shippings.actions";
import { EDIT_SLOPE_REQUEST, slopesActions } from "../actions/slopes.actions";
import {
  ADD_USER_REQUEST,
  EDIT_USER_REQUEST,
  usersActions
} from "../actions/users.actions";
import {
  EDIT_PRODUCT_VARIANT_REQUEST,
  productsVariantsActions
} from "../actions/productVariant.actions";
import {
  ADD_PRODUCT_OPTION_REQUEST,
  EDIT_PRODUCT_OPTION_REQUEST,
  DISABLE_PRODUCT_OPTION_REQUEST,
  productOptionsActions
} from "../actions/productOption.actions";
import { ADD_PRODUCT_COLOR_REQUEST, DISABLE_PRODUCT_COLOR_REQUEST, EDIT_PRODUCT_COLOR_REQUEST, productColorsActions } from "../actions/productColor.actions";

const actions = {
  [ADD_PROJECT_REQUEST]: projectsActions.add,
  [EDIT_PROJECT_REQUEST]: projectsActions.edit,
  [ADD_USER_REQUEST]: usersActions.add,
  [EDIT_USER_REQUEST]: usersActions.edit,
  [ADD_CLIENT_REQUEST]: clientsActions.add,
  [EDIT_CLIENT_REQUEST]: clientsActions.edit,
  [ADD_MEDIA_REQUEST]: mediaActions.add,
  [EDIT_ACCESSORY_REQUEST]: accessoriesActions.edit,
  [ADD_ACCESSORY_REQUEST]: accessoriesActions.add,
  [ADD_INSULATION_REQUEST]: insulationsActions.add,
  [EDIT_INSULATION_REQUEST]: insulationsActions.edit,
  [DISABLE_INSULATION_REQUEST]: insulationsActions.disable,
  [EDIT_PRICE_REQUEST]: pricesActions.edit,
  [EDIT_PRICEESTIMATED_REQUEST]: pricesEstimatedActions.edit,
  [EDIT_SHIPPING_REQUEST]: shippingsActions.edit,
  [UPLOAD_CSV_REQUEST]: csvActions.upload,
  [UPLOAD_ESTIMATIF_CSV_REQUEST]: csvActions.upload_estimatif,
  [DOWNLOAD_CSV_REQUEST]: csvActions.download,
  [EDIT_PRODUCT_REQUEST]: productsActions.edit,
  [EDIT_QUOTATION_REQUEST]: quotationsActions.edit,
  [EDIT_SLOPE_REQUEST]: slopesActions.edit,
  [EDIT_SETTING_REQUEST]: settingsActions.edit,
  [EDIT_PRODUCT_VARIANT_REQUEST]: productsVariantsActions.edit,
  [PROJECT_THERMO_CALCULATE_ESTIMATE_REQUEST]: projectsActions.calculateThermoEstimate,
  [ADD_PRODUCT_OPTION_REQUEST]: productOptionsActions.add,
  [EDIT_PRODUCT_OPTION_REQUEST]: productOptionsActions.edit,
  [DISABLE_PRODUCT_OPTION_REQUEST]: productOptionsActions.disable,
  [ADD_PRODUCT_COLOR_REQUEST]: productColorsActions.add,
  [EDIT_PRODUCT_COLOR_REQUEST]: productColorsActions.edit,
  [DISABLE_PRODUCT_COLOR_REQUEST]: productColorsActions.disable,
};

// A Redux middleware that interprets actions that comes from react-redux-promise-listener.
export default (store) => (next) => (action) => {
  if (action && action.type && actions[action.type]) {
    return next(actions[action.type](action.payload));
  }

  return next(action);
};
