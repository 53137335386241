import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Close, Sync } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import arrayMutators from "final-form-arrays";
import { Select, TextField } from "mui-rff";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { accessoriesActions } from "../../../actions/accessories.actions";
import { appActions } from "../../../actions/app.actions";
import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction,
} from "../../../actions/notifications.actions";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../actions/projects.actions";
import StepperButtons from "../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../components/projects/StepperHeader";
import ValidationModal from "../../../components/ValidationModal";
import { getProjectStep, useStyles } from "../../../helpers/project";
import { selectProject } from "../../../helpers/schema";
import { promiseListener } from "../../../helpers/store";
import { productColorsActions } from "../../../actions/productColor.actions";

const avatarUseStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export default function Step8() {
  let { projectId } = useParams();
  const [showConfirmRecalculateModal, setShowConfirmRecalculateModal] =
    useState(false);
  const [calculatingAccessories, setCalculatingAccessories] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [compatibleAccessories, setCompatibleAccessories] = useState(false);
  const [fetchingCompatibleAccessories, setFetchingCompatibleAccessories] =
    useState(false);
  const classes = useStyles();
  const avatarClasses = avatarUseStyles();
  const [ral, setRal] = useState(null);

  const dispatch = useDispatch();
  const [activeStep] = React.useState(7);
  let submit;

  const isFetchingAccessories = useSelector((state) => {
    return state.accessories.isFetching;
  }, shallowEqual);

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  }, shallowEqual);

  const productColors = useSelector((state) => {
    return Object.values(state.productColors.all) || [];
  }, shallowEqual);

  productColors.unshift({ ral: null, name: "Tous les RAL / Sans RAL" });

  useEffect(() => {
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(accessoriesActions.fetchAccessoriesIfNeeded());
    dispatch(appActions.setNextPage("/renov/projects/{currentProject}/step9"));
    dispatch(appActions.setCurrentProject(projectId));
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
  }, [dispatch, projectId, isFetchingProject]);

  if (!compatibleAccessories && !fetchingCompatibleAccessories) {
    setFetchingCompatibleAccessories(true);
    dispatch(projectsActions.getAvailableAccessories(projectId)).then(
      (data) => {
        setCompatibleAccessories(data.payload);
      }
    );
  }

  const manualAccessories =
    !isFetchingAccessories &&
      !isFetchingProject &&
      compatibleAccessories?.length
      ? compatibleAccessories.reduce((array, accessory) => {
        const alreadyDefinedAccessory = project?.accessories.length
          ? project?.accessories.find(
            (entry) => entry.accessory.id === accessory.id
          )
          : false;

        array.push({
          ...accessory,
          defined: !!alreadyDefinedAccessory,
          quantity: alreadyDefinedAccessory?.quantity
            ? alreadyDefinedAccessory.quantity
            : 0,
        });

        return array;
      }, [])
      : [];

  /* Accessoires sans category en premier */
  manualAccessories.sort((x, y) => {
    return x?.category === undefined ? -1 : 1;
  });

  const calculateAccessories = async () => {
    setCalculatingAccessories(true);
    const result = await dispatch(
      projectsActions.calculateAccessories(projectId)
    );
    setCalculatingAccessories(false);
    setShowConfirmRecalculateModal(false);
    dispatch(closeSnackbarAction("step8_outdatedAccessoriesCalculation"));

    if (result.status === 200) {
      setCalculated(true);
    }
  };

  if (project.id && !calculatingAccessories && !calculated) {
    calculateAccessories();
  }

  if (
    project?.accessoriesOutdated &&
    project?.accessories?.length &&
    !displayAlert
  ) {
    setDisplayAlert(true);
    dispatch(
      enqueueSnackbarAction({
        message:
          'Le calpinage a été modifié. Le dernier calcul d\'accessoires est donc périmé. Cliquez sur "Actualiser" pour relancer le calcul.',
        options: {
          key: "step8_outdatedAccessoriesCalculation",
          autoHideDuration: null,
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          action: (key) => (
            <React.Fragment>
              <Button
                variant="contained"
                color={"primary"}
                onClick={() => setShowConfirmRecalculateModal(true)}
                disabled={calculatingAccessories}
              >
                {!calculatingAccessories && (
                  <>
                    <Sync /> Actualiser
                  </>
                )}
                {calculatingAccessories && <CircularProgress size={20} />}
              </Button>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={() => dispatch(closeSnackbarAction(key))}
              >
                <Close />
              </IconButton>
            </React.Fragment>
          ),
        },
      })
    );
  }

  if (!isFetchingProject && project.name && getProjectStep(project) < 7) {
    return (
      <Redirect to={`/renov/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  return (
    <>
      {isFetchingAccessories ||
        (isFetchingProject && !project.name && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        ))}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {!isFetchingAccessories && !isFetchingProject && project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={{ manualAccessories }}
              onSubmit={(form) => {
                let accessories = form.manualAccessories.map(
                  (accessory, id) => {
                    return {
                      id: accessory.defined
                        ? `${project.id}-${accessory.id}`
                        : undefined,
                      accessory: {
                        id: accessory.id,
                      },
                      quantity: parseFloat(accessory.quantity),
                    };
                  }
                );

                accessories = accessories.filter((el) => el.quantity !== 0);

                return onSubmit({ accessories });
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
                ...arrayMutators,
              }}
              render={({
                handleSubmit,
                submitting,
                values,
                pristine,
                form: {
                  mutators: { push },
                },
              }) => {
                submit = handleSubmit;
                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={parseInt(projectId)}
                      submitting={submitting}
                      shouldLink={pristine}
                      disabled={manualAccessories.length === 0}
                    />
                    <Container>
                      <form className={classes.form} onSubmit={handleSubmit}>
                        <Grid
                          container
                          spacing={5}
                          className={"mb-4"}
                          item
                          xs={12}
                        >
                          {/* <Grid item xs={12}>
                            <Box textAlign="center" mt={5}>
                              <Typography
                                variant="h6"
                                display="block"
                                gutterBottom
                              >
                                Accessoires calculés automatiquement
                              </Typography>
                              <Typography
                                variant="body1"
                                display="block"
                                gutterBottom
                              >
                                Certains accessoires sont calculés
                                automatiquement. Le calcul se base sur les
                                informations définies dans les étapes
                                précédentes.
                              </Typography>
                              <Typography
                                variant="body1"
                                display="block"
                                gutterBottom
                              >
                                Le calcul est automatiquement lancé lorsque le
                                projet arrive pour la première fois à l'étape 8.
                                Par la suite il n'est plus lancé automatiquement
                                afin de conserver vos modifications.
                              </Typography>
                              <Typography
                                variant="body1"
                                display="block"
                                gutterBottom
                              >
                                Si vous avez effectué des modifications récentes
                                dans les étapes précédentes du projet, il se
                                peut que vous deviez relancer le calcul
                                automatique. Pour cela, cliquez sur le bouton
                                ci-dessous :
                              </Typography>
                            </Box>
                          </Grid> */}

                          <Grid item xs={12}>
                            {/* <hr /> */}
                            <Box textAlign="center">
                              <Typography
                                variant="h6"
                                display="block"
                                gutterBottom
                              >
                                Accessoires ajoutés manuellement
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                display="block"
                                gutterBottom
                              >
                                Compatibles avec le projet
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid container justify="center">
                            <Grid
                              className={"mb-4"}
                              item
                              md={6}
                              xs={12}
                            >
                              <Select
                                label="Filtrer par RAL"
                                name="ral"
                                data={productColors.map(color => {
                                  return {
                                    label: color.name,
                                    value: color.ral
                                  };
                                })}
                                onChange={(event) => setRal(event.target.value)}
                                value={ral}
                              />
                            </Grid>
                          </Grid>

                          {!manualAccessories.length && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              flexGrow={1}
                              mb={5}
                            >
                              <h3>Aucun accessoire.</h3>
                              <span severity="error">
                                Aucun accessoires compatible avec ce projet.
                                Vérifiez le choix de l'isolation et du produit.
                              </span>
                            </Box>
                          )}

                          {!!manualAccessories.length && (
                            <FieldArray name="manualAccessories">
                              {({ fields }) =>
                                fields.map((accessory, index) => (
                                  <React.Fragment key={index}>
                                    {index === 0 || (manualAccessories[index - 1]?.category
                                      ?.name !==
                                      manualAccessories[index]?.category?.name) ? (
                                      <Grid item xs={12}>
                                        {index !== 0 && <hr />}
                                        <Typography
                                          variant="subtitle1"
                                          display="block"
                                          gutterBottom
                                        >
                                          {manualAccessories[index]?.category
                                            ?.name !== undefined
                                            ? `Catégorie ${manualAccessories[index].category?.name}`
                                            : "Sans catégorie"}
                                        </Typography>
                                      </Grid>
                                    ) : (
                                      ""
                                    )}
                                    <Grid item lg={4} md={4} xs={4} style={{ display: (!ral || (ral && fields.value[index].ral === ral) ? 'block' : 'none') }}>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexGrow={1}
                                        p={2}
                                      >
                                        <Box>
                                          <Avatar
                                            alt={
                                              manualAccessories[index].name +
                                              "icon"
                                            }
                                            variant="square"
                                            src={
                                              process.env.REACT_APP_API_URL +
                                              manualAccessories[index]?.image
                                                ?.contentUrl
                                            }
                                            className={avatarClasses.large}
                                          />
                                        </Box>

                                        <Typography
                                          variant="caption"
                                          display="block"
                                          gutterBottom
                                        >
                                          {manualAccessories[index].name}
                                        </Typography>

                                        <TextField
                                          type="number"
                                          variant="filled"
                                          label={`Ref. ${manualAccessories[index].reference}`}
                                          name={`${accessory}.quantity`}
                                        />
                                      </Box>
                                    </Grid>
                                  </React.Fragment>
                                ))
                              }
                            </FieldArray>
                          )}
                        </Grid>

                        <StepperButtons
                          submit={async () => {
                            await submit().then(async () => {
                              await calculateAccessories();
                            });
                          }}
                          activeStep={activeStep}
                          projectId={projectId}
                          submitting={submitting}
                          shouldLink={pristine}
                          disabled={manualAccessories.length === 0}
                        />
                      </form>
                      <ValidationModal
                        open={showConfirmRecalculateModal}
                        onClose={() => setShowConfirmRecalculateModal(false)}
                        title={
                          "Voulez-vous vraiment regénérer les accessoires ?"
                        }
                        paragraph={
                          "Vous allez perdre vos modifications concernant la quantité. Les nouvelles quantités appliquées seront calculées automatiquement en fonction du projet."
                        }
                        loading={calculatingAccessories}
                        onClick={calculateAccessories}
                      />
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
