import { Grid } from "@material-ui/core";
import { TextField } from "mui-rff";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { productOptionsActions } from "../../../../actions/productOption.actions";

const formFields = [
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <TextField label="Reference" name="reference" margin="none" disabled />
    ),
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: <TextField label="Nom" name="name" margin="none" required />,
  }
];

export default function OptionsForm(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productOptionsActions.fetchProductOptionsIfNeeded());
  }, [dispatch]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={8} container spacing={2}>
        {formFields.map((item, idx) => (
          <Grid
            className={"mb-4"}
            item
            md={item.mdSize}
            xs={item.xsSize}
            key={idx}
          >
            {item.field}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}