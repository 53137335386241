import {
    ROLE_ADMIN as ADMIN_LABEL,
    ROLE_MERCHANT as MERCHANT_LABEL,
    ROLE_ROOFER as ROOFER_LABEL,
    ROLE_SALESPERSON as SALESPERSON_LABEL
} from "./constante";

export const ROLE_ADMIN = "ROLE_ADMIN"
export const ROLE_SALESPERSON = "ROLE_SALESPERSON"
export const ROLE_ROOFER = "ROLE_ROOFER"
export const ROLE_MERCHANT = "ROLE_MERCHANT"
export const ROLE_PRODUCT_RENOV = "ROLE_PRODUCT_RENOV"
export const ROLE_PRODUCT_THERMO = "ROLE_PRODUCT_THERMO"

export function getUser() {
    return JSON.parse(localStorage.getItem('user'));
}

export const isAdmin = () => getUserRoles().includes(ROLE_ADMIN);
export const isSalesperson = () => getUserRoles().includes(ROLE_SALESPERSON);
export const isMerchant = () => getUserRoles().includes(ROLE_MERCHANT);
export const isRoofer = () => getUserRoles().includes(ROLE_ROOFER);
export const hasProductRenov = () => getUserRoles().includes(ROLE_PRODUCT_RENOV);
export const hasProductThermo = () => getUserRoles().includes(ROLE_PRODUCT_THERMO);

export function getUserRole() {
    let user = localStorage.getItem('user')

    if (user) {
        user = JSON.parse(user);

        if (user.decoded.roles.includes(ROLE_ADMIN)) return ROLE_ADMIN
        else if (user.decoded.roles.includes(ROLE_SALESPERSON)) return ROLE_SALESPERSON
        else if (user.decoded.roles.includes(ROLE_MERCHANT)) return ROLE_MERCHANT
        else if (user.decoded.roles.includes(ROLE_ROOFER)) return ROLE_ROOFER
    }

    return false;
}

export function getUserRoles() {
    let user = localStorage.getItem('user')

    if (user) {
        user = JSON.parse(user);

        return user.decoded.roles;
    }

    return [];
}

export function getUserProducts() {
    let products = [];

    for (let i = 0, roles = getUserRoles(); i < roles.length; i++) {
        if (roles[i].startsWith('ROLE_PRODUCT')) {
            products.push(roles[i]);
        }
    }

    return products;
}

export function getAvailableRoleForNewUser() {
    switch (getUserRole()) {
        case ROLE_ADMIN:
            return [
                {
                    label: ADMIN_LABEL,
                    value: ROLE_ADMIN,
                },
                {
                    label: SALESPERSON_LABEL,
                    value: ROLE_SALESPERSON,
                },
                {
                    label: MERCHANT_LABEL,
                    value: ROLE_MERCHANT,
                },
                {
                    label: ROOFER_LABEL,
                    value: ROLE_ROOFER,
                }
            ];
        case "ROLE_SALESPERSON":
            return [
                {
                    label: MERCHANT_LABEL,
                    value: ROLE_MERCHANT,
                },
                {
                    label: ROOFER_LABEL,
                    value: ROLE_ROOFER,
                }
            ];
        case "ROLE_MERCHANT":
            return [
                {
                    label: ROOFER_LABEL,
                    value: ROLE_ROOFER,
                }
            ];
        default:
            return []
    }
}

export function getAvailableProductsForNewUser() {
    return [
        {
            label: 'RENOV',
            value: ROLE_PRODUCT_RENOV,
        },
        {
            label: 'THERMO',
            value: ROLE_PRODUCT_THERMO,
        }
    ];
}