import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  Hidden,
  LinearProgress,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";
import { appActions } from "../../../actions/app.actions";
import { projectsActions } from "../../../actions/projects.actions";
import { getProjectStep } from "../../../helpers/project";
import { isAdmin } from "../../../helpers/user";
import ArchiveModal from "../../ArchiveModal";
import MenuActions from "../../MenuActions";
import { getCurrentProductName } from "../../../helpers/api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  gridItemButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
    },
  },
  imgWrapper: {
    margin: "auto 8px",
    height: "40px",
    width: "60px",
  },
  image: {
    maxWidth: "100%",
    height: "200px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginBottom: 15,
    marginTop: 15,
  },
  title: {
    display: "block",
    marginBottom: 5,
    textTransform: "uppercase",
  },
  mbt15: {
    marginTop: 15,
    marginBottom: 15,
  },
  mt15: {
    marginTop: 15,
  },
  blocSubInformations: {
    justifyContent: "space-between",
    alignItems: "center",
    gap: "18px",
    width: "100%",
    margin: "3px 0",
    padding: "0 12px",
  },
  blocSubInformationsNote: {
    justifyContent: "end",
    width: "100%",
    margin: "3px 0",
  },
  mr5: {
    marginRight: 5,
  },
  p0: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  m0: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  blockEvolution: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
    fontSize: "0.7rem!important",
  },
  projectInfos: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  dateCreatedAt: {
    fontWeight: "normal",
    fontSize: "0.9rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  blocProgressBar: {
    width: "100%",
    marginRight: "5px",
  },
  chipWrapper: {
    display: "flex",
  },
  barColorWarning: {
    backgroundColor: "#ff9800",
  },
  barColorSuccess: {
    backgroundColor: "#4caf50",
  },
  barColorBlue: {
    backgroundColor: "#0377fc",
  },
  itemBorderColorWarning: {
    borderLeft: "8px solid #ff9800",
  },
  itemBorderColorSuccess: {
    borderLeft: "8px solid #4caf50",
  },
  itemBorderColorBlue: {
    borderLeft: "8px solid #0377fc",
  },
  bgProgression: {
    backgroundColor: "#f0f2f8",
  },
  bgLightGrey: {
    maxWidth: "100%",
    height: "200px",
    marginBottom: 15,
    marginTop: 15,
    backgroundColor: "lightgray",
  },
  secondaryText: {
    display: "inline-flex",
    alignItems: "center",
    color: "rgba( 0, 0, 0, 0.54)",
  },
  secondaryTextNote: {
    display: "inline-flex",
    fontSize: "0.8rem",
    color: "rgba( 0, 0, 0, 0.54)",
  },
}));

const BlueChip = withStyles({
  root: {
    borderColor: "#0377fc",
    color: "#0377fc",
  },
})(Chip);

const GreenChip = withStyles({
  root: {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
})(Chip);

const OrangeChip = withStyles({
  root: {
    borderColor: "#ff9800",
    color: "#ff9800",
  },
})(Chip);

export default function ProjectsList(props) {
  const classes = useStyles();
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseMenuList = (option, project) => {
    if (option === "archive") {
      setSelectedProject(project);
      dispatch(appActions.setCurrentProject(project.id));
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleProjectWon = () => {
    dispatch(projectsActions.edit({ archived: true, won: true }));
    setOpenModal(false);
  };

  const handleProjectLost = () => {
    dispatch(projectsActions.edit({ archived: true, won: false }));
    setOpenModal(false);
  };

  const getPercentProgress = (step) => {
    return (step / 7) * 100;
  };

  const handleScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    if (scrollHeight - clientHeight === scrollTop) {
      props.nextPage();
    }
  };

  const _noRowsRenderer = () => {
    return (
      <div style={{ listStyle: "none" }}>
        <ListItem button style={{ height: "80px" }}>
          <ListItemText
            disableTypography
            primary={
              <Typography style={{ textAlign: "center" }}>
                Aucun projet trouvé
              </Typography>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  };

  const _rowRenderer = ({ index, key, style }) => {
    const project = props.projects[index];

    return (
      <div
        key={key}
        style={{
          ...style,
          marginTop: "10px",
          marginBottom: "30px",
          paddingTop: "15px",
          paddingBottom: "8px",
        }}
      >
        <ListItem // TODO: Colors depending of product step
          style={{ height: "115%", listStyle: "none", alignItems: "center" }}
          alignItems="flex-start"
          className={
            getProjectStep(project) === 5
              ? classes.itemBorderColorBlue
              : getProjectStep(project) === 9
                ? classes.itemBorderColorSuccess
                : classes.itemBorderColorWarning
          }
        >
          <Grid
            container
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              xs={12}
              lg={9}
              className={`${classes.gridItem} ${classes.p0}`}
            >
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-evenly"
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="h6"
                          className={classes.title}
                          color="textPrimary"
                        >
                          {project.name}{" "}
                          <span className={classes.dateCreatedAt}>
                            | créé le&nbsp;
                            {moment(project.createdAt).format("DD/M/Y")}
                            {isAdmin() &&
                              ` - par ${project.user.firstName} 
                            ${project.user.lastName} `}
                          </span>
                          {/* {project.infos && (
                            <span className={classes.projectInfos}>
                              |&nbsp;{project.infos}
                            </span>
                          )} */}
                        </Typography>
                      </React.Fragment>
                    }
                    className={classes.m0}
                  />

                  <div className={classes.blockEvolution}>
                    <div className={classes.blocProgressBar}>
                      <LinearProgress
                        classes={{
                          barColorPrimary:
                            getProjectStep(project) === 4
                              ? classes.barColorBlue
                              : getProjectStep(project) === 7
                                ? classes.barColorSuccess
                                : classes.barColorWarning,
                          colorPrimary: classes.bgProgression,
                        }}
                        variant="determinate"
                        value={getPercentProgress(getProjectStep(project))}
                      />
                    </div>
                    <div className={classes.chipWrapper}>
                      {getProjectStep(project) >= 4 &&
                        getProjectStep(project) !== 7 && (
                          <BlueChip
                            label="Estimatif"
                            color="secondary"
                            variant="outlined"
                            className={classes.mr5}
                          />
                        )}
                      {getProjectStep(project) !== 4 &&
                        getProjectStep(project) !== 7 && (
                          <OrangeChip
                            label="En cours"
                            color="secondary"
                            variant="outlined"
                          />
                        )}
                      {getProjectStep(project) === 7 && (
                        <GreenChip
                          label="Terminé"
                          color="secondary"
                          variant="outlined"
                        />
                      )}
                    </div>
                  </div>
                </Box>

                <Grid container className={classes.blocSubInformations}>
                  <Grid item className={classes.secondaryText} variant="body2">
                    <CalendarTodayIcon style={{ marginRight: 2 }} />{" "}
                    {`Dernière mise à jour le ${moment(
                      project.updatedAt
                    ).format("DD/M/Y")}`}
                  </Grid>
                  <Grid item className={classes.secondaryText} variant="body2" data-toggle="tooltip" data-placement="top" title="Note : marge vendue = marge chantier + remise ou marge du commercial">
                    <BusinessCenterIcon style={{ marginRight: 2 }} /> Marge
                    vendeur :&nbsp;<b>{Math.round((project.priceMargin + project.priceMarginTotal)*100)/100}%</b>
                    <br />
                  </Grid>
                  {/* <Grid item className={classes.secondaryText} variant="body2" data-toggle="tooltip" data-placement="top" title="Note : marge chantier = marge totale du prix de cession de tous les articles du devis">
                    <BusinessCenterOutlinedIcon style={{ marginRight: 2 }} />{" "}
                    Marge chantier :&nbsp;
                    <b>{Math.round(project.priceMarginTotal*100)/100}%</b>
                  </Grid> */}
                </Grid>
              </Box>
              <Hidden lgDown>
                <div className={classes.imgWrapper}>
                  {project?.image?.contentUrl && (
                    <>
                      <Avatar
                        variant={"rounded"}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                        src={
                          process.env.REACT_APP_API_URL +
                          project.image.contentUrl
                        }
                      />
                    </>
                  )}
                  {/* {!project?.image?.contentUrl && (
                        <Avatar
                          variant={"square"}
                          style={{ height: "100%", width: "100%" }}
                        >
                          <ImageSearch />
                        </Avatar>
                      )} */}
                </div>
              </Hidden>
            </Grid>

            <Grid item xs={12} lg={3} className={classes.gridItemButton}>
              <Button
                component={Link}
                to={`/${getCurrentProductName()}/projects/${project.id}/step${getProjectStep(project)}`}
                variant="contained"
                color="primary"
              >
                Editer le projet
              </Button>

              <MenuActions
                options={{
                  archive: {
                    name: "Archiver",
                  },
                }}
                onClose={(option) => handleCloseMenuList(option, project)}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider variant="middle" style={{ marginTop: "8px" }} />
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <AutoSizer>
          {({ width, height }) => {
            return (
              <>
                <ArchiveModal
                  onClose={handleCloseModal}
                  onWonClick={handleProjectWon}
                  onLostClick={handleProjectLost}
                  open={openModal}
                  title={"Êtes-vous sûr de vouloir archiver ce projet ?"}
                  paragraph={"Vous ne pourrez plus y accéder par la suite."}
                />
                <List
                  overscanRowCount={30}
                  noRowsRenderer={_noRowsRenderer}
                  onScroll={handleScroll}
                  rowCount={props?.projects?.length || 0}
                  rowHeight={width > 940 ? 105 : 200}
                  rowRenderer={_rowRenderer}
                  width={width}
                  height={height}
                />
              </>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
}
