import {
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useDispatch } from "react-redux";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";
import { productOptionsActions } from "../../actions/productOption.actions";
import MenuActions from "../MenuActions";
import ValidationModal from "../ValidationModal";

const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default function OptionsList(props) {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseMenuList = (option, productOption) => {
    if (option === "Supprimer") {
      setSelectedOption(productOption);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOptionDelete = () => {
    setDeleting(true);

    dispatch(productOptionsActions.disable(selectedOption.id)).then(() => {
      setDeleting(false);
      setOpenModal(false);
    });
  };

  const _noRowsRenderer = () => {
    return (
      <div style={{ listStyle: "none" }}>
        <ListItem button style={{ height: "80px" }}>
          <ListItemText
            disableTypography
            primary={
              <Typography style={{ textAlign: "center" }}>
                Aucune option trouvée
              </Typography>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  };

  const _rowRenderer = ({ index, key, style }) => {
    const option = props.options[index];

    return (
      <div key={key} style={{ ...style, listStyle: "none" }}>
        <ListItem
          button
          style={{ height: "80px", listStyle: "none", paddingRight: "75px" }}
        >
          <ListItemText
            disableTypography
            primary={<Typography>{option.name}</Typography>}
            secondary={
              <Box display="flex">
                <Grid className={classes.textSecondary} container>
                  <Grid item xs={4}>
                    <Typography variant={"body2"}>
                      {option.reference}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            }
          />
          <ListItemSecondaryAction>
            <MenuActions
              onClose={(evt) => handleCloseMenuList(evt, option)}
              options={{
                edit: {
                  name: "Editer",
                  link: `/thermo/options/${option.id}/edit`,
                },
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <ValidationModal
        loading={deleting}
        onClose={handleCloseModal}
        onClick={handleOptionDelete}
        open={openModal}
        title={"Êtes-vous sûr de vouloir supprimer cette option ?"}
        paragraph={"Sa suppression est définitive."}
      />

      <div style={{ flex: "1 1 auto", height: "100%" }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                overscanRowCount={5}
                noRowsRenderer={_noRowsRenderer}
                rowCount={props?.options?.length || 0}
                rowHeight={80}
                rowRenderer={_rowRenderer}
                width={width}
                height={height}
              />
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
}
