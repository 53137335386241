import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Toolbar,
  useTheme
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import {
  AccountCircle as AccountCircleIcon, 
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
  SupervisorAccount as SupervisorAccountIcon
} from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Route, Switch } from "react-router-dom";
import { usersActions } from "../actions/users.actions";
import { getUser, getUserProducts, isAdmin, ROLE_PRODUCT_RENOV, ROLE_PRODUCT_THERMO } from "../helpers/user";
import logo from "../logo.svg";
import AccountEdit from "./account/AccountEdit";
import UsersIndex from "./admin/users/Index";
import { history } from "../helpers/history";
import Hub from "../components/menu/Hub";
import RenovItems from "../components/menu/RenovItems";
import ThermoItems from "../components/menu/ThermoItems";
import { appActions } from "../actions/app.actions";
import RenovRoutes from "../components/menu/RenovRoutes";
import ThermoRoutes from "../components/menu/ThermoRoutes";
import { reset } from "../actions/actions";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  listItem: {
    "&:hover": {
      color: "red",
    },
    "&:focus": {
      color: "red",
    },
  },
  bar: {
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(true);
  const userProducts = getUserProducts();

  let currentProduct = useSelector((state) => {
    return state.app.currentProduct || '';
  });

  if (currentProduct === '' && userProducts.length === 1) {
    currentProduct = userProducts[0];
    dispatch(appActions.setCurrentProduct(currentProduct));
    sessionStorage.setItem('currentProduct', currentProduct);
  }

  const [product, setProduct] = React.useState(currentProduct);

  const logout = () => {
    dispatch(usersActions.logout());
    history.push('/login');
  }

  let users = useSelector((state) => {
    return Object.values(state.users.all).filter(
      (user) => user.managed && user.id !== getUser()?.decoded.userId
    );
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleProductChange = (event) => {
    changeProduct(event.target.value);
  };

  const changeProduct = (product) => {
    dispatch(reset());
    setProduct(product);
    dispatch(appActions.setCurrentProduct(product));
    sessionStorage.setItem('currentProduct', product);
  }

  const isProductRenov = () => product === ROLE_PRODUCT_RENOV;
  const isProductThermo = () => product === ROLE_PRODUCT_THERMO;

  const drawer = (
    <>
      <div className={classes.toolbar} />

      <ListItem
        className={classes.listItem}
        component={NavLink}
        activeClassName="Mui-selected"
        to="/account"
        button
      >
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Compte" />
      </ListItem>

      <ListItem
          component={NavLink}
          activeClassName="Mui-selected"
          to="/users"
          className={classes.listItem}
          button
      >
          <ListItemIcon>
              <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Utilisateurs" />
      </ListItem>

      <Divider />

      {userProducts.length > 1 && isAdmin() && (
        <>
          <ListItem className={classes.listItem}>
            <InputLabel id="product-select-label" style={{marginRight: '1em'}}>Produit</InputLabel>
            <Select
              labelId="product-select-label"
              id="product-select"
              value={product}
              label="Produit"
              onChange={handleProductChange}
            >
              <MenuItem value={ROLE_PRODUCT_RENOV}>RENOV</MenuItem>
              <MenuItem value={ROLE_PRODUCT_THERMO}>THERMO</MenuItem>
            </Select>


          </ListItem>

            <Divider />
          </>
      )}

      <List className={classes.list}>
        {isProductRenov() && <RenovItems isAdmin={isAdmin()}/>}
        {isProductThermo() && <ThermoItems isAdmin={isAdmin()}/>}

        <List className={classes.bottomList}>
          <ListItem
            className={classes.listItem}
            activeClassName="Mui-selected"
            onClick={logout}
            button
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Déconnexion" />
          </ListItem>
        </List>
      </List>
    </>
  );

  return (
    <div className={classes.root}>
      {product !== '' &&
        <>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.bar}>
              <Box display="flex">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <img src={logo} className="App-logo" alt="Logo Onduline" />
              </Box>
              <Button color="inherit">{getUser()?.decoded?.username}</Button>
            </Toolbar>
          </AppBar>

          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </>
      }

      <main className={classes.content}>
        <div className={classes.toolbar} />

        {product === '' ?
          <Hub setProductFn={changeProduct} />
          :
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <Route path="/account">
                <AccountEdit currentUser={getUser()?.decoded} />
              </Route>
              <Route path="/users">
                <UsersIndex />
              </Route>
              {isProductRenov() && <RenovRoutes />}
              {isProductThermo() && <ThermoRoutes />}
            </Switch>
          </Container>
        }
      </main>
    </div>
  );
}
