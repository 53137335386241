import { CALL_API } from "../middleware/api";
import { panelManufacturer } from "../helpers/schema";
import { shouldFetch } from "../helpers/api";

// ----------------------
// Panel manufacturer Fetching
// ----------------------
export const FETCH_PANEL_MANUFACTURERS_REQUEST = "FETCH_PANEL_MANUFACTURERS_REQUEST";
export const FETCH_PANEL_MANUFACTURERS_SUCCESS = "FETCH_PANEL_MANUFACTURERS_SUCCESS";
export const FETCH_PANEL_MANUFACTURERS_FAILURE = "FETCH_PANEL_MANUFACTURERS_FAILURE";
export const INVALIDATE_PANEL_MANUFACTURERS    = "INVALIDATE_PANEL_MANUFACTURERS";

function invalidatePanelManufacturers () {
    return {type: INVALIDATE_PANEL_MANUFACTURERS};
}

function fetchPanelManufacturersIfNeeded () {
    return (dispatch, getState) => {
        if (shouldFetch(getState(), "panelManufacturers")) {
            return dispatch(fetchPanelManufacturers());
        }
    };
}

const fetchPanelManufacturers = () => ({
    [CALL_API]: {
        types: [
            FETCH_PANEL_MANUFACTURERS_REQUEST,
            FETCH_PANEL_MANUFACTURERS_SUCCESS,
            FETCH_PANEL_MANUFACTURERS_FAILURE
        ],
        endpoint: `panel_manufacturer_thermos?pagination=false`,
        schema: [panelManufacturer],
    }
});

export const panelManufacturersActions = {
    invalidatePanelManufacturers,
    fetchPanelManufacturersIfNeeded,
};
