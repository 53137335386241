import React from "react";
import { Button } from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import LoadingButton from "./LoadingButton";

export default function ArchiveModal(props) {
    return (<Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.paragraph}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} color="primary">
                Annuler
            </Button>
            <LoadingButton loading={props.loading || false} onClick={props.onLostClick} color="primary" autoFocus>
                Projet perdu
            </LoadingButton>
            <LoadingButton loading={props.loading || false} onClick={props.onWonClick} color="primary" autoFocus>
                Projet gagné
            </LoadingButton>
        </DialogActions>
    </Dialog>);
}
