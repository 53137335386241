import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, Box, CircularProgress, Fab } from "@material-ui/core";
import { productColorsActions } from "../../../../actions/productColor.actions";
import ColorsList from "../../../../components/colors/ColorsList";
import ColorsEdit from "./ColorsEdit";
import HeaderTitle from "../../../../components/HeaderTitle";
import SearchList from "../../../../components/searchList";
import { filterElement } from "../../../../helpers/schema";

export default function Index(props) {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = React.useState("");

  const isFetching = useSelector((state) => {
    return state.productColors.isFetching;
  }, shallowEqual);

  const productColors = useSelector((state) => {
    return Object.values(state.productColors.all) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
  }, [dispatch, isFetching]);

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(productColorsActions.invalidateProductColors());
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
  };

  const onSearch = (search) => {
    setSearchFilter(search);
  };

  return (
    <Switch>
      <Route exact path="/thermo/colors">
        <HeaderTitle
          mb={3}
          title="Finitions"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
          bottom={<SearchList onSearch={onSearch} />}
        />
        {isFetching && Object.entries(productColors).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        <div className={"colors-container"} style={{ height: "100%" }}>
          {!isFetching && Object.entries(productColors).length === 0 && (
            <h2>Aucune donnée disponible.</h2>
          )}

          {Object.entries(productColors).length > 0 && (
            <ColorsList
              colors={Object.values(productColors).filter((item) =>
                filterElement(item, ["name"], searchFilter)
              )}
            />
          )}
        </div>
      </Route>
      <Route
        path={"/thermo/colors/:colorId/edit"}
        children={<ColorsEdit />}
      />
      <Route>
        <Redirect to={"/thermo/colors"} />
      </Route>
    </Switch>
  );
}
