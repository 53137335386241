import { CALL_API } from "../middleware/api";
import { insulation } from "../helpers/schema";
import { shouldFetch } from "../helpers/api";
import { buildNextUrl, history } from "../helpers/history";
import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction,
} from "./notifications.actions";
import Button from "@material-ui/core/Button";
import React from "react";

// ----------------------
// Insulations Fetching
// ----------------------
export const FETCH_INSULATIONS_REQUEST = "FETCH_INSULATIONS_REQUEST";
export const FETCH_INSULATIONS_SUCCESS = "FETCH_INSULATIONS_SUCCESS";
export const FETCH_INSULATIONS_FAILURE = "FETCH_INSULATIONS_FAILURE";
export const INVALIDATE_INSULATIONS = "INVALIDATE_INSULATIONS";

function invalidateInsulations() {
  return { type: INVALIDATE_INSULATIONS };
}

function fetchInsulationsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "insulations")) {
      return dispatch(fetchInsulations());
    }
  };
}

// sub actions
const fetchInsulations = () => ({
  [CALL_API]: {
    types: [
      FETCH_INSULATIONS_REQUEST,
      FETCH_INSULATIONS_SUCCESS,
      FETCH_INSULATIONS_FAILURE,
    ],
    endpoint: `insulations?pagination=false`,
    schema: [insulation],
  },
});

// ----------------------
// Insulation Create
// ----------------------

export const ADD_INSULATION_REQUEST = "ADD_INSULATION_REQUEST";
export const ADD_INSULATION_RESPONSE = "ADD_INSULATION_RESPONSE";
export const ADD_INSULATION_ERROR = "ADD_INSULATION_ERROR";

const add = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        ADD_INSULATION_REQUEST,
        ADD_INSULATION_RESPONSE,
        ADD_INSULATION_ERROR,
      ],
      endpoint: `insulations`,
      schema: insulation,
      requestOptions: {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify(form),
      },
      success: (result) => {
        if (history.location.pathname.includes("insulations")) {
          history.push(buildNextUrl(getState()));
        }
      },
    },
  });

// ----------------------
// Insulation Edit
// ----------------------

export const EDIT_INSULATION_REQUEST = "EDIT_INSULATION_REQUEST";
export const EDIT_INSULATION_RESPONSE = "EDIT_INSULATION_RESPONSE";
export const EDIT_INSULATION_ERROR = "EDIT_INSULATION_ERROR";

const edit = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        EDIT_INSULATION_REQUEST,
        EDIT_INSULATION_RESPONSE,
        EDIT_INSULATION_ERROR,
      ],
      endpoint: `insulations/` + form.id,
      schema: insulation,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify(form),
      },
      success: (result) => {
        if (history.location.pathname.includes("insulations")) {
          history.push(buildNextUrl(getState()));
        }
      },
    },
  });

// ----------------------
// Insulation disable
// ----------------------

export const DISABLE_INSULATION_REQUEST = "DISABLE_INSULATION_REQUEST";
export const DISABLE_INSULATION_RESPONSE = "DISABLE_INSULATION_RESPONSE";
export const DISABLE_INSULATION_ERROR = "DISABLE_INSULATION_ERROR";

const disable = (insulationId) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      types: [
        DISABLE_INSULATION_REQUEST,
        DISABLE_INSULATION_RESPONSE,
        DISABLE_INSULATION_ERROR,
      ],
      endpoint: `insulations/${insulationId}`,
      schema: insulation,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ enabled: false }),
      },
      success: (result) => {
        dispatch(
          enqueueSnackbarAction({
            message: "Isolation supprimée avec succès.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              action: (key) => (
                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                  OK
                </Button>
              ),
            },
          })
        );
      },
    },
  });

export const insulationsActions = {
  invalidateInsulations,
  fetchInsulationsIfNeeded,
  add,
  edit,
  disable,
};
