export const MERCHANT = "Marchand";
export const ROOFER   = "Poseur";
export const FINAL    = "Client final";

export const ROLE_ADMIN       = "Admin";
export const ROLE_SALESPERSON = "Commercial";
export const ROLE_MERCHANT    = "Négociant";
export const ROLE_ROOFER      = "Poseur";
export const ROLE_PRODUCT_RENOV = "RENOV";
export const ROLE_PRODUCT_THERMO = "THERMO";

export const AVAILABLES_ROLES = [
    {
        label: ROLE_ADMIN,
        value: "ROLE_ADMIN",
    },
    {
        label: ROLE_SALESPERSON,
        value: "ROLE_SALESPERSON",
    },
    {
        label: ROLE_MERCHANT,
        value: "ROLE_MERCHANT",
    },
    {
        label: ROLE_ROOFER,
        value: "ROLE_ROOFER",
    }
];

export const AVAILABLES_PRODUCTS_ROLES = [
    {
        label: ROLE_PRODUCT_RENOV,
        value: "ROLE_PRODUCT_RENOV",
    },
    {
        label: ROLE_PRODUCT_THERMO,
        value: "ROLE_PRODUCT_THERMO",
    }
];

export const humanReadableRoles = {
    "ROLE_ADMIN": "Admin",
    "ROLE_SALESPERSON": "Commercial",
    "ROLE_MERCHANT": "Négociant",
    "ROLE_ROOFER": "Poseur",
    "ROLE_PRODUCT_RENOV": "renov",
    "ROLE_PRODUCT_THERMO": "thermo"
};

export const AVAILABLES_TYPES = [
    {
        label: MERCHANT,
        value: "MERCHANT",
    },
    {
        label: ROOFER,
        value: "ROOFER",
    },
    {
        label: FINAL,
        value: "FINAL",
    }
];

export const humanReadableTypes = {
    "MERCHANT": "Négociant",
    "ROOFER": "Poseur",
    "FINAL": "Client final"
};

export const OPTIONS = {
    "Voir": "/show",
    "Editer": "/edit",
    // "Supprimer": "/delete", todo: finish delete / disable system for entities
};

export const SETTINGS = [
    {
        key: "valuation_factor",
        name: "coefficient estimation",
        type: "number",
        description: "coefficient appliqué sur les estimations (utilisé pour ajouter le coût des accessoires)"
    }
]

export const ENDPOINTS = {
    'projects': {
        'renov': 'projects',
        'thermo': 'project_thermos'
    },
    'clients': {
        'renov': 'clients',
        'thermo': 'client_thermos'
    },
    'products': {
        'renov': 'products',
        'thermo': 'product_thermos'
    },
    'productsVariants': {
        'renov': 'product_variants',
        'thermo': 'product_variant_thermos'
    },
    'productsColors': {
        'renov': 'product_colors',
        'thermo': 'product_color_thermos'
    },
    'accessories': {
        'renov': 'accessories',
        'thermo': 'accessory_thermos'
    },
    'admin': {
        'renov': 'admin',
        'thermo': 'admin/thermo'
    },
    'mediaObjects': {
        'renov': 'media_objects',
        'thermo': 'media_object_thermos'
    }
}

export const DEPARTEMENTS = [
    {
        "nom": "Ain",
        "code": "01",
        "codeRegion": "84"
    },
    {
        "nom": "Aisne",
        "code": "02",
        "codeRegion": "32"
    },
    {
        "nom": "Allier",
        "code": "03",
        "codeRegion": "84"
    },
    {
        "nom": "Alpes-de-Haute-Provence",
        "code": "04",
        "codeRegion": "93"
    },
    {
        "nom": "Hautes-Alpes",
        "code": "05",
        "codeRegion": "93"
    },
    {
        "nom": "Alpes-Maritimes",
        "code": "06",
        "codeRegion": "93"
    },
    {
        "nom": "Ardèche",
        "code": "07",
        "codeRegion": "84"
    },
    {
        "nom": "Ardennes",
        "code": "08",
        "codeRegion": "44"
    },
    {
        "nom": "Ariège",
        "code": "09",
        "codeRegion": "76"
    },
    {
        "nom": "Aube",
        "code": "10",
        "codeRegion": "44"
    },
    {
        "nom": "Aude",
        "code": "11",
        "codeRegion": "76"
    },
    {
        "nom": "Aveyron",
        "code": "12",
        "codeRegion": "76"
    },
    {
        "nom": "Bouches-du-Rhône",
        "code": "13",
        "codeRegion": "93"
    },
    {
        "nom": "Calvados",
        "code": "14",
        "codeRegion": "28"
    },
    {
        "nom": "Cantal",
        "code": "15",
        "codeRegion": "84"
    },
    {
        "nom": "Charente",
        "code": "16",
        "codeRegion": "75"
    },
    {
        "nom": "Charente-Maritime",
        "code": "17",
        "codeRegion": "75"
    },
    {
        "nom": "Cher",
        "code": "18",
        "codeRegion": "24"
    },
    {
        "nom": "Corrèze",
        "code": "19",
        "codeRegion": "75"
    },
    {
        "nom": "Côte-d'Or",
        "code": "21",
        "codeRegion": "27"
    },
    {
        "nom": "Côtes-d'Armor",
        "code": "22",
        "codeRegion": "53"
    },
    {
        "nom": "Creuse",
        "code": "23",
        "codeRegion": "75"
    },
    {
        "nom": "Dordogne",
        "code": "24",
        "codeRegion": "75"
    },
    {
        "nom": "Doubs",
        "code": "25",
        "codeRegion": "27"
    },
    {
        "nom": "Drôme",
        "code": "26",
        "codeRegion": "84"
    },
    {
        "nom": "Eure",
        "code": "27",
        "codeRegion": "28"
    },
    {
        "nom": "Eure-et-Loir",
        "code": "28",
        "codeRegion": "24"
    },
    {
        "nom": "Finistère",
        "code": "29",
        "codeRegion": "53"
    },
    {
        "nom": "Corse-du-Sud",
        "code": "2A",
        "codeRegion": "94"
    },
    {
        "nom": "Haute-Corse",
        "code": "2B",
        "codeRegion": "94"
    },
    {
        "nom": "Gard",
        "code": "30",
        "codeRegion": "76"
    },
    {
        "nom": "Haute-Garonne",
        "code": "31",
        "codeRegion": "76"
    },
    {
        "nom": "Gers",
        "code": "32",
        "codeRegion": "76"
    },
    {
        "nom": "Gironde",
        "code": "33",
        "codeRegion": "75"
    },
    {
        "nom": "Hérault",
        "code": "34",
        "codeRegion": "76"
    },
    {
        "nom": "Ille-et-Vilaine",
        "code": "35",
        "codeRegion": "53"
    },
    {
        "nom": "Indre",
        "code": "36",
        "codeRegion": "24"
    },
    {
        "nom": "Indre-et-Loire",
        "code": "37",
        "codeRegion": "24"
    },
    {
        "nom": "Isère",
        "code": "38",
        "codeRegion": "84"
    },
    {
        "nom": "Jura",
        "code": "39",
        "codeRegion": "27"
    },
    {
        "nom": "Landes",
        "code": "40",
        "codeRegion": "75"
    },
    {
        "nom": "Loir-et-Cher",
        "code": "41",
        "codeRegion": "24"
    },
    {
        "nom": "Loire",
        "code": "42",
        "codeRegion": "84"
    },
    {
        "nom": "Haute-Loire",
        "code": "43",
        "codeRegion": "84"
    },
    {
        "nom": "Loire-Atlantique",
        "code": "44",
        "codeRegion": "52"
    },
    {
        "nom": "Loiret",
        "code": "45",
        "codeRegion": "24"
    },
    {
        "nom": "Lot",
        "code": "46",
        "codeRegion": "76"
    },
    {
        "nom": "Lot-et-Garonne",
        "code": "47",
        "codeRegion": "75"
    },
    {
        "nom": "Lozère",
        "code": "48",
        "codeRegion": "76"
    },
    {
        "nom": "Maine-et-Loire",
        "code": "49",
        "codeRegion": "52"
    },
    {
        "nom": "Manche",
        "code": "50",
        "codeRegion": "28"
    },
    {
        "nom": "Marne",
        "code": "51",
        "codeRegion": "44"
    },
    {
        "nom": "Haute-Marne",
        "code": "52",
        "codeRegion": "44"
    },
    {
        "nom": "Mayenne",
        "code": "53",
        "codeRegion": "52"
    },
    {
        "nom": "Meurthe-et-Moselle",
        "code": "54",
        "codeRegion": "44"
    },
    {
        "nom": "Meuse",
        "code": "55",
        "codeRegion": "44"
    },
    {
        "nom": "Morbihan",
        "code": "56",
        "codeRegion": "53"
    },
    {
        "nom": "Moselle",
        "code": "57",
        "codeRegion": "44"
    },
    {
        "nom": "Nièvre",
        "code": "58",
        "codeRegion": "27"
    },
    {
        "nom": "Nord",
        "code": "59",
        "codeRegion": "32"
    },
    {
        "nom": "Oise",
        "code": "60",
        "codeRegion": "32"
    },
    {
        "nom": "Orne",
        "code": "61",
        "codeRegion": "28"
    },
    {
        "nom": "Pas-de-Calais",
        "code": "62",
        "codeRegion": "32"
    },
    {
        "nom": "Puy-de-Dôme",
        "code": "63",
        "codeRegion": "84"
    },
    {
        "nom": "Pyrénées-Atlantiques",
        "code": "64",
        "codeRegion": "75"
    },
    {
        "nom": "Hautes-Pyrénées",
        "code": "65",
        "codeRegion": "76"
    },
    {
        "nom": "Pyrénées-Orientales",
        "code": "66",
        "codeRegion": "76"
    },
    {
        "nom": "Bas-Rhin",
        "code": "67",
        "codeRegion": "44"
    },
    {
        "nom": "Haut-Rhin",
        "code": "68",
        "codeRegion": "44"
    },
    {
        "nom": "Rhône",
        "code": "69",
        "codeRegion": "84"
    },
    {
        "nom": "Haute-Saône",
        "code": "70",
        "codeRegion": "27"
    },
    {
        "nom": "Saône-et-Loire",
        "code": "71",
        "codeRegion": "27"
    },
    {
        "nom": "Sarthe",
        "code": "72",
        "codeRegion": "52"
    },
    {
        "nom": "Savoie",
        "code": "73",
        "codeRegion": "84"
    },
    {
        "nom": "Haute-Savoie",
        "code": "74",
        "codeRegion": "84"
    },
    {
        "nom": "Paris",
        "code": "75",
        "codeRegion": "11"
    },
    {
        "nom": "Seine-Maritime",
        "code": "76",
        "codeRegion": "28"
    },
    {
        "nom": "Seine-et-Marne",
        "code": "77",
        "codeRegion": "11"
    },
    {
        "nom": "Yvelines",
        "code": "78",
        "codeRegion": "11"
    },
    {
        "nom": "Deux-Sèvres",
        "code": "79",
        "codeRegion": "75"
    },
    {
        "nom": "Somme",
        "code": "80",
        "codeRegion": "32"
    },
    {
        "nom": "Tarn",
        "code": "81",
        "codeRegion": "76"
    },
    {
        "nom": "Tarn-et-Garonne",
        "code": "82",
        "codeRegion": "76"
    },
    {
        "nom": "Var",
        "code": "83",
        "codeRegion": "93"
    },
    {
        "nom": "Vaucluse",
        "code": "84",
        "codeRegion": "93"
    },
    {
        "nom": "Vendée",
        "code": "85",
        "codeRegion": "52"
    },
    {
        "nom": "Vienne",
        "code": "86",
        "codeRegion": "75"
    },
    {
        "nom": "Haute-Vienne",
        "code": "87",
        "codeRegion": "75"
    },
    {
        "nom": "Vosges",
        "code": "88",
        "codeRegion": "44"
    },
    {
        "nom": "Yonne",
        "code": "89",
        "codeRegion": "27"
    },
    {
        "nom": "Territoire de Belfort",
        "code": "90",
        "codeRegion": "27"
    },
    {
        "nom": "Essonne",
        "code": "91",
        "codeRegion": "11"
    },
    {
        "nom": "Hauts-de-Seine",
        "code": "92",
        "codeRegion": "11"
    },
    {
        "nom": "Seine-Saint-Denis",
        "code": "93",
        "codeRegion": "11"
    },
    {
        "nom": "Val-de-Marne",
        "code": "94",
        "codeRegion": "11"
    },
    {
        "nom": "Val-d'Oise",
        "code": "95",
        "codeRegion": "11"
    },
    {
        "nom": "Guadeloupe",
        "code": "971",
        "codeRegion": "01"
    },
    {
        "nom": "Martinique",
        "code": "972",
        "codeRegion": "02"
    },
    {
        "nom": "Guyane",
        "code": "973",
        "codeRegion": "03"
    },
    {
        "nom": "La Réunion",
        "code": "974",
        "codeRegion": "04"
    },
    {
        "nom": "Mayotte",
        "code": "976",
        "codeRegion": "06"
    }
];

export const PITCHES = [
    '250',
    '333'
];

export const THICKNESSES = [
    '30',
    '40',
    '50',
    '60',
    '80',
    '100',
    '120',
    '140',
    '150'
];

export const AREAS = [
    {
        area: "0_25",
        label: 'Inférieur à 25m²'
    },
    {
        area: "25_50",
        label: 'De 25 à 50m²'
    },
    {
        area: "50_120",
        label: 'De 50 à 120m²'
    },
    {
        area: "120_300",
        label: 'De 120 à 300m²'
    },
    {
        area: "300_9999",
        label: 'Plus de 300m²'
    }
];
