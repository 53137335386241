import React, { useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from "@material-ui/core";
import LoadingButton from "./LoadingButton";
import { projectsActions } from "../actions/projects.actions";
import { useDispatch } from "react-redux";

export default function SendMailModal(props) {
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState(props?.defaultValue);

  const dispatch = useDispatch();

  useEffect(() => setMail(props.defaultValue), [props.defaultValue]);

  const sendMail = async (event) => {
    const { type, projectId } = props;
    setLoading(true);
    event.preventDefault();
    try {
      await dispatch(projectsActions.sendMail(projectId, type, mail)).then(result => props.onClose());
    } catch (e) {
      setLoading(false);
      return console.error(e);
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    setMail(event.target.value);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={sendMail}>
        <DialogTitle id="alert-dialog-title">
          {props?.title || "Recevoir le devis sur ma boite mail"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Merci de vérifier votre adresse mail.
            <br />
            Pour envoyer le PDF à l'adresse indiquée cliquez sur envoyer.
          </DialogContentText>
          <Grid container>
            <Grid item md={12}>
              <TextField
                required
                type="email"
                size="small"
                variant="filled"
                label={"Destinataire"}
                onChange={handleChange}
                value={mail}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Annuler
          </Button>
          <LoadingButton
            type="submit"
            loading={loading || false}
            color="primary"
            autoFocus
          >
            Envoyer
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
