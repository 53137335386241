import { CALL_API } from "../middleware/api";
import { project } from "../helpers/schema";
import React from "react";

// ----------------------
// Slope Edit
// ----------------------

export const EDIT_SLOPE_REQUEST = "EDIT_SLOPE_REQUEST";
export const EDIT_SLOPE_RESPONSE = "EDIT_SLOPE_RESPONSE";
export const EDIT_SLOPE_ERROR = "EDIT_SLOPE_ERROR";

const edit = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        EDIT_SLOPE_REQUEST,
        EDIT_SLOPE_RESPONSE,
        EDIT_SLOPE_ERROR,
      ],
      endpoint: `slopes/` + form.id + '/edit_layout',
      schema: project,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify(form),
      }
    },
  });

export const slopesActions = {
  edit,
};
