import Button from "@material-ui/core/Button";
import React from "react";
import { getCurrentProductName, shouldFetch } from "../helpers/api";
import { buildNextUrl, history } from "../helpers/history";
import { project } from "../helpers/schema";
import { CALL_API } from "../middleware/api";
import { appActions } from "./app.actions";
import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction
} from "./notifications.actions";
import { ENDPOINTS } from "../helpers/constante";

export const FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";
export const INVALIDATE_PROJECTS = "INVALIDATE_PROJECTS";

function invalidateProjects() {
  return { type: INVALIDATE_PROJECTS };
}

function fetchProjectsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "projects")) {
      return dispatch(fetchProjects());
    }
  };
}
function fetchArchivedProjectsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "projects")) {
      return dispatch(fetchProjects());
    }
  };
}

// sub actions
const fetchProjects = (
  stateless,
  pagination = false,
  page = 1,
  itemsPerPage = 10,
  search = "",
  showArchived = false
) => {
  return {
    [CALL_API]: {
      types: [
        FETCH_PROJECTS_REQUEST,
        FETCH_PROJECTS_SUCCESS,
        FETCH_PROJECTS_FAILURE,
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}?pagination=${pagination}&order[updatedAt]=desc&page=${page}&itemsPerPage=${itemsPerPage}${
        search ? `&search=${search}` : ""
      }${
        showArchived ? `&archived=${true}` : `&archived=${false}`
      }`,
      schema: stateless ? null : [project],
    },
  };
};


// ----------------------
// Project Add
// ----------------------
export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
export const ADD_PROJECT_RESPONSE = "ADD_PROJECT_RESPONSE";
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR";

const add = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [ADD_PROJECT_REQUEST, ADD_PROJECT_RESPONSE, ADD_PROJECT_ERROR],
      form: true,
      endpoint: ENDPOINTS.projects[getCurrentProductName()],
      schema: project,
      requestOptions: {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify(form),
      },
      success: (result) => {
        dispatch(appActions.setCurrentProject(result.id));
        history.push(buildNextUrl(getState()));
      },
      failure: (error) => {
        console.log(error);
      },
    },
  });

// ----------------------
// Project Edit
// ----------------------
export const EDIT_PROJECT_REQUEST = "EDIT_PROJECT_REQUEST";
export const EDIT_PROJECT_RESPONSE = "EDIT_PROJECT_RESPONSE";
export const EDIT_PROJECT_ERROR = "EDIT_PROJECT_ERROR";

const edit = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [EDIT_PROJECT_REQUEST, EDIT_PROJECT_RESPONSE, EDIT_PROJECT_ERROR],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/` + getState()["app"].currentProject,
      schema: project,
      requestOptions: {
        headers: { "content-type": "application/json" },
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        body: JSON.stringify(form),
      },
      success: (result) => {
        dispatch(appActions.setCurrentProject(result.id));
        history.push(buildNextUrl(getState()));
      },
      failure: (error) => {
        dispatch(
          enqueueSnackbarAction({
            message: error.detail || error.error,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              autoHideDuration: 4000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            },
          })
        );
      },
    },
  });

// ----------------------
// Project get
// ----------------------
export const FETCH_PROJECT_REQUEST = "FETCH_PROJECT_REQUEST";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAILURE = "FETCH_PROJECT_FAILURE";

function fetchProjectIfNeeded(project) {
  return (dispatch, getState) => {
    let state = getState();
    let projects = state.projects.all;
    if (
      !state.projects.isFetching &&
      !state.projects.fetchFailed.includes(project) &&
      !(projects[project] && projects[project].fullSchema)
    ) {
      return dispatch(fetchProject(project));
    }
  };
}

const fetchProject = (projectId) => ({
  [CALL_API]: {
    types: [
      FETCH_PROJECT_REQUEST,
      FETCH_PROJECT_SUCCESS,
      FETCH_PROJECT_FAILURE,
    ],
    endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/` + projectId,
    schema: project,
  },
});

// ----------------------
// Project estimate calculation
// ----------------------
export const PROJECT_CALCULATE_ESTIMATE_REQUEST = "PROJECT_CALCULATE_ESTIMATE_REQUEST";
export const PROJECT_CALCULATE_ESTIMATE_SUCCESS = "PROJECT_CALCULATE_ESTIMATE_SUCCESS";
export const PROJECT_CALCULATE_ESTIMATE_FAILURE = "PROJECT_CALCULATE_ESTIMATE_FAILURE";

const calculateEstimate = (projectId) => ({
  [CALL_API]: {
    types: [
      PROJECT_CALCULATE_ESTIMATE_REQUEST,
      PROJECT_CALCULATE_ESTIMATE_SUCCESS,
      PROJECT_CALCULATE_ESTIMATE_FAILURE,
    ],
    endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/` + projectId + "/calculateestimate",
    schema: project,
  },
});

// ----------------------
// Project estimate calculation
// ----------------------
export const PROJECT_THERMO_CALCULATE_ESTIMATE_REQUEST = "PROJECT_THERMO_CALCULATE_ESTIMATE_REQUEST";
export const PROJECT_THERMO_CALCULATE_ESTIMATE_SUCCESS = "PROJECT_THERMO_CALCULATE_ESTIMATE_SUCCESS";
export const PROJECT_THERMO_CALCULATE_ESTIMATE_FAILURE = "PROJECT_THERMO_CALCULATE_ESTIMATE_FAILURE";

const calculateThermoEstimate = (form) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        PROJECT_THERMO_CALCULATE_ESTIMATE_REQUEST,
        PROJECT_THERMO_CALCULATE_ESTIMATE_SUCCESS,
        PROJECT_THERMO_CALCULATE_ESTIMATE_FAILURE
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/${getState()["app"].currentProject}/estimate_price`,
      schema: project,
      requestOptions: {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify(form),
      },
      success: (result) => {
        dispatch(appActions.setCurrentProject(result.id));
        history.push(buildNextUrl(getState()));
      },
      failure: (error) => {
        dispatch(
          enqueueSnackbarAction({
            message: error.detail || error.error,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              autoHideDuration: 4000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            },
          })
        );
      },
    },
  });
}

// ----------------------
// Project accessory calculation
// ----------------------
export const PROJECT_ACCESSORY_REQUEST = "PROJECT_ACCESSORY_REQUEST";
export const PROJECT_ACCESSORY_SUCCESS = "PROJECT_ACCESSORY_SUCCESS";
export const PROJECT_ACCESSORY_FAILURE = "PROJECT_ACCESSORY_FAILURE";

const calculateAccessories = (projectId) => ({
  [CALL_API]: {
    types: [
      PROJECT_ACCESSORY_REQUEST,
      PROJECT_ACCESSORY_SUCCESS,
      PROJECT_ACCESSORY_FAILURE,
    ],
    endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/` + projectId + "/calculate_accessories",
    schema: project,
  },
});

// ----------------------
// Project get estimation pdf
// ----------------------

export const GET_PROJECT_ESTIMATION_REQUEST = "GET_PROJECT_ESTIMATION_REQUEST";
export const GET_PROJECT_ESTIMATION_RESPONSE =
  "GET_PROJECT_ESTIMATION_RESPONSE";
export const GET_PROJECT_ESTIMATION_FAILURE = "GET_PROJECT_ESTIMATION_FAILURE";

const getEstimation = (projectId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        GET_PROJECT_ESTIMATION_REQUEST,
        GET_PROJECT_ESTIMATION_RESPONSE,
        GET_PROJECT_ESTIMATION_FAILURE,
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/${projectId}/valuation`,
      requestOptions: {
        headers: { Accept: "application/pdf" },
      },
    },
  });

// ----------------------
// Project get quotation pdf
// ----------------------

export const GET_PROJECT_QUOTATION_REQUEST = "GET_PROJECT_QUOTATION_REQUEST";
export const GET_PROJECT_QUOTATION_RESPONSE = "GET_PROJECT_QUOTATION_RESPONSE";
export const GET_PROJECT_QUOTATION_FAILURE = "GET_PROJECT_QUOTATION_FAILURE";

const getQuotation = (projectId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        GET_PROJECT_QUOTATION_REQUEST,
        GET_PROJECT_QUOTATION_RESPONSE,
        GET_PROJECT_QUOTATION_FAILURE,
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/${projectId}/quotation`,
      requestOptions: {
        headers: { Accept: "application/pdf" },
      },
    },
  });

// ----------------------
// Project get customer quotation pdf
// ----------------------

export const GET_PROJECT_CUSTOMER_QUOTATION_REQUEST =
  "GET_PROJECT_CUSTOMER_QUOTATION_REQUEST";
export const GET_PROJECT_CUSTOMER_QUOTATION_RESPONSE =
  "GET_PROJECT_CUSTOMER_QUOTATION_RESPONSE";
export const GET_PROJECT_CUSTOMER_QUOTATION_FAILURE =
  "GET_PROJECT_CUSTOMER_QUOTATION_FAILURE";

const getCustomerQuotation = (projectId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        GET_PROJECT_CUSTOMER_QUOTATION_REQUEST,
        GET_PROJECT_CUSTOMER_QUOTATION_RESPONSE,
        GET_PROJECT_CUSTOMER_QUOTATION_FAILURE,
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/${projectId}/quotationCustomer`,
      requestOptions: {
        headers: { Accept: "application/pdf" },
      },
    },
  });

// ----------------------
// Project generate quotation
// ----------------------

export const GENERATE_PROJECT_QUOTATION_REQUEST =
  "GENERATE_PROJECT_QUOTATION_REQUEST";
export const GENERATE_PROJECT_QUOTATION_RESPONSE =
  "GENERATE_PROJECT_QUOTATION_RESPONSE";
export const GENERATE_PROJECT_QUOTATION_FAILURE =
  "GENERATE_PROJECT_QUOTATION_FAILURE";

const generateQuotation = (projectId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GENERATE_PROJECT_QUOTATION_REQUEST,
        GENERATE_PROJECT_QUOTATION_RESPONSE,
        GENERATE_PROJECT_QUOTATION_FAILURE,
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/${projectId}/generate_quotation`,
      schema: project,
    },
  });
};

// ----------------------
// Project generate layout
// ----------------------

export const PROJECT_LAYOUT_REQUEST = "PROJECT_LAYOUT_REQUEST";
export const PROJECT_LAYOUT_RESPONSE = "PROJECT_LAYOUT_RESPONSE";
export const PROJECT_LAYOUT_FAILURE = "PROJECT_LAYOUT_FAILURE";

const generateLayoutIfNeeded = (projectId, force = 0) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        PROJECT_LAYOUT_REQUEST,
        PROJECT_LAYOUT_RESPONSE,
        PROJECT_LAYOUT_FAILURE,
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/${projectId}/auto_layouter?` + new URLSearchParams({force}),
      schema: project,
    },
  });
};

// ----------------------
// Send mail
// ----------------------

export const SEND_MAIL_PROJECT_QUOTATION_REQUEST =
  "SEND_MAIL_PROJECT_QUOTATION_REQUEST";
export const SEND_MAIL_PROJECT_QUOTATION_RESPONSE =
  "SEND_MAIL_PROJECT_QUOTATION_RESPONSE";
export const SEND_MAIL_PROJECT_QUOTATION_FAILURE =
  "SEND_MAIL_PROJECT_QUOTATION_FAILURE";

const sendMail = (projectId, type, email) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      types: [
        SEND_MAIL_PROJECT_QUOTATION_REQUEST,
        SEND_MAIL_PROJECT_QUOTATION_RESPONSE,
        SEND_MAIL_PROJECT_QUOTATION_FAILURE,
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/${projectId}/mail_pdf?type=${type}&email=${email}`,
      schema: null,
      success: (result) => {
        dispatch(
          enqueueSnackbarAction({
            message: `Mail envoyé avec succès à ${email}`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              action: (key) => (
                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                  Fermer
                </Button>
              ),
            },
          })
        );
      },
      failure: (result) => {
        dispatch(
          enqueueSnackbarAction({
            message: `Une erreur est survenue. Le mail n'a pas été envoyé`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              action: (key) => (
                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                  Fermer
                </Button>
              ),
            },
          })
        );
      },
    },
  });

// ----------------------
// Get available accessories
// ----------------------

export const GET_AVAILABLE_ACCESSORIES_REQUEST =
  "GET_AVAILABLE_ACCESSORIES_REQUEST";
export const GET_AVAILABLE_ACCESSORIES_RESPONSE =
  "GET_AVAILABLE_ACCESSORIES_RESPONSE";
export const GET_AVAILABLE_ACCESSORIES_FAILURE =
  "GET_AVAILABLE_ACCESSORIES_FAILURE";

const getAvailableAccessories = (projectId) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      types: [
        GET_AVAILABLE_ACCESSORIES_REQUEST,
        GET_AVAILABLE_ACCESSORIES_RESPONSE,
        GET_AVAILABLE_ACCESSORIES_FAILURE,
      ],
      endpoint: `${ENDPOINTS.projects[getCurrentProductName()]}/${projectId}/accessoriesAvailable`,
      schema: null,
    },
  });

export const projectsActions = {
  invalidateProjects,
  fetchProjectsIfNeeded,
  fetchArchivedProjectsIfNeeded,
  fetchProjectIfNeeded,
  fetchProjects,
  getEstimation,
  getQuotation,
  getCustomerQuotation,
  generateQuotation,
  generateLayoutIfNeeded,
  add,
  edit,
  calculateEstimate,
  calculateThermoEstimate,
  calculateAccessories,
  sendMail,
  getAvailableAccessories,
};
