import { CALL_API } from "../middleware/api";
import { panelProfil } from "../helpers/schema";
import { shouldFetch } from "../helpers/api";

// ----------------------
// Panel profil Fetching
// ----------------------
export const FETCH_PANEL_PROFILS_REQUEST = "FETCH_PANEL_PROFILS_REQUEST";
export const FETCH_PANEL_PROFILS_SUCCESS = "FETCH_PANEL_PROFILS_SUCCESS";
export const FETCH_PANEL_PROFILS_FAILURE = "FETCH_PANEL_PROFILS_FAILURE";
export const INVALIDATE_PANEL_PROFILS    = "INVALIDATE_PANEL_PROFILS";

function invalidatePanelProfils () {
    return {type: INVALIDATE_PANEL_PROFILS};
}

function fetchPanelProfilsIfNeeded (projectId) {
    return (dispatch, getState) => {
        if (shouldFetch(getState(), "panelProfils")) {
            return dispatch(fetchPanelProfils(projectId));
        }
    };
}

const fetchPanelProfils = (projectId) => ({
    [CALL_API]: {
        types: [
            FETCH_PANEL_PROFILS_REQUEST,
            FETCH_PANEL_PROFILS_SUCCESS,
            FETCH_PANEL_PROFILS_FAILURE
        ],
        endpoint: `panel_profil_thermos?pagination=false&projectId=${projectId}`,
        schema: [panelProfil],
    }
});

export const panelProfilsActions = {
    invalidatePanelProfils,
    fetchPanelProfilsIfNeeded,
};
