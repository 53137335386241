import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentProductName } from "../../helpers/api";
import ListContainer from "../../containers/thermo/projects/Index";
import ClientsIndex from "../../containers/thermo/admin/clients/Index";
import ArchivesIndex from "../../containers/thermo/admin/archives/Index";
import ProductsIndex from "../../containers/thermo/admin/products/Index";
import PricesIndex from "../../containers/thermo/admin/prices/Index";
import OptionsIndex from "../../containers/thermo/admin/options/Index";
import ColorsIndex from "../../containers/thermo/admin/colors/Index";

export default function ThermoRoutes() {
    const productPrefix = getCurrentProductName();

    return <>
        <Route path={`/${productPrefix}/projects`}>
            <ListContainer />
        </Route>
        <Route path={`/${productPrefix}/archives`}>
            <ArchivesIndex />
        </Route>
        <Route path={`/${productPrefix}/clients`}>
            <ClientsIndex />
        </Route>
        <Route path={`/${productPrefix}/products`}>
            <ProductsIndex />
        </Route>
        <Route path={`/${productPrefix}/prices`}>
            <PricesIndex />
        </Route>
        <Route path={`/${productPrefix}/options`}>
            <OptionsIndex />
        </Route>
        <Route path={`/${productPrefix}/colors`}>
            <ColorsIndex />
        </Route>
        <Route>
            <Redirect to={`/${productPrefix}/projects`} />
        </Route>
    </>
}