import { Box } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { appActions } from "../../../actions/app.actions";
import { settingsActions } from "../../../actions/setting.actions";
import HeaderTitle from "../../../components/HeaderTitle";
import { selectSettings } from "../../../helpers/schema";
import ImportCsvButton from "../../settings/ImportCsvButton";

const useStyles = makeStyles({
  table: {
    minWidth: "50%",
    width: "auto",
  },
});

export default function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let forms = {};

  const settings = useSelector((state) => {
    return Object.values(selectSettings(state)) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(settingsActions.fetchSettingsIfNeeded());
    dispatch(appActions.setNextPage("/settings"));
  }, [dispatch]);

  const submitForms = () => {
    Object.values(forms).forEach((form) => {
      form.submit();
    });
  };

  return (
    <>
      <HeaderTitle mb={3} title="paramètres" />

      <Box display="flex" justifyContent="center" mb={3}>
        <ImportCsvButton>Téléverser le fichier CSV</ImportCsvButton>
      </Box>
      {/* <Box display="flex" justifyContent="center">
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell align="right">Valeur</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {settings.map((row) => {
                let setting =
                  SETTINGS.find((setting) => setting.key === row.key) || {};
                return (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Tooltip title={setting.description}>
                        <Typography>{setting.name || row.key}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <MakeAsyncFunction
                        listener={promiseListener}
                        start={EDIT_SETTING_REQUEST}
                        resolve={EDIT_SETTING_RESPONSE}
                        reject={EDIT_SETTING_ERROR}
                        key={row.key}
                      >
                        {(onSubmit) => (
                          <Form
                            onSubmit={(form) => {
                              onSubmit(form);
                            }}
                            initialValues={row}
                            render={({
                              handleSubmit,
                              submitting,
                              pristine,
                              form,
                            }) => {
                              forms[row.key] = form;
                              return (
                                <form onSubmit={handleSubmit}>
                                  <Box display="flex" justifyContent="flex-end">
                                    <TextField
                                      type={setting.type}
                                      name="value"
                                      margin="none"
                                      fullWidth={false}
                                      required
                                    />
                                  </Box>
                                </form>
                              );
                            }}
                          />
                        )}
                      </MakeAsyncFunction>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
      {/* <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button onClick={submitForms} variant="contained" color="primary">
          Enregistrer
        </Button>
      </Box> */}
    </>
  );
}
