import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Box,
  makeStyles,
} from "@material-ui/core";
import ValidationModal from "../ValidationModal";
import MenuActions from "../MenuActions";
import { useDispatch } from "react-redux";
import { productsActions } from "../../actions/product.actions";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ImageSearch from "@material-ui/icons/ImageSearch";
import { getCurrentProductName } from "../../helpers/api";

const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default function ProductsList(props) {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseMenuList = (option, product) => {
    if (option === "Supprimer") {
      setSelectedProduct(product);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleProductDelete = () => {
    setDeleting(true);

    dispatch(productsActions.disable(selectedProduct.id)).then(() => {
      setDeleting(false);
      setOpenModal(false);
    });
  };

  return (
    <>
      <ValidationModal
        loading={deleting}
        onClose={handleCloseModal}
        onClick={handleProductDelete}
        open={openModal}
        title={"Êtes-vous sûr de vouloir supprimer ce produit ?"}
        paragraph={"Sa suppression est définitive."}
      />

      <List>
        {props.products.map((product, i) => (
          <div key={i}>
            <ListItem button>
              <ListItemAvatar>
                {product.image && (
                  <Avatar
                    variant={"circle"}
                    src={
                      process.env.REACT_APP_API_URL + product.image.contentUrl
                    }
                  />
                )}
                {!product.image && (
                  <Avatar>
                    <ImageSearch/>
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={<Typography>{product.name}</Typography>}
                secondary={
                  <Box display="flex">
                    <Grid className={classes.textSecondary} container>
                      <Grid item xs={4}>
                        <Typography variant={"body2"}>
                          {product.reference}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                <MenuActions
                  onClose={(option) => handleCloseMenuList(option, product)}
                  options={{
                    edit: {
                      name: "Editer",
                      link: `/${getCurrentProductName()}/products/${product.id}/edit`
                    },
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li"/>
          </div>
        ))}
      </List>
    </>
  );
}
