import { ADD_ENTITIES } from "../actions/actions";
import { mergeEntitiesWithState, normalizeId, productVariant } from "../helpers/schema";

const schema = productVariant;

export function productVariants(
  state = {
      all: {}
  },
  action
) {
    switch (action.type) {
        case ADD_ENTITIES:
            let data = action.payload.productVariant;
            if (!data) return state

            return {
                ...state,
                all: mergeEntitiesWithState(data, state.all, schema)
            };
        default:
            return state
    }
}