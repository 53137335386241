import React, { useEffect } from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { TextField } from "mui-rff";
import { insulationsActions } from "../../../actions/insulations.actions";
import ImgUploader from "../../../components/imgUploader";
import PropTypes from "prop-types";

const formFields = [
  {
    mdSize: 12,
    xsSize: 12,
    field: <TextField label="Nom" name="name" margin="none" required />,
  },
  {
    mdSize: 12,
    xsSize: 12,
    field: (
      <TextField label="Reference" name="reference" margin="none" required />
    ),
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <TextField
        label="Epaisseur"
        name="thickness"
        margin="none"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
        required
      />
    ),
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <TextField
        label="Compatibilité"
        name="wave"
        margin="none"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">ondes</InputAdornment>,
        }}
      />
    ),
  },
];

export default function InsulationsForm(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(insulationsActions.fetchInsulationsIfNeeded());
  }, [dispatch]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={8} container spacing={2}>
        {formFields.map((item, idx) => (
          <Grid
            className={"mb-4"}
            item
            md={item.mdSize}
            xs={item.xsSize}
            key={idx}
          >
            {item.field}
          </Grid>
        ))}
      </Grid>

      <Grid
        item
        md={4}
        xs={12}
        container
        alignItems={"center"}
        justify={"center"}
      >
        <Grid item lg={8} md={10} xs={8}>
          <ImgUploader
            image={props.image}
            beforeText="Ajouter un icon"
            afterText="Modifier l'icon"
            onResult={(result) => props.setValue("image", result.id)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

InsulationsForm.propTypes = {
  setValue: PropTypes.func.isRequired,
};
