import React from "react";
import { useSelector } from "react-redux";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { selectInsulation } from "../../../helpers/schema";

function createData(label, value) {
  return {
    label,
    value,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default function InsulationsShow() {
  let { insulationId } = useParams(),
    rows = [];

  const classes = useStyles();
  const insulationByIdFromState = useSelector((state) => {
    return selectInsulation(state, insulationId);
  });

  // TODO: persister l'arborescence d'état redux lors de l'actualisation

  if (typeof insulationByIdFromState !== "undefined") {
    if (typeof insulationByIdFromState.reference !== "undefined") {
      rows.push(createData("Référence", insulationByIdFromState.reference));
    }

    if (typeof insulationByIdFromState.name !== "undefined") {
      rows.push(createData("Nom", insulationByIdFromState.name));
    }

    if (typeof insulationByIdFromState.thickness !== "undefined") {
      rows.push(createData("Epaisseur", insulationByIdFromState.thickness));
    }
    if (typeof insulationByIdFromState.wave !== "undefined") {
      rows.push(
        createData("Compatibilité (ondes)", insulationByIdFromState.wave)
      );
    }
  }

  return insulationId ? (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              Isolations
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.label}>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              {insulationByIdFromState?.image && (
                <img
                  alt={insulationByIdFromState.name + "_icon"}
                  width={"50%"}
                  src={
                    process.env.REACT_APP_API_URL +
                    insulationByIdFromState.image.contentUrl
                  }
                ></img>
              )}
              {!insulationByIdFromState?.image &&
                "Aucun icon pour cette isolation"}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  ) : (
    <div>
      <h3>Isolation introuvable</h3>
    </div>
  );
}
