import React from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../../../helpers/store";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { Check } from "@material-ui/icons";

export default function PriceItem(props) {
  return typeof props.entity !== 'undefined' && (
    <MakeAsyncFunction
      listener={promiseListener}
      start={props.start}
      resolve={props.resolve}
      reject={props.reject}
      key={props.key}
    >
      {(onSubmit) => (
        <Form
          onSubmit={(form) => {
            form[props.priceProperty] = parseFloat(form[props.priceProperty]);
            return onSubmit(form);
          }}
          initialValues={{
            id: props.entity.id,
            [props.priceProperty]: props.entity[props.priceProperty]
          }}
          render={({ handleSubmit, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <TextField label="Tarif" name={props.priceProperty || "price"} margin="none" required
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      {!pristine &&
                        <IconButton edge="end" type="submit" disabled={submitting}>
                          <Check color="primary" />
                        </IconButton>
                      }
                    </InputAdornment>
                }}
              />
            </form>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
}
