import {
  FETCH_ACCESSORIES_FAILURE,
  FETCH_ACCESSORIES_REQUEST,
  FETCH_ACCESSORIES_SUCCESS,
  INVALIDATE_ACCESSORIES,
} from "../actions/accessories.actions";
import { ADD_ENTITIES } from "../actions/actions";
import { accessory, mergeEntitiesWithState } from "../helpers/schema";

const schema = accessory;

export function accessories(
  state = {
    isFetching: false,
    didInvalidate: false,
    fetched: false,
    all: {},
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_ACCESSORIES:
      return Object.assign({}, state, {
        ...state,
        didInvalidate: true,
      });
    case ADD_ENTITIES:
      let data = action.payload.accessory;
      if (!data) return state;

      return {
        ...state,
        all: mergeEntitiesWithState(data, state.all, schema),
      };
    case FETCH_ACCESSORIES_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_ACCESSORIES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
      });
    case FETCH_ACCESSORIES_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}
