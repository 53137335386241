import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import arrayMutators from "final-form-arrays";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { useParams } from "react-router-dom";
import { appActions } from "../../../../actions/app.actions";
import {
  EDIT_PRODUCT_ERROR,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_RESPONSE,
} from "../../../../actions/product.actions";
import { selectProduct, selectProductOptions } from "../../../../helpers/schema";
import { promiseListener } from "../../../../helpers/store";
import ProductsForm from "./ProductsForm";
import { productOptionsActions } from "../../../../actions/productOption.actions";

export default function ProductsEdit() {
  let { productId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setNextPage("/thermo/products"));
    dispatch(productOptionsActions.fetchProductOptionsIfNeeded());
  }, [dispatch]);

  let product = useSelector((state) => {
    let p = selectProduct(state, productId) || {};
    p.options = state.products.all[productId]?.options;
    if (Array.isArray(p.options)) {
      p.options = p.options[0].toString();
    }
    return p;
  });

  if (product && product?.variants && product?.variants?.length > 0) {
    product.variants.map((elem) => {
      elem.pitch = elem.pitch.toString();
      elem.area = `${elem.areaMin}_${elem.areaMax}`
      delete elem.product;
    });
  }

  const productOptions = useSelector((state) => {
    return Object.values(selectProductOptions(state)) || [];
  }, shallowEqual);

  return (
    <div>
      <MakeAsyncFunction
        listener={promiseListener}
        start={EDIT_PRODUCT_REQUEST}
        resolve={EDIT_PRODUCT_RESPONSE}
        reject={EDIT_PRODUCT_ERROR}
      >
        {(onSubmit) => (
          <Form
            onSubmit={(form) => {
              form.variants = form.variants.map((e) => {
                e.thickness = parseInt(e.thickness);
                e.pitch = parseInt(e.pitch);

                const areas = e.area.split('_');
                e.areaMin = parseInt(areas[0]);
                e.areaMax = parseInt(areas[1]);

                delete e.product;

                return e;
              });

              return onSubmit(form);
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
              ...arrayMutators,
            }}
            initialValues={product}
            initialValuesEqual={(old, newOne) => old.id === newOne.id}
            render={({ handleSubmit, submitting, form }) => (
              <form onSubmit={handleSubmit}>
                <ProductsForm
                  image={product?.image?.contentUrl}
                  setValue={form.mutators.setValue}
                  productOptions={productOptions}
                />

                <Box mt={1} mb={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Editer
                  </Button>
                </Box>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    </div>
  );
}
