import { Box, Button, CircularProgress, Fab, Popover } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, Route, Switch } from "react-router-dom";
import { projectsActions } from "../../../actions/projects.actions";
import HeaderTitle from "../../../components/HeaderTitle";
import ProjectsList from "../../../components/projects/thermo/ProjectsList";
import SearchList from "../../../components/searchList";
import Step1 from "./tunnel/Step1";
import Step2 from "./tunnel/Step2";
import Step3 from "./tunnel/Step3";
import Step4 from "./tunnel/Step4";
import Step5 from "./tunnel/Step5";
import Step6 from "./tunnel/Step6";
import Step7 from "./tunnel/Step7";
import Step8 from "./tunnel/Step8";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastUpdated: props.lastUpdated,
      search: "",
      projects: [],
      fetching: false,
      page: 1,
    };
  }

  render() {
    return (
      <Switch>
        <Route exact path="/thermo/projects">
          <ProjectsIndex lastUpdated={this.state.lastUpdated} />
        </Route>
        <Route exact path="/thermo/projects/new">
          <Step1 />
        </Route>
        <Route exact path="/thermo/projects/:projectId/step1">
          <Step1 />
        </Route>
        <Route exact path="/thermo/projects/:projectId/step2">
          <Step2 />
        </Route>
        <Route exact path="/thermo/projects/:projectId/step3">
          <Step3 />
        </Route>
        <Route exact path="/thermo/projects/:projectId/step4">
          <Step4 />
        </Route>
        <Route exact path="/thermo/projects/:projectId/step5">
          <Step5 />
        </Route>
        <Route exact path="/thermo/projects/:projectId/step6">
          <Step6 />
        </Route>
        <Route exact path="/thermo/projects/:projectId/step7">
          <Step7 />
        </Route>
        <Route exact path="/thermo/projects/:projectId/step8">
          <Step8 />
        </Route>
      </Switch>
    );
  }
}

Index.propTypes = {
  users: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { lastUpdated } = state.users || {
    isFetching: true,
  };

  const isFetching = state.users.isFetching;

  const users = state.users.all || [];

  return {
    users,
    isFetching,
    lastUpdated,
  };
}

const ProjectsIndex = ({ lastUpdated }) => {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isFetching, setSetFetching] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const dispatch = useDispatch();

  const getProjects = async (givenProjects) => {
    setSetFetching(true);
    const toAddProjects = givenProjects ? givenProjects : projects;

    let newProjects = [];
    try {
      const response = await dispatch(
        projectsActions.fetchProjects(true, true, page, 10, search)
      );
      newProjects = response.payload;
    } catch (e) {
      console.error(e);
    }

    newProjects = [...toAddProjects, ...newProjects];

    setProjects(newProjects);
    setSetFetching(false);
  };

  const handleRefreshClick = () => {
    getProjects([]);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const onSearch = (search) => {
    clearTimeout(searchTimeout);
    const timeoutId = setTimeout(() => {
      setProjects([]);
      setSearch(search);
      setPage(1);
    }, 300);
    setSearchTimeout(timeoutId);
  };

  useEffect(() => {
    getProjects();
  }, [search, page]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const id = open ? "help-popover" : undefined;

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <HeaderTitle
        title="Projets"
        left={
          <>
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleClickPopover}
            >
              <Help /> <Box ml={1}>Aide</Box>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <>
                <Box m={1}>
                  Pour créer un projet, cliquez sur le bouton circulaire rouge +
                  en bas à droite.
                </Box>
                <Box m={1}>
                  Pour rechercher un projet, vous pouvez entrer un ou plusieurs
                  critères de recherche dans la barre de recherche ci-dessous.{" "}
                  <br />
                  Vous pouvez rechercher un projet sur les critères suivants:
                  nom du projet, code postal, le nom ou prénom du créateur, le
                  nom du client ou encore les informations du contact du chantier.{" "}
                  <br />
                  Par exemple vous pouvez rechercher "garage paris Durand" pour
                  chercher un projet situé à Paris qui concernant le garage de
                  M. Durand.
                </Box>
              </>
            </Popover>
          </>
        }
        right={
          <Button
            variant="contained"
            onClick={handleRefreshClick}
            startIcon={<RefreshIcon />}
            disabled={isFetching}
          >
            Actualiser
          </Button>
        }
        bottom={<SearchList onSearch={onSearch} />}
      />
      <div className={"projects-container"} style={{ height: "100%" }}>
        {lastUpdated && (
          <Box display="flex" justifyContent="flex-end" mb={2}>
            (
            <span>
              {" "}
              Dernière mise à jour le :{" "}
              {new Date(lastUpdated).toLocaleTimeString()}.{" "}
            </span>
          </Box>
        )}

        {(isFetching || isFetching) && Object.entries(projects).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            mt={5}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        {!isFetching && !isFetching && Object.entries(projects).length === 0 && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            mt={5}
          >
            <h3>Aucun projet.</h3>
            <span>
              Vous pouvez en créer un via le bouton rouge "+" en bas à droite.
            </span>
          </Box>
        )}

        {Object.entries(projects).length > 0 && (
          <ProjectsList nextPage={nextPage} projects={projects} />
        )}

        <Box position="fixed" bottom={30} right={30}>
          <Fab
            component={Link}
            to="/thermo/projects/new"
            color="primary"
            aria-label="add"
          >
            <AddIcon />
          </Fab>
        </Box>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Index);
