import { makeStyles, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { InsertDriveFile } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field } from "react-final-form";
import {
  ADD_MEDIA_ERROR,
  ADD_MEDIA_REQUEST,
  ADD_MEDIA_RESPONSE,
} from "../actions/media.actions";
import FileUploader from "./fileUploader";

const useStyles = makeStyles((theme) => ({
  imgResponsive: {
    maxWidth: "100%",
    height: "auto",
  },
  btnUpload: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  relative: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function ImgUploader(props) {
  const classes = useStyles();
  const [pdf, setPdf] = useState("");
  let { onResult, beforeText, afterText } = props;

  if (!pdf && props.pdf) {
    setPdf(process.env.REACT_APP_API_URL + props.pdf);
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      {pdf && (
        <Box width="80%">
          <Paper
            style={{
              height: "150px",
            }}
          >
            <a
              href={pdf && pdf}
              target="_blank"
              rel="noopener"
              download
              style={{
                width: "100%",
                textDecoration: "none",
                color: "#000000",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "end",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  height: "85%",
                  overflowY: "hidden",
                  marginTop: "20px",
                }}
              >
                <Box mt={1}>
                  <InsertDriveFile color="primary" fontSize="large" />
                </Box>
                <Box
                  m={2}
                  style={{
                    overflowWrap: "anywhere",
                  }}
                ></Box>
              </Box>
            </a>
          </Paper>
        </Box>
      )}
      <Box mt={pdf ? 1 : 0} display="flex" justifyContent="center">
        <Field name="pdf" component="input" type="hidden" />
        <Button component="label" variant="outlined" color="primary">
          {pdf ? afterText : beforeText}
          <FileUploader
            start={ADD_MEDIA_REQUEST}
            resolve={ADD_MEDIA_RESPONSE}
            reject={ADD_MEDIA_ERROR}
            onResult={(result) => {
              // form.mutators.setValue("pdf", result.id);
              onResult(result);
              setPdf(process.env.REACT_APP_API_URL + result.contentUrl);
            }}
          />
        </Button>
      </Box>
    </Box>
  );
}

ImgUploader.propTypes = {
  onResult: PropTypes.func.isRequired,
  beforeText: PropTypes.string.isRequired,
  afterText: PropTypes.string.isRequired,
};
