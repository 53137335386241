import { TableCell, TableRow, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import {
  EDIT_SHIPPING_ERROR,
  EDIT_SHIPPING_REQUEST,
  EDIT_SHIPPING_RESPONSE,
} from "../../actions/shippings.actions";
import ShippingItem from "./ShippingItem";

export default function ShippingsRow(props) {
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {props.name}
          <Typography
            variant="body2"
            color={"textSecondary"}
            style={{ fontStyle: "italic" }}
            noWrap
          >
            Livraison Zone {props.shippingZone.id}
          </Typography>
        </TableCell>

        {props.shippings.map((shipping, i) => (
          <Fragment key={i}>
            <ShippingItem
              start={EDIT_SHIPPING_REQUEST}
              resolve={EDIT_SHIPPING_RESPONSE}
              reject={EDIT_SHIPPING_ERROR}
              entity={shipping}
              priceProperty={"price"}
            />
          </Fragment>
        ))}
      </TableRow>
    </>
  );
}
