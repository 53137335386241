import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import { Clear, InsertDriveFile, NoteAdd } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ADD_MEDIA_ERROR,
  ADD_MEDIA_REQUEST,
  ADD_MEDIA_RESPONSE,
} from "../../actions/media.actions";
import { projectsActions } from "../../actions/projects.actions";
import FileUploader from "../../components/fileUploader";

export default function UploadArea({ project }) {
  const dispatch = useDispatch();
  const [uploadingFile, setUploadingFile] = useState(false);
  return (
    <>
      {project?.attachedFiles?.map((file, index) => (
        <Grid item xs={4} md={2} key={index}>
          <Paper
            style={{
              height: "150px",
            }}
          >
            <Box
              style={{
                height: "0",
              }}
              textAlign="right"
            >
              <IconButton
                onClick={() => {
                  const files = project.attachedFiles;
                  files.splice(index, 1);
                  dispatch(
                    projectsActions.edit({
                      attachedFiles: files,
                    })
                  );
                }}
              >
                <Clear />
              </IconButton>
            </Box>

            <Link
              href={process.env.REACT_APP_API_URL + file.contentUrl}
              target="_blank"
              rel="noopener"
              download
              style={{
                width: "100%",
                textDecoration: "none",
                color: "#000000",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "end",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  height: "85%",
                  overflowY: "hidden",
                  marginTop: "20px",
                }}
              >
                <Box mt={1}>
                  <InsertDriveFile color="primary" fontSize="large" />
                </Box>
                <Box
                  m={2}
                  style={{
                    overflowWrap: "anywhere",
                  }}
                >
                  <Typography variant="caption">{file.originalName}</Typography>
                </Box>
              </Box>
            </Link>
          </Paper>
        </Grid>
      ))}
      <Grid item xs={4} md={2}>
        <Button
          component="label"
          style={{
            height: "100%",
            width: "100%",
          }}
          variant="outlined"
          color="primary"
          disabled={false}
        >
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {!uploadingFile && <NoteAdd color="primary" fontSize="large" />}
            {uploadingFile && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
              >
                <CircularProgress color="primary" size={20} />
              </Box>
            )}
          </Box>

          <FileUploader
            start={ADD_MEDIA_REQUEST}
            resolve={ADD_MEDIA_RESPONSE}
            reject={ADD_MEDIA_ERROR}
            onChange={(event) => {
              setUploadingFile(true);
            }}
            onResult={(result) => {
              dispatch(
                projectsActions.edit({
                  attachedFiles: [...project.attachedFiles, result.id],
                })
              ).then(() => setUploadingFile(false));
            }}
          />
        </Button>
      </Grid>
    </>
  );
}
