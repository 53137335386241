import React, { useEffect } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, Box, CircularProgress, Fab } from "@material-ui/core";
import { usersActions } from "../../../actions/users.actions";
import UsersList from "../../../components/users/UsersList";
import UsersEdit from "../../../containers/admin/users/UsersEdit";
import UsersNew from "../../../containers/admin/users/UsersNew";
import UsersShow from "../../../containers/admin/users/UsersShow";
import HeaderTitle from "../../../components/HeaderTitle";
import SearchList from "../../../components/searchList";
import { filterElement, selectUsers } from "../../../helpers/schema";
import { getUser } from "../../../helpers/user";

export default function Index(props) {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = React.useState("");
  const [loadItems, _setLoadItems] = React.useState(50);

  const loadItemsRef = React.useRef(loadItems);
  const setLoadItems = (data) => {
    loadItemsRef.current = data;
    _setLoadItems(data);
  };

  const isFetching = useSelector((state) => {
    return state.users.isFetching;
  }, shallowEqual);

  const users = useSelector((state) => {
    return Object.values(selectUsers(state)).filter(user => user.managed && user.id !== getUser().decoded.userId) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(usersActions.fetchUsersIfNeeded());
  });

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(usersActions.invalidateUsers());
    dispatch(usersActions.fetchUsersIfNeeded());
  };

  const onSearch = (search) => {
    setSearchFilter(search);
  };

  const onScrollEnd = () => {
    setLoadItems(loadItemsRef.current + 50);
  };

  return (
    <Switch>
      <Route exact path="/users">
        <HeaderTitle
          mb={3}
          title="Utilisateurs"
          right={
            !isFetching && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleRefreshClick}
                startIcon={<RefreshIcon />}
              >
                Rafraîchir
              </Button>
            )
          }
          bottom={<SearchList onScrollEnd={onScrollEnd} onSearch={onSearch} />}
        />
        {isFetching && Object.entries(users).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        <div className={"users-container"}>
          {!isFetching && Object.entries(users).length === 0 && (
            <h2>Aucune donnée disponible.</h2>
          )}
          {Object.entries(users).length > 0 && (
            <UsersList
              users={Object.values(users)
                .filter((item) =>
                  filterElement(
                    item,
                    ["firstName", "lastName", "email"],
                    searchFilter
                  )
                )
                .slice(0, loadItems)}
            />
          )}

          <Box position="fixed" bottom={30} right={30}>
            <Fab
              color="primary"
              aria-label="add"
              component={Link}
              to={"/users/new"}
            >
              <AddIcon />
            </Fab>
          </Box>
        </div>
      </Route>
      <Route path={"/users/:userId/show"} children={<UsersShow />} />
      <Route path={"/users/:userId/edit"} children={<UsersEdit />} />
      <Route path={"/users/new"} children={<UsersNew />} />
      <Route>
        <Redirect to={"/users"} />
      </Route>
    </Switch>
  );
}
