import { ADD_ENTITIES } from "../actions/actions";
import { mergeEntitiesWithState, slope } from "../helpers/schema";

const schema = slope;

export function slopes(
  state = {
    isFetching: false,
    didInvalidate: false,
    fetched: false,
    all: {},
  },
  action
) {
  switch (action.type) {
    case ADD_ENTITIES:
      let data = action.payload.slope;
      if (!data) return state

      return {
        ...state,
        all: mergeEntitiesWithState(data, state.all, schema),
      };
    default:
      return state;
  }
}
