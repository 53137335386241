import { ADD_ENTITIES } from "../actions/actions";
import { FETCH_CLIENTS_REQUEST, FETCH_CLIENTS_SUCCESS, INVALIDATE_CLIENTS } from "../actions/clients.actions";
import { client, mergeEntitiesWithState } from "../helpers/schema";

const schema = client;

export function clients(
    state = {
        isFetching: false,
        didInvalidate: false,
        fetched: false,
        all: {}
    },
    action
) {
    switch (action.type) {
        case INVALIDATE_CLIENTS:
            return Object.assign({}, state, {
                ...state,
                didInvalidate: true
            })
        case ADD_ENTITIES:
            let data = action.payload.client;
            if (!data) return state

            return {
                ...state,
                all: mergeEntitiesWithState(data, state.all, client)
            };
        case FETCH_CLIENTS_REQUEST:
            return Object.assign({}, state, {
                ...state,
                isFetching: true,
                didInvalidate: false
            })
        case FETCH_CLIENTS_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                fetched: true,
                isFetching: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
}