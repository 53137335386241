import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";

function createData (label, value) {
    return {
        label,
        value,
    };
}

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
});

export default function ClientsShow () {
    let {clientId}    = useParams(),
        rows          = [];

    const classes             = useStyles();
    const clientByIdFromState = useSelector((state) => {
        return state.clients.all[clientId];
    });

    // TODO: persister l'arborescence d'état redux lors de l'actualisation

    if (typeof clientByIdFromState !== "undefined") {
        if (typeof clientByIdFromState.name !== "undefined") {
            rows.push(createData("Nom", clientByIdFromState.name));
        }

        if (typeof clientByIdFromState.company !== "undefined") {
            rows.push(createData("Société", clientByIdFromState.company));
        }

        if (typeof clientByIdFromState.siret !== "undefined") {
            rows.push(createData("SIRET", clientByIdFromState.siret));
        }

        if (typeof clientByIdFromState.phone !== "undefined") {
            rows.push(createData("N° de téléphone", clientByIdFromState.phone));
        }

        if (typeof clientByIdFromState.email !== "undefined") {
            rows.push(createData("Adresse email", clientByIdFromState.email));
        }

        if (typeof clientByIdFromState.postCode !== "undefined") {
            rows.push(createData("Code postal", clientByIdFromState.postCode));
        }

        if (typeof clientByIdFromState.city !== "undefined") {
            rows.push(createData("Ville", clientByIdFromState.city));
        }

        if (typeof clientByIdFromState.type !== "undefined") {
            rows.push(createData("Type", clientByIdFromState.type));
        }

        if (typeof clientByIdFromState.manager !== "undefined") {
            rows.push(createData("Manager", clientByIdFromState.manager));
        }

        if (typeof clientByIdFromState.hasRatio !== "undefined" && clientByIdFromState.hasRatio) {
            rows.push(createData('Application d\'un coefficient sur la grille tarifaire', ''));
        }
    }

    return clientId ? <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell align="center" colSpan={2}>CLIENT</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (<TableRow key={row.label}>
                    <TableCell component="th" scope="row">
                        {row.label}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </TableContainer> : <div>
        <h3>Client introuvable</h3>
    </div>;
}