import React, { useEffect } from "react";
import { Form } from "react-final-form";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../../../helpers/store";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { appActions } from "../../../actions/app.actions";
import UsersForm from "./UsersForm";
import Box from "@material-ui/core/Box";
import {
  ADD_USER_ERROR,
  ADD_USER_REQUEST,
  ADD_USER_RESPONSE,
} from "../../../actions/users.actions";

export default function UserEdit() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setNextPage("/users"));
  }, [dispatch]);

  return (
    <div>
      <MakeAsyncFunction
        listener={promiseListener}
        start={ADD_USER_REQUEST}
        resolve={ADD_USER_RESPONSE}
        reject={ADD_USER_ERROR}
      >
        {(onSubmit) => (
          <Form
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            onSubmit={(form) => {
              form.sellMargin = parseFloat(form.sellMargin);
              form.buyMargin = parseFloat(form.buyMargin);
              form.roles = [form.roles];

              if (form.roles !== "ROLE_SALESPERSON") {
                form.cpInCharge = [];
              }

              return onSubmit(form);
            }}
            initialValues={{ cpInCharge: [], buyMargin: 0, sellMargin: 0 }}
            render={({ handleSubmit, submitting, values, form }) => (
              <form onSubmit={handleSubmit}>
                <UsersForm values={values} setValue={form.mutators.setValue} create />

                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Ajouter
                  </Button>
                </Box>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    </div>
  );
}
