import { ADD_ENTITIES } from "../actions/actions";
import { FETCH_PRODUCT_OPTIONS_REQUEST, FETCH_PRODUCT_OPTIONS_SUCCESS, INVALIDATE_PRODUCT_OPTIONS } from "../actions/productOption.actions";
import { mergeEntitiesWithState, productOption } from "../helpers/schema";

const schema = productOption;

export function productOptions(
  state = {
    isFetching: false,
    didInvalidate: false,
    fetched: false,
    all: {},
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_PRODUCT_OPTIONS:
      state.all = {};
      return Object.assign({}, state, {
        ...state,
        didInvalidate: true
      });
    case ADD_ENTITIES:
      let data = action.payload.productOption;
      if (!data) return state;

      return {
        ...state,
        all: mergeEntitiesWithState(data, state.all, schema),
      };
    case FETCH_PRODUCT_OPTIONS_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false
      });
    case FETCH_PRODUCT_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt
      });
    default:
      return state;
  }
}
