import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

class MenuActions extends Component {
    state = {
        anchorEl: null,
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleCloseMenuList = option => {
        this.setState({anchorEl: null});
        this.props.onClose(option);
    };

    render () {
        const {anchorEl} = this.state;
        const open       = Boolean(anchorEl);
        const listItems  = Object.entries(this.props.options)
                                 .map(([key, value], index) => {
                                     return typeof value.link === "string" ?
                                       <Link key={index} to={value.link} style={{
                                         textDecoration: "none",
                                         display: "block",
                                         color: "#000000",
                                       }}>
                                           <MenuItem key={index} onClick={() => this.handleCloseMenuList(key)}>{value.name}</MenuItem>
                                       </Link> : <MenuItem key={index} onClick={() => this.handleCloseMenuList(key)}>{value.name}</MenuItem>;
                                 });

        return (<div id={"actions"}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={this.handleMenu}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={this.handleCloseMenuList}
                PaperProps={{
                    style: {
                        width: "20ch",
                    },
                }}
            >
                {listItems}
            </Menu>
        </div>);
    }
}

export default MenuActions;
