import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelSummary,
  Fade,
  Grid,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon, Sync } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import arrayMutators from "final-form-arrays";
import { toBlob } from "html-to-image";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import { mediaActions } from "../../../actions/media.actions";
import { projectsActions } from "../../../actions/projects.actions";
import {
  EDIT_SLOPE_ERROR,
  EDIT_SLOPE_REQUEST,
  EDIT_SLOPE_RESPONSE,
} from "../../../actions/slopes.actions";
import { GridColumn } from "../../../components/projects/grid/GridColumn";
import StepperButtons from "../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../components/projects/StepperHeader";
import ValidationModal from "../../../components/ValidationModal";
import {
  isCellAdjacentToSelection,
  isSelected,
  platesFormToSlopeApi,
} from "../../../helpers/grid";
import { getProjectStep, useStyles } from "../../../helpers/project";
import { selectProject } from "../../../helpers/schema";
import { promiseListener } from "../../../helpers/store";

const useStylesStep7 = makeStyles((theme) => ({
  gridContainer: {
    overflowX: "auto",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
  },
}));

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      height: 40,
    },
  },
})(TextField);

export default function Step7() {
  const [activeStep] = useState(6);
  let { projectId } = useParams();
  const constraintId = useRef(0);
  const dispatch = useDispatch();
  const [constraintsDef, setConstraintsDef] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [selectedBySlope, _setSelectedBySlope] = useState({});
  const [generating, setGenerating] = useState(false);
  const [showConfirmRegenerateModal, setShowConfirmRegenerateModal] =
    useState(false);

  const isMouseDown = useRef(false);
  const selectedBySlopeRef = useRef(selectedBySlope);

  const classes = useStyles();
  const classesStep7 = useStylesStep7();

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const setSelectedBySlope = (data) => {
    selectedBySlopeRef.current = data;
    _setSelectedBySlope(data);
  };

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  });

  const regenerateLayout = () => {
    dispatch(projectsActions.generateLayoutIfNeeded(projectId, true)).then(
      () => {
        setShowConfirmRegenerateModal(false);
      }
    );
  };

  const addPlate = (slopeIndex, form, linesSize, type) => {
    let id = ++constraintId.current;
    let y = selectedBySlopeRef.current[slopeIndex]
      .map((value) => value.y)
      .reduce((a, b) => Math.min(a, b));
    selectedBySlopeRef.current[slopeIndex].forEach((item) => {
      form.mutators.setValue(`constraints[${item.x}][${item.y}]`, {
        id,
        y,
        length: selectedBySlopeRef.current[slopeIndex].length,
        new: true,
        type: "plate",
      });
    });

    resetSlopeSelection(slopeIndex);
  };

  const resetSlopeSelection = (slope) => {
    setSelectedBySlope({
      ...selectedBySlopeRef.current,
      [slope]: [],
    });
  };

  const removePlate = (slopeIndex, form) => {
    let selected = selectedBySlopeRef.current[slopeIndex][0];
    let values = form.getState().values;
    let cell = values.constraints[selected.x][selected.y];
    let indexes = [];
    values.constraints[selected.x].forEach((item, index) => {
      if (item.id === cell.id) {
        indexes.push(index);
      }
    });
    indexes.forEach((index) => {
      form.mutators.setValue(`constraints[${selected.x}][${index}]`, false);
    });
    resetSlopeSelection(slopeIndex);
  };

  const handleDocumentMouseUp = (event) => {
    if (event.button !== 2 && isMouseDown.current) {
      isMouseDown.current = !isMouseDown.current;
    }
  };

  const handleDocumentMouseDown = (event) => {
    if (event.button !== 2 && !isMouseDown.current) {
      isMouseDown.current = !isMouseDown.current;
    }
  };

  useEffect(() => {
    setGenerating(true);
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(projectsActions.generateLayoutIfNeeded(projectId)).then(() =>
      setGenerating(false)
    );
    dispatch(appActions.setNextPage("/renov/projects/{currentProject}/step8"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject]);

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentMouseDown);
    document.addEventListener("mouseup", handleDocumentMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleDocumentMouseUp);
      document.addEventListener("mousedown", handleDocumentMouseDown);
    };
  }, []);

  const addCellDefinition = (defs) => {
    let newDefs = { ...constraintsDef };

    defs.forEach((def) => {
      if (!newDefs[def.length]) {
        newDefs = {
          ...newDefs,
          [def.name]: {
            color: def.color,
            name: def.name,
          },
        };
      }
    });

    setConstraintsDef(newDefs);
  };

  const selectCell = (y, x, slope, values) => {
    let shouldReset = false;

    // if it's not a plate we doesn't select the cell
    if (!!values[x][y] && values[x][y].type !== "plate") {
      return;
    }

    if (values[x][y]) {
      shouldReset = true;
    }

    selectedBySlopeRef.current[slope].forEach((cell) => {
      if (values[cell.x][cell.y]) {
        shouldReset = true;
      }
    });

    if (
      !shouldReset &&
      (selectedBySlopeRef.current[slope].length === 0 ||
        isCellAdjacentToSelection({ y, x }, selectedBySlopeRef.current[slope]))
    ) {
      addCellToSelected(y, x, slope);
    } else {
      addCellToSelected(y, x, slope, true);
    }
  };

  const addCellToSelected = (y, x, slope, reset = false) => {
    if (!isSelected(y, x, selectedBySlopeRef.current[slope])) {
      let start = reset ? [] : selectedBySlopeRef.current[slope];
      setSelectedBySlope({
        ...selectedBySlopeRef.current,
        [slope]: [...start, { y, x }],
      });
    }
  };

  const removeAllPlates = (slopeIndex, form) => {
    let values = form.getState().values;
    let indexes = [];

    values.constraints.forEach((column, columnIndex) => {
      values.constraints[columnIndex].forEach((item, index) => {
        if (item.type === "plate") {
          indexes.push({
            x: columnIndex,
            y: index,
          });
        }
      });
    });

    indexes.forEach((index) => {
      form.mutators.setValue(
        `constraints[${index.x}][${index.y}]`,
        false
      );
    });
    resetSlopeSelection(slopeIndex);
  };

  const selectionIsEntitie = (slope, values) => {
    let cell = selectedBySlopeRef.current[slope][0];

    if (
      selectedBySlopeRef.current[slope].length === 1 &&
      selectedBySlopeRef.current[slope][0]
    ) {
      return values[cell.x][cell.y] !== false;
    }

    return false;
  };

  let changed = false;
  if (
    project.slopes &&
    project.slopes.length > 0 &&
    initialValues.slopes &&
    initialValues.slopes.length > 0
  ) {
    project.slopes.forEach((slope, index) => {
      if (
        slope.platesUpdatedAt !== initialValues.slopes[index].platesUpdatedAt
      ) {
        changed = true;
      }
    });
  }

  if (
    !initialValues.slopes ||
    (initialValues.slopes.length === 0 && project.slopes) ||
    changed
  ) {
    let slopes = project.slopes ? Object.values(project.slopes) : [];
    let selected = {};
    let values = [];

    if (getProjectStep(project) >= 6) {
      slopes.forEach((slope, index) => {
        slope = Object.assign({}, slope);

        if (slope.linesSize.length === 0) {
          slope.linesSize.push(1);
        }

        let entities = [];

        Array(slope.columnNumber)
          .fill(null)
          .forEach(() => {
            entities.push(Array(slope.linesSize.length).fill(false));
          });

        slope.constraints.forEach((constraint) => {
          entities[constraint.x].splice(
            constraint.y,
            constraint.length,
            ...Array(constraint.length).fill(constraint)
          );
        });

        if (slope.plates) {
          slope.plates.forEach((plate) => {
            entities[plate.x].splice(
              plate.y,
              plate.length,
              ...Array(plate.length).fill({
                ...plate,
                type: "plate",
              })
            );
          });
        }

        slope.constraints = entities;

        values.push(slope);

        if (selectedBySlopeRef.current[index] === undefined) {
          selected = {
            ...selected,
            [index]: [],
          };
        }
      });

      if (project.nomenclatures) {
        addCellDefinition(Object.values(project.nomenclatures));
      }

      if (Object.keys(selected).length > 0) {
        setSelectedBySlope(selected);
      }

      setInitialValues({
        slopes: values,
      });
    }
  }

  const retrieveCalpinageImages = async () => {
    let calepinageImages = [];
    let slopes = initialValues.slopes;
    await Promise.all(
      slopes.map(async (slope, slopeIndex) => {
        const grid = document.querySelector(`#slope_${slopeIndex}`);
        grid.style.overflowX = "unset";
        if (!grid) return;

        await toBlob(grid).then(async (blob) => {
          await dispatch(mediaActions.add(blob, slope.name, projectId)).then(
            ({ payload }) => {
              calepinageImages.push(payload.id);
            }
          );
        });
      })
    );

    dispatch(projectsActions.edit({ calepinageImages }));
  };

  if (generating) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        <CircularProgress size={100} />
      </Box>
    );
  }

  if (!isFetchingProject && project.name && getProjectStep(project) < 6) {
    return (
      <Redirect to={`/renov/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && initialValues.slopes && (
        <>
          <StepperHeader
            submit={retrieveCalpinageImages}
            activeStep={activeStep}
            projectId={parseInt(projectId)}
            shouldLink={false}
          />
          <Container maxWidth="lg">
            <Grid>
              <Box mt={2} md={12} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color={"primary"}
                  onClick={() => setShowConfirmRegenerateModal(true)}
                  disabled={generating}
                >
                  {!generating && (
                    <>
                      <Sync /> Recalculer
                    </>
                  )}
                  {generating && <CircularProgress size={20} />}
                </Button>
              </Box>
            </Grid>
            <Grid className={"mb-4"} item xs={12}>
              <Box mt={2}>
                {initialValues.slopes.map((slope, slopeIndex) => (
                  <MakeAsyncFunction
                    listener={promiseListener}
                    start={EDIT_SLOPE_REQUEST}
                    resolve={EDIT_SLOPE_RESPONSE}
                    reject={EDIT_SLOPE_ERROR}
                  >
                    {(onSubmit) => (
                      <Form
                        initialValues={slope}
                        onSubmit={(form) =>
                          onSubmit(platesFormToSlopeApi(form))
                        }
                        mutators={{
                          setValue: (
                            [field, value],
                            state,
                            { changeValue }
                          ) => {
                            changeValue(state, field, () => value);
                          },
                          ...arrayMutators,
                        }}
                        render={({ form, handleSubmit, values }) => {
                          return (
                            <Fade key={slopeIndex} in={true}>
                              <ExpansionPanel defaultExpanded>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={classes.heading}>
                                    {slope.name}
                                  </Typography>
                                </ExpansionPanelSummary>
                                <Box display="flex" justifyContent="center">
                                  <Box
                                    id={`slope_${slopeIndex}`}
                                    mb={3}
                                    mt={1}
                                    p={2}
                                    display="flex"
                                    className={classesStep7.gridContainer}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="column-reverse"
                                    >
                                      <FieldArray
                                        type="number"
                                        name={`linesSize`}
                                        inputProps={{
                                          step: "0.01",
                                          min: "0",
                                        }}
                                      >
                                        {({ fields }) =>
                                          fields.map((name, lineIndex) => (
                                            <Box
                                              key={lineIndex}
                                              display="flex"
                                              justifyContent="center"
                                            >
                                              <Box width="80px" mr={2}>
                                                <CustomTextField
                                                  size={"small"}
                                                  disabled
                                                  inputProps={{
                                                    min: 0,
                                                    style: {
                                                      textAlign: "center",
                                                    },
                                                  }}
                                                  value={
                                                    values.linesSize[lineIndex]
                                                  }
                                                  variant={"outlined"}
                                                  name={name}
                                                />
                                              </Box>
                                              <Box
                                                mr={1}
                                                minWidth={20}
                                                textAlign="center"
                                                alignSelf="center"
                                              >
                                                <Typography>
                                                  {lineIndex + 1}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          ))
                                        }
                                      </FieldArray>
                                    </Box>

                                    {Array(values.constraints.length)
                                      .fill(null)
                                      .map((column, columnIndex) => (
                                        <Box
                                          key={columnIndex}
                                          display="flex"
                                          justifyContent="center"
                                          flexDirection="column"
                                          textAlign="center"
                                        >
                                          <Typography>
                                            {columnIndex + 1}
                                          </Typography>
                                          <GridColumn
                                            linesSize={values.linesSize}
                                            column={columnIndex}
                                            columnValues={
                                              values.constraints[columnIndex]
                                            }
                                            constraintsDef={constraintsDef}
                                            selected={
                                              selectedBySlopeRef.current[
                                              slopeIndex
                                              ] || []
                                            }
                                            onMouseDown={(y, x) => {
                                              selectCell(
                                                y,
                                                x,
                                                slopeIndex,
                                                values.constraints
                                              );
                                            }}
                                            onMouseEnter={(y, x) => {
                                              if (isMouseDown.current) {
                                                selectCell(
                                                  y,
                                                  x,
                                                  slopeIndex,
                                                  values.constraints
                                                );
                                              }
                                            }}
                                          />
                                        </Box>
                                      ))}
                                  </Box>
                                </Box>
                                <Box>
                                  <Button
                                    color="primary"
                                    disabled={
                                      selectedBySlopeRef.current[slopeIndex]
                                        .length === 0 ||
                                      selectionIsEntitie(
                                        slopeIndex,
                                        values.constraints
                                      )
                                    }
                                    onClick={() => {
                                      addPlate(
                                        slopeIndex,
                                        form,
                                        values.linesSize,
                                        "plate"
                                      );
                                      handleSubmit(values);
                                    }}
                                  >
                                    Ajouter
                                  </Button>

                                  {
                                    <Button
                                      color="primary"
                                      disabled={
                                        !selectionIsEntitie(
                                          slopeIndex,
                                          values.constraints
                                        )
                                      }
                                      onClick={() =>
                                        removePlate(slopeIndex, form)
                                      }
                                    >
                                      supprimer
                                    </Button>
                                  }
                                </Box>
                                <Divider />
                                <ExpansionPanelActions>
                                  <Box
                                    flexGrow={1}
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box display="flex">
                                      <Button
                                        size="small"
                                        color="primary"
                                        onClick={() =>
                                          removeAllPlates(slopeIndex, form)
                                        }
                                      >
                                        Purger la grille
                                      </Button>
                                    </Box>
                                    <Button
                                      size="small"
                                      color="primary"
                                      disabled={
                                        selectedBySlopeRef.current[slopeIndex]
                                          .length === 0
                                      }
                                      onClick={() =>
                                        resetSlopeSelection(slopeIndex)
                                      }
                                    >
                                      Réinitialiser la selection
                                    </Button>
                                  </Box>
                                </ExpansionPanelActions>
                              </ExpansionPanel>
                            </Fade>
                          );
                        }}
                      />
                    )}
                  </MakeAsyncFunction>
                ))}
                <StepperButtons
                  submit={retrieveCalpinageImages}
                  activeStep={activeStep}
                  projectId={projectId}
                  submitting={false}
                />
              </Box>
              <ValidationModal
                open={showConfirmRegenerateModal}
                onClose={() => setShowConfirmRegenerateModal(false)}
                title={"Voulez-vous vraiment régénérer le calepinage ?"}
                paragraph={
                  "L'ensemble du calepinage sera régénéré. Si vous avez ajouté des plaques manuellement, elle seront supprimées."
                }
                loading={generating}
                onClick={regenerateLayout}
              />
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}
