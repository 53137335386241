import { setIn } from "final-form";

export function formReturnHandler(response) {
  if (response && response.payload && response.payload.violations) {
    let result = {};

    response.payload.violations.forEach((violation) => {
      result[violation.propertyPath] = violation.message;
    });

    return result;
  }
}

export function constraintGridToApi(constraints) {
  let form = [];

  constraints.forEach((line, index) => {
    line.forEach((cell, index) => {
      if (cell) form.push({});
    });
  });
}

// To be passed to React Final Form
export const validateFormValues = (schema) => async (values) => {
  if (typeof schema === "function") {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export const convertBase64ToBlob = (dataURL, name) => {
  const BASE64_MARKER = ";base64,";

  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);

  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    parts = dataURL.split(",");
    contentType = parts[0].split(":")[1];
    raw = decodeURIComponent(parts[1]);
    const blob = new Blob([raw], { type: contentType });
    return new File([blob], name);
  }

  parts = dataURL.split(BASE64_MARKER);
  contentType = parts[0].split(":")[1];
  raw = window.atob(parts[1]);
  const rawLength = raw.length;

  let uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  const blob = new Blob([uInt8Array], { type: contentType, name });
  return new File([blob], name);
};
