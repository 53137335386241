import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from "@material-ui/core";
import { useParams } from "react-router-dom";
import FaceIcon from "@material-ui/icons/Face";
import { humanReadableRoles } from "../../../helpers/constante";

function createData (label, value) {
    return {
        label,
        value,
    };
}

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
});

const ROLE_ADMIN       = "ROLE_ADMIN";
const ROLE_SALESPERSON = "ROLE_SALESPERSON";
const ROLE_MERCHANT    = "ROLE_MERCHANT";
const ROLE_ROOFER      = "ROLE_ROOFER";

const AVAILABLES_ROLES = {
    ROLE_ADMIN: "Admin",
    ROLE_SALESPERSON: "Commercial",
    ROLE_MERCHANT: "Négociant",
    ROLE_ROOFER: "Poseur",
};

export default function UsersShow () {
    let {userId}      = useParams(),
        strRole       = "",
        strCpInCharge = "",
        rows          = [];

    const classes           = useStyles();
    const userByIdFromState = useSelector((state) => {
        return state.users.all[userId];
    });

    // TODO: persister l'arborescence d'état redux lors de l'actualisation

    if (typeof userByIdFromState !== "undefined") {
        if (typeof userByIdFromState.roles !== "undefined") {
            let rolesData = [];
            let productsData = [];
            
            for (let i = 0; i < userByIdFromState.roles.length; i++) {
                const role = userByIdFromState.roles[i];

                if (role.startsWith('ROLE_PRODUCT')) {
                    productsData.push(<Chip color="secondary" label={humanReadableRoles[role].toUpperCase()}/>);
                } else if (humanReadableRoles[role]) {
                    rolesData.push(<Chip color="primary" icon={<FaceIcon/>} label={humanReadableRoles[role]}/>);
                }
            }

            rows.push(createData("Rôle", rolesData));
            rows.push(createData("Produits", productsData));
        }

        if (typeof userByIdFromState.cpInCharge !== "undefined") {
            // eslint-disable-next-line array-callback-return
            userByIdFromState.cpInCharge.map((cp, index, arr) => {
                let delimiter = index === arr.length - 1 ? "" : ", ";

                strCpInCharge += cp + delimiter;
            });
        }

        if (typeof userByIdFromState.firstName !== "undefined") {
            rows.push(createData("Prénom", userByIdFromState.firstName));
        }

        if (typeof userByIdFromState.lastName !== "undefined") {
            rows.push(createData("Nom", userByIdFromState.lastName));
        }

        if (typeof userByIdFromState.company !== "undefined") {
            rows.push(createData("Société", userByIdFromState.company));
        }

        if (typeof userByIdFromState.phone !== "undefined") {
            rows.push(createData("N° de téléphone", userByIdFromState.phone));
        }

        if (typeof userByIdFromState.email !== "undefined") {
            rows.push(createData("Adresse email", userByIdFromState.email));
        }

        if (typeof userByIdFromState.postCode !== "undefined") {
            rows.push(createData("Code postal", userByIdFromState.postCode));
        }

        if (typeof userByIdFromState.city !== "undefined") {
            rows.push(createData("Ville", userByIdFromState.city));
        }

        if (strCpInCharge !== "") {
            rows.push(createData("Codes postaux en charge", strCpInCharge));
        }

        if (typeof userByIdFromState.buyMargin !== "undefined") {
            rows.push(createData("Marge de vente", userByIdFromState.buyMargin));
        }

        if (typeof userByIdFromState.sellMargin !== "undefined") {
            rows.push(createData("Marge par défaut", userByIdFromState.sellMargin));
        }
    }

    return userId ? <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" colSpan={2}>UTILISATEUR</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (<TableRow key={row.label}>
                    <TableCell component="th" scope="row">
                        {row.label}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </TableContainer> : <div>
        <h3>Utilisateur introuvable</h3>
    </div>;
}