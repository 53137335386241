export function isSelected(y, x, selected = []) {
  let result = false;

  selected.forEach((item) => {
    if (item.y === y && item.x === x) result = true;
  });

  return result;
}

export function arraysEquals(a, b) {
  if (a.length !== b.length) return false;

  let result = true;
  a.forEach((item, index) => {
    if (item !== b[index]) result = false;
  });

  return result;
}

export function areCellsVerticallyAdjacent(cell1, cell2) {
  return (
    cell1.x === cell2.x && (cell1.y - cell2.y === -1 || cell1.y - cell2.y === 1)
  );
}

export function isCellAdjacentToSelection(cell, selected) {
  let result = false;
  selected.forEach((item) => {
    if (areCellsVerticallyAdjacent(cell, item)) {
      result = true;
    }
  });
  return result;
}

export function buildConstraintFromSelected(selected) {
  let start = 1000;
  let end = 0;

  selected.forEach((item) => {
    if (item.y < start) start = item.y;
    if (item.y > end) end = item.y;
  });

  return {
    x: selected[0].x,
    y: start,
    length: end - start,
  };
}

export function buildConstraintWithStyleForColumn(
  column,
  defs,
  linesSize,
  queue = null
) {
  let itemsByStart = {};

  column.forEach((item) => {
    if (item.id) {
      if (!itemsByStart[column.findIndex((cell) => cell.id === item.id)]) {
        itemsByStart = {
          ...itemsByStart,
          [column.findIndex((cell) => cell.id === item.id)]: {
            type: "constraint",
            constraint: {
              ...item,
              length: column.filter((col) => col && col.id === item.id).length,
            },
            start: item.y,
          },
        };
      }
    }
  });

  if (queue) {
    queue.forEach((item, index) => {
      let start = 1000;
      item.values.forEach((value) => {
        if (value.y < start) start = value.y;
      });

      itemsByStart = {
        ...itemsByStart,
        [start]: {
          ...item,
          length: item.values.length,
          queue: true,
        },
      };
    });
  }

  Object.keys(itemsByStart)
    .reverse()
    .forEach((key) => {
      let item = itemsByStart[key];
      if (!item.queue) {
        const constraint = item.constraint;
        let def = defs[constraint.name];
        column.splice(key, constraint.length, {
          ...constraint,
          color: def ? def.color : "#ffffff",
          new: constraint.new,
          name: def ? def.name : "-",
        });
      } else {
        column.splice(key, item.length, {
          queue: item.queue,
          type: item.type,
          length: item.length,
          color: "#000000",
        });
      }
    });

  return column;
}

export function buildConstraintForSlope(values, linesSize) {
  let constraints = {};

  values.forEach((column, columnIndex) => {
    column.forEach((item, index) => {
      if (item.id && item.type !== "plate") {
        if (constraints[item.id]) {
          constraints[item.id].length++;
        } else {
          let constraint = {
            x: columnIndex,
            y: index,
            type: item.type,
            length: 1,
          };

          if (!item.new) constraint.id = item.id;

          constraints = {
            ...constraints,
            [item.id]: constraint,
          };
        }
      }
    });
  });

  return Object.values(constraints);
}

export function buildPlatesForSlope(values, linesSize) {
  let plates = {};

  values.forEach((column, columnIndex) => {
    column.forEach((item, index) => {
      if (item.id && item.type === "plate") {
        if (plates[item.id]) {
          plates[item.id].length++;
        } else {
          let plate = {
            x: columnIndex,
            y: index,
            type: item.type,
            length: 1,
          };

          if (!item.new) plate.id = item.id;

          plates = {
            ...plates,
            [item.id]: plate,
          };
        }
      }
    });
  });

  return Object.values(plates);
}

export function constraintsFormToApi(values) {
  let converted = JSON.parse(JSON.stringify(values));

  converted.slopes.forEach((slope, slopeIndex) => {
    converted.slopes[slopeIndex] = {
      id: slope.id,
      linesSize: slope.linesSize,
      constraints: buildConstraintForSlope(
        slope.constraints,
        converted.slopes[slopeIndex].linesSize
      ),
    };
  });

  return converted;
}

export function constraintsFormToSlopeApi(values) {
  let convertedSlope = JSON.parse(JSON.stringify(values));

  return {
    id: convertedSlope.id,
    linesSize: convertedSlope.linesSize,
    constraints: buildConstraintForSlope(
      convertedSlope.constraints,
      convertedSlope.linesSize
    ),
  };
}

export function platesFormToApi(values) {
  let convertedProject = JSON.parse(JSON.stringify(values));

  convertedProject.slopes.forEach((slope, slopeIndex) => {
    convertedProject.slopes[slopeIndex] = {
      id: slope.id,
      plates: buildPlatesForSlope(slope.constraints, slope.linesSize),
    };
  });

  return convertedProject;
}

export function platesFormToSlopeApi(values) {
  let convertedSlope = JSON.parse(JSON.stringify(values));

  return {
    id: convertedSlope.id,
    plates: buildPlatesForSlope(
      convertedSlope.constraints,
      convertedSlope.linesSize
    ),
  };
}

export function heightLeftForSlope(slope) {
  let tab = [];
  slope.linesSize.forEach((e) => {
    if (e === undefined || e === null) {
      tab.push("0");
    } else {
      tab.push(e.toString().replace(",", "."));
    }
  });
  const rest = (
    slope.height - tab.reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0)
  ).toFixed(2);

  if (rest === 0) {
    return Math.abs(rest);
  }

  return rest;
}

export function isSlopeFull(slope) {
  return heightLeftForSlope(slope) < 0;
}
