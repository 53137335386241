import {
  Box,
  Button,
  CardMedia,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Paper,
  Slider,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "mui-rff";
import React, { Fragment, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Document, Page, pdfjs } from "react-pdf";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../../actions/app.actions";
import { clientsActions } from "../../../../actions/clients.actions";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../../actions/projects.actions";
import StepperButtons from "../../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../../components/projects/StepperHeader";
import SendMailModal from "../../../../components/SendMailModal";
import { getProjectStep, useStyles } from "../../../../helpers/project";
import { selectProject } from "../../../../helpers/schema";
import { promiseListener } from "../../../../helpers/store";
import { getUser } from "../../../../helpers/user";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Step4() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(3);
  const [estimationPdf, setEstimationPdf] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [pdfPagesNum, setPdfPagesNum] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [showSendMailModal, setShowSendMailModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  let submit;

  const isUpdatingProject = useSelector((state) => {
    return state.projects.isUpdating;
  });

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  }, shallowEqual);

  let estimate = project && project?.productVariant ? project?.productVariant.price : null;

  if (typeof project?.productColor?.extraPrice !== 'undefined') {
    estimate += project.productColor.extraPrice;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setPdfPagesNum(numPages);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const formatterEuro = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  });

  useEffect(() => {
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(clientsActions.fetchClientsIfNeeded());
    dispatch(appActions.setNextPage("/thermo/projects/{currentProject}/step4"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject, project.priceMargin]);

  const popoverOpen = Boolean(anchorEl);

  useEffect(() => {}, [estimate]);

  if (!isFetchingProject && project.name && getProjectStep(project) < 4) {
    return (
      <Redirect to={`/thermo/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={{ priceMargin: project.priceMargin }}
              onSubmit={onSubmit}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                form,
                handleSubmit,
                submitting,
                values,
                pristine,
              }) => {
                submit = handleSubmit;
                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={parseInt(projectId)}
                      submitting={submitting}
                      shouldLink={pristine}
                    />
                    <Container maxWidth="lg">
                      <Box mt={2} mb={4} display="flex" justifyContent="center">
                        <Chip
                          label={project.name + " (#" + projectId + ")"}
                          color="primary"
                          size="medium"
                          variant={"outlined"}
                        />
                      </Box>
                      <Grid
                        className={"mb-4"}
                        container
                        spacing={2}
                        justify="center"
                      >
                        {/* ******** Produit ********* */}
                        <Grid item xs={12} md={6}>
                          <Paper>
                            <Box display="flex" flexDirection="column">
                              <Box
                                mt={1}
                                display="flex"
                                justifyContent="center"
                              >
                                <Typography variant="body1">Produit</Typography>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  m={2}
                                  className={classes.boxSize}
                                >
                                  <CardMedia
                                    className={classes.image}
                                    image={
                                      process.env.REACT_APP_API_URL +
                                      project.productVariant?.product?.image
                                        ?.contentUrl
                                    }
                                    title={project.productVariant?.name}
                                    alt={project.productVariant?.name}
                                  />
                                </Box>
                                <Box
                                  display="flex"
                                  flexGrow={1}
                                  justifyContent="center"
                                  alignItems="start"
                                  flexDirection="column"
                                  m={2}
                                >
                                  <strong>
                                    {project.productVariant?.name}
                                  </strong>
                                  <p>{project.productColor?.name}</p>
                                </Box>
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>

                        {/* ********** Résumé Tarifs Estimés ********* */}
                        {estimate && (
                          <>
                            <Box mt={2} mb={2}>
                              <Divider m={3} />
                            </Box>
                            <Grid item md={10} xs={12}>
                              <Grid container justify="center">

                                <Grid item md={6} xs={12}>
                                  <Box p={1}>
                                    <TextField
                                      disabled
                                      size="small"
                                      type="number"
                                      variant="filled"
                                      label={"Prix sans marge"}
                                      name={`Prix sans marge`}
                                      value={(
                                        estimate
                                      ).toFixed(2)}
                                      InputProps={{
                                        endAdornment: "€",
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <Box mt={2} mb={2}>
                                <Divider m={3} />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Box display="flex" flexDirection="column">
                                <Box
                                  mt={1}
                                  mb={5}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Typography id="price-margin" variant="body1">
                                    Marge appliquée
                                  </Typography>
                                </Box>
                                <Box display="flex" justifyContent="center">
                                  <Slider
                                    aria-labelledby="price-margin"
                                    valueLabelDisplay="on"
                                    value={values.priceMargin}
                                    onChange={(event, newValue) =>
                                      form.mutators.setValue(
                                        "priceMargin",
                                        newValue
                                      )
                                    }
                                    min={-10}
                                    max={100}
                                  />
                                </Box>
                                <Box display="flex" justifyContent="center">
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={async () => {
                                      await submit();
                                    }}
                                    disabled={pristine}
                                  >
                                    Mettre à jour
                                  </Button>
                                </Box>
                              </Box>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Box mt={4} mb={2}>
                        <Divider m={3} />
                      </Box>
                      {estimate && (
                        <>
                          <Grid item xs={12} md={12}>
                            <Box display="flex" flexDirection="column">
                              <Box
                                mt={1}
                                mb={5}
                                display="flex"
                                justifyContent="center"
                              >
                                <Typography id="price-margin" variant="body1">
                                  Prix au m² (tarif estimatif)
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                {isUpdatingProject && (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    flexGrow={1}
                                  >
                                    <CircularProgress size={40} />
                                  </Box>
                                )}
                                {!isUpdatingProject && (
                                  <>
                                    <Box flexGrow={1} flexBasis={0}></Box>
                                    <Typography
                                      style={{
                                        fontSize: "Roboto",
                                        borderRadius: "4px",
                                        padding: "10px",
                                        width: "auto",
                                        border: "#dc2c32 solid 1px",
                                      }}
                                      variant="h5"
                                      color="primary"
                                    >
                                      {formatterEuro.format(
                                        estimate * (1 + values.priceMargin / 100)
                                      )}
                                    </Typography>
                                    <Typography
                                      style={{
                                        flexGrow: 1,
                                        flexBasis: 0,
                                        marginLeft: "10px",
                                        fontStyle: "italic",
                                      }}
                                    >
                                      / m<sup>2</sup>
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Box mt={2} mb={2}>
                              <Divider m={3} />
                            </Box>
                          </Grid>
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={loadingPdf}
                            onClick={() => {
                              setLoadingPdf(true);
                              dispatch(
                                projectsActions.getEstimation(projectId)
                              ).then((data) => {
                                setEstimationPdf(
                                  window.URL.createObjectURL(data.payload)
                                );
                                setOpen(true);
                                setLoadingPdf(false);
                              }).catch(error => setLoadingPdf(false));
                            }}
                          >
                            {!loadingPdf && "Générer le pdf"}
                            {loadingPdf && <CircularProgress size={20} />}
                          </Button>
                          {/* name: `${project.name}-${project.id}-devis-codifie.pdf`, */}
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth={false}
                            maxWidth={"xl"}
                          >
                            <DialogContent>
                              {estimationPdf && (
                                <>
                                  <Document
                                    file={estimationPdf}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                  >
                                    {[...Array(pdfPagesNum)].map((x, i) => (
                                      <Fragment key={i}>
                                        <Page pageNumber={i + 1} />
                                        {i + 1 < pdfPagesNum && <hr />}
                                      </Fragment>
                                    ))}
                                  </Document>
                                </>
                              )}
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setShowSendMailModal(true);
                                  handleClose();
                                }}
                                color="primary"
                              >
                                Envoyer par mail
                              </Button>
                              <a
                                href={estimationPdf}
                                download={`${project.name}-${project.id}-devis-estimatif.pdf`}
                                target="_blank"
                              >
                                <Button color="primary">Télécharger</Button>
                              </a>
                              <Button
                                onClick={handleClose}
                                color="primary"
                                autoFocus
                              >
                                Fermer
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <SendMailModal
                            open={showSendMailModal}
                            defaultValue={getUser()?.decoded?.username}
                            type="VALUATION"
                            projectId={projectId}
                            onClose={() => setShowSendMailModal(false)}
                          />
                        </>
                      )}

                      <form className={classes.form} onSubmit={handleSubmit}>
                        <Field
                          name="priceMargin"
                          component="input"
                          type="hidden"
                        />
                        <Box mt={2}>
                          <StepperButtons
                            submit={submit}
                            activeStep={activeStep}
                            projectId={parseInt(projectId)}
                            submitting={submitting}
                            shouldLink={pristine}
                          />
                        </Box>
                      </form>
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
