import { CALL_API } from "../middleware/api";
import {product} from "../helpers/schema";
import { getCurrentProductName, shouldFetch } from "../helpers/api";
import {buildNextUrl, history} from "../helpers/history";
import {closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction} from "./notifications.actions";
import Button from "@material-ui/core/Button";
import React from "react";
import { ENDPOINTS } from "../helpers/constante";

// ----------------------
// Product Fetching
// ----------------------
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const INVALIDATE_PRODUCTS    = "INVALIDATE_PRODUCTS";

function invalidateProducts () {
    return {type: INVALIDATE_PRODUCTS};
}

function fetchProductsIfNeeded () {
    return (dispatch, getState) => {
        if (shouldFetch(getState(), "products")) {
            return dispatch(fetchProducts());
        }
    };
}

const fetchProducts = () => ({
    [CALL_API]: {
        types: [
            FETCH_PRODUCTS_REQUEST,
            FETCH_PRODUCTS_SUCCESS,
            FETCH_PRODUCTS_FAILURE
        ],
        endpoint: `${ENDPOINTS.products[getCurrentProductName()]}?pagination=false`,
        schema: [product],
    }
});


// ----------------------
// Product Create
// ----------------------

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_RESPONSE = "ADD_PRODUCT_RESPONSE";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";

const add = (form) => (dispatch, getState) =>
  dispatch({
      [CALL_API]: {
          types: [
              ADD_PRODUCT_REQUEST,
              ADD_PRODUCT_RESPONSE,
              ADD_PRODUCT_ERROR,
          ],
          endpoint: `${ENDPOINTS.products[getCurrentProductName()]}`,
          schema: product,
          requestOptions: {
              headers: { "content-type": "application/json" },
              method: "POST",
              body: JSON.stringify(form),
          },
          success: (result) => {
              if (history.location.pathname.includes("products")) {
                  history.push(buildNextUrl(getState()));
              }
          },
      },
  });


// ----------------------
// Product Edit
// ----------------------

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_RESPONSE = "EDIT_PRODUCT_RESPONSE";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";

const edit = (form) => (dispatch, getState) =>
  dispatch({
      [CALL_API]: {
          types: [
              EDIT_PRODUCT_REQUEST,
              EDIT_PRODUCT_RESPONSE,
              EDIT_PRODUCT_ERROR,
          ],
          endpoint: `${ENDPOINTS.products[getCurrentProductName()]}/` + form.id,
          schema: product,
          requestOptions: {
              method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
              headers: { "content-type": "application/json" },
              body: JSON.stringify(form),
          },
          success: (result) => {
              if (history.location.pathname.includes("products")) {
                  history.push(buildNextUrl(getState()));
              }
          },
      },
  });

// ----------------------
// Accessory disable
// ----------------------

export const DISABLE_PRODUCT_REQUEST = "DISABLE_PRODUCT_REQUEST";
export const DISABLE_PRODUCT_RESPONSE = "DISABLE_PRODUCT_RESPONSE";
export const DISABLE_PRODUCT_ERROR = "DISABLE_PRODUCT_ERROR";

const disable = (productId) => (dispatch) =>
  dispatch({
      [CALL_API]: {
          types: [
              DISABLE_PRODUCT_REQUEST,
              DISABLE_PRODUCT_RESPONSE,
              DISABLE_PRODUCT_ERROR,
          ],
          endpoint: `${ENDPOINTS.products[getCurrentProductName()]}/${productId}`,
          schema: product,
          requestOptions: {
              method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
              headers: { "content-type": "application/json" },
              body: JSON.stringify({ enabled: false }),
          },
          success: (result) => {
              dispatch(
                enqueueSnackbarAction({
                    message: "Produit supprimé avec succès.",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        action: (key) => (
                          <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                              OK
                          </Button>
                        ),
                    },
                })
              );
          },
      },
  });

export const productsActions = {
    invalidateProducts,
    fetchProductsIfNeeded,
    add,
    edit,
    disable,
};
