import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import arrayMutators from "final-form-arrays";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { useParams } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import {
  EDIT_PRODUCT_ERROR,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_RESPONSE,
} from "../../../actions/product.actions";
import { productColorsActions } from "../../../actions/productColor.actions";
import { selectProduct, selectProductColors } from "../../../helpers/schema";
import { promiseListener } from "../../../helpers/store";
import ProductsForm from "./ProductsForm";

export default function ProductsEdit() {
  let { productId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setNextPage("/renov/products"));
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
  }, [dispatch]);

  let product = useSelector((state) => {
    let test = selectProduct(state, productId) || {};
    test.colors = state.products.all[productId]?.colors;
    return test;
  });

  const productColors = useSelector((state) => {
    return Object.values(selectProductColors(state)) || [];
  }, shallowEqual);

  if (product && product?.variants && product?.variants?.length > 0) {
    product.variants.map((elem) => delete elem.product);
  }

  return (
    <div>
      <MakeAsyncFunction
        listener={promiseListener}
        start={EDIT_PRODUCT_REQUEST}
        resolve={EDIT_PRODUCT_RESPONSE}
        reject={EDIT_PRODUCT_ERROR}
      >
        {(onSubmit) => (
          <Form
            onSubmit={(form) => {
              form.variants = form.variants.map((e) => {
                e.width = parseInt(e.width);
                e.visibleWidth = parseInt(e.visibleWidth);
                e.wave = parseInt(e.wave);
                delete e.product;

                return e;
              });

              form.maxLength = parseFloat(form.maxLength);

              return onSubmit(form);
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
              ...arrayMutators,
            }}
            initialValues={product}
            initialValuesEqual={(old, newOne) => old.id === newOne.id}
            render={({ handleSubmit, submitting, form }) => (
              <form onSubmit={handleSubmit}>
                <ProductsForm
                  image={product?.image?.contentUrl}
                  setValue={form.mutators.setValue}
                  productColors={productColors}
                />

                <Box mt={1} mb={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Editer
                  </Button>
                </Box>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    </div>
  );
}
