import React from "react";
import { Button, Container, Typography } from "@material-ui/core";
import { ROLE_PRODUCT_RENOV, ROLE_PRODUCT_THERMO } from "../../helpers/user";

export default function Hub({ setProductFn }) {
    return <Container >
        <Typography align="center" component="p" variant="h4">
            Choisissez le produit auquel vous voulez accéder.
        </Typography>

        <Container style={{textAlign: 'center', marginTop: '2em'}}>
            <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setProductFn(ROLE_PRODUCT_RENOV)}
                style={{marginRight: '2em'}}
            >
                RENOV
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setProductFn(ROLE_PRODUCT_THERMO)}
            >
                THERMO
            </Button>
        </Container>
    </Container>
}
