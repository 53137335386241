import { combineReducers } from "redux";
import { LOGOUT_USERS } from "../actions/users.actions";
import { accessories } from "./accessories.reducer";
import { accessoryCategories } from "./accessoryCategories.reducer";
import { app } from "./app.reducer";
import { authentication } from "./authentication.reducer";
import { clients } from "./clients.reducer";
import { insulations } from "./insulations.reducer";
import { medias } from "./medias.reducer";
import notifications from "./notifications.reducer";
import { prices } from "./prices.reducer";
import { pricesEstimated } from "./pricesEstimated.reducer";
import { productColors } from "./productColors.reducer";
import { productOptions } from "./productOptions.reducer";
import { panelManufacturers } from "./panelManufacturers.reducer";
import { panelProfils } from "./panelProfils.reducer";
import { products } from "./products.reducer";
import { productVariants } from "./productVariants.reducer";
import { projects } from "./projects.reducer";
import { quotations } from "./quotations.reducer";
import { settings } from "./settings.reducer";
import { shippings } from "./shippings.reducer";
import { slopes } from "./slopes.reducer";
import { users } from "./users.reducer";
import { RESET } from "../actions/actions";

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USERS || action.type === RESET) {
    state = undefined;
  }

  return appReducers(state, action);
};

const appReducers = combineReducers({
  authentication,
  accessories,
  accessoryCategories,
  projects,
  users,
  clients,
  prices,
  pricesEstimated,
  shippings,
  products,
  productColors,
  productOptions,
  productVariants,
  insulations,
  notifications,
  quotations,
  app,
  medias,
  slopes,
  settings,
  panelManufacturers,
  panelProfils
});

export default rootReducer;
