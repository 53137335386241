import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, Box, CircularProgress, Fab } from "@material-ui/core";
import { productColorsActions } from "../../../actions/productColor.actions";
import RenovColorsList from "../../../components/colors/RenovColorsList";
import ColorsEdit from "./ColorsEdit";
import ColorsNew from "./ColorsNew";
import HeaderTitle from "../../../components/HeaderTitle";
import SearchList from "../../../components/searchList";
import { filterElement } from "../../../helpers/schema";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

export default function Index(props) {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = React.useState("");

  const isFetching = useSelector((state) => {
    return state.productColors.isFetching;
  }, shallowEqual);

  const productColors = useSelector((state) => {
    return Object.values(state.productColors.all) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
  }, [dispatch, isFetching]);

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(productColorsActions.invalidateProductColors());
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
  };

  const onSearch = (search) => {
    setSearchFilter(search);
  };

  return (
    <Switch>
      <Route exact path="/renov/colors">
        <HeaderTitle
          mb={3}
          title="Couleurs"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
          bottom={<SearchList onSearch={onSearch} />}
        />
        {isFetching && Object.entries(productColors).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        <div className={"colors-container"} style={{ height: "100%" }}>
          {!isFetching && Object.entries(productColors).length === 0 && (
            <h2>Aucune donnée disponible.</h2>
          )}

          {Object.entries(productColors).length > 0 && (
            <RenovColorsList
              colors={Object.values(productColors).filter((item) =>
                filterElement(item, ["name"], searchFilter)
              )}
            />
          )}

          <Box position="fixed" bottom={30} right={30}>
            <Fab
              color="primary"
              aria-label="add"
              component={Link}
              to={"/renov/colors/new"}
            >
              <AddIcon />
            </Fab>
          </Box>
        </div>
      </Route>
      <Route
        path={"/renov/colors/:colorId/edit"}
        children={<ColorsEdit />}
      />
      <Route path={"/renov/colors/new"} children={<ColorsNew />} />
      <Route>
        <Redirect to={"/renov/colors"} />
      </Route>
    </Switch>
  );
}
