import { IconButton, InputAdornment } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import { Check } from "@material-ui/icons";
import { TextField } from "mui-rff";
import React from "react";
import { Form } from "react-final-form";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../../helpers/store";

export default function PriceEstimatedItem(props) {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={props.start}
      resolve={props.resolve}
      reject={props.reject}
      key={props.key}
    >
      {(onSubmit) => (
        <TableCell width="130em">
          <Form
            onSubmit={(form) => {
              form[props.priceProperty] = parseFloat(form[props.priceProperty]);
              return onSubmit(form);
            }}
            initialValues={{
              id: props.entity.id,
              [props.priceProperty]: props.entity[props.priceProperty],
            }}
            render={({ handleSubmit, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Tarif"
                  name={props.priceProperty || "priceEstimated"}
                  margin="none"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!pristine && (
                          <IconButton
                            edge="end"
                            type="submit"
                            disabled={submitting}
                          >
                            <Check color="primary" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            )}
          />
        </TableCell>
      )}
    </MakeAsyncFunction>
  );
}
