import { ADD_ENTITIES } from "../actions/actions";
import { insulation, mergeEntitiesWithState } from "../helpers/schema";
import {
  FETCH_INSULATIONS_REQUEST,
  FETCH_INSULATIONS_SUCCESS,
  FETCH_INSULATIONS_FAILURE,
  INVALIDATE_INSULATIONS,
} from "../actions/insulations.actions";

const schema = insulation;

export function insulations(
  state = {
    isFetching: false,
    didInvalidate: false,
    fetched: false,
    all: {},
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_INSULATIONS:
      return Object.assign({}, state, {
        ...state,
        didInvalidate: true,
      });
    case ADD_ENTITIES:
      let data = action.payload.insulation;
      if (!data) return state;

      return {
        ...state,
        all: mergeEntitiesWithState(data, state.all, schema),
      };
    case FETCH_INSULATIONS_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_INSULATIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
      });
    case FETCH_INSULATIONS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}
