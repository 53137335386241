import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CreateIcon from "@material-ui/icons/Create";
import Alert from "@material-ui/lab/Alert";
import { Radios, TextField } from "mui-rff";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import {
  ADD_CLIENT_ERROR,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_RESPONSE,
  clientsActions,
  EDIT_CLIENT_ERROR,
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_RESPONSE,
} from "../../actions/clients.actions";
import { AVAILABLES_TYPES } from "../../helpers/constante";
import { promiseListener } from "../../helpers/store";
import VirtualizedAutocomplete from "../VirtualizedAutocomplete";

const useStyles = makeStyles((theme) => ({
  selector: {
    flexGrow: 1,
  },
  editButton: {
    marginLeft: "10px",
  },
}));

export default function ClientSelect(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [newClient, setNewClient] = React.useState({
    name: "",
  });
  const [editedClient, setEditedClient] = React.useState(false);
  const [filteredClients, setFilteredClients] = React.useState({});
  const [previousClientValue, setPreviousClientValue] = React.useState({
    id: null,
    name: "",
  });
  const [clientValue, _setClientValue] = React.useState({
    id: null,
    name: "",
  });

  let clients = useSelector((state) => {
    return state.clients.all || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(clientsActions.fetchClientsIfNeeded());
  }, [dispatch]);

  const { defaultClient, form, name, placeHolder, filterFunction } = props;

  const setClientValue = (client) => {
    if (client.id !== clientValue.id) {
      setPreviousClientValue(clientValue);
      _setClientValue(client);
      form.mutators.setValue(name, client.id);
    }
  };

  const rollBackSelectedClient = () => {
    _setClientValue(previousClientValue);
    form.mutators.setValue(name, previousClientValue.id);
  };

  useEffect(() => {
    if (filterFunction) {
      setFilteredClients(Object.values(clients).filter(filterFunction));
    } else {
      setFilteredClients(Object.values(clients));
    }
  }, [dispatch, clients, filterFunction]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (checkvalue = true) => {
    setOpen(false);

    if (checkvalue) {
      rollbackClientIfNeeded(clientValue);
    }

    setNewClient({
      name: "",
    });
  };

  const isClientImportedAndInvalid = (client) => {
    return (
      client.imported && (!client.name || !client.city || !client.postCode)
    );
  };

  const setClientOrRollback = (client) => {
    if (!rollbackClientIfNeeded(client)) {
      setClientValue(client);
    }
  };

  const rollbackClientIfNeeded = (client) => {
    if (
      client.id &&
      (isClientImportedAndInvalid(client) ||
        (filterFunction !== undefined && !filterFunction(client)))
    ) {
      rollBackSelectedClient();
      return true;
    }

    return false;
  };

  if (!clientValue?.id && defaultClient && !editedClient) {
    setClientValue(clients[defaultClient]);
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Field name={name}>{() => null}</Field>{" "}
        {/* We put a RAW "client" field to force final form to manage the field, so it update form state when client value change */}
        <VirtualizedAutocomplete
          className={classes.selector}
          value={clientValue}
          label="Sélectionner un client"
          name={name}
          onChange={(event, newValue, reason) => {
            setEditedClient(true);
            if (!newValue) {
              return;
            }
            if (reason === "clear") {
              return setClientValue(null);
            }
            if (newValue.action === "new") {
              const name =
                typeof newValue === "string"
                  ? newValue
                  : newValue?.inputValue
                  ? newValue.inputValue
                  : "Nouveau Client";

              setTimeout(() => {
                setOpen(true);
                setNewClient({
                  name,
                });
              });
            }
            if (typeof newValue === "string") {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                setOpen(true);
                setNewClient({
                  name: newValue,
                });
              });
            } else {
              setClientValue(newValue);
              // if the value is imported from salesforce, we may need to ask some missing informations
              if (isClientImportedAndInvalid(newValue)) {
                setOpen(true);
              }
            }
          }}
          options={filteredClients}
          groupBy={(option) => (option.action ? "" : "Liste des clients")}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          filterOptions={(options, params) => {
            let filtered = options.filter(
              (option) =>
                option.name
                  .toLowerCase()
                  .includes(params.inputValue.toLowerCase()) ||
                option.company
                  ?.toLowerCase()
                  .includes(params.inputValue.toLowerCase()) ||
                option.city
                  ?.toLowerCase()
                  .includes(params.inputValue.toLowerCase()) ||
                option.postCode
                  ?.toLowerCase()
                  .includes(params.inputValue.toLowerCase())
            );

            // Suggest the creation of a new value
            filtered.unshift({
              inputValue: params.inputValue,
              name: `Créer un nouveau client`,
              action: "new",
            });

            return filtered;
          }}
          renderOption={(option) => (
            <>
              {!!option?.company && !!option?.name && (
                <>
                  <Typography noWrap>
                    {option.company}
                    {option?.company && option?.name && <>&nbsp;-&nbsp;</>}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={"textSecondary"}
                    style={{ fontStyle: "italic" }}
                    noWrap
                  >
                    {option.name}&nbsp;
                  </Typography>
                  {option?.city && option?.postCode && (
                    <Typography
                      variant="body2"
                      color={"textSecondary"}
                      style={{ fontStyle: "italic" }}
                      noWrap
                    >
                      &nbsp;-&nbsp; {option?.city.toUpperCase()}{" "}
                      {option?.postCode}
                    </Typography>
                  )}
                </>
              )}
              {!!option?.name && !option?.company && (
                <>
                  <Typography noWrap>{option.name}&nbsp;</Typography>
                  {option?.city && option?.postCode && (
                    <Typography
                      variant="body2"
                      color={"textSecondary"}
                      style={{ fontStyle: "italic" }}
                      noWrap
                    >
                      &nbsp;-&nbsp; {option?.city.toUpperCase()}{" "}
                      {option?.postCode}
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
          selectOnFocus
          clearOnBlur
          freesolo
          renderInput={(params) => (
            <MuiTextField {...params} label={placeHolder} variant="standard" />
          )}
        />
        {form.getState().values.client && (
          <Button
            className={classes.editButton}
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
          >
            <CreateIcon fontSize="small" />
          </Button>
        )}
      </Box>
      <Dialog
        open={open}
        disableBackdropClick
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <MakeAsyncFunction
          listener={promiseListener}
          start={newClient.name ? ADD_CLIENT_REQUEST : EDIT_CLIENT_REQUEST}
          resolve={newClient.name ? ADD_CLIENT_RESPONSE : EDIT_CLIENT_RESPONSE}
          reject={newClient.name ? ADD_CLIENT_ERROR : EDIT_CLIENT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={
                newClient.name
                  ? newClient
                  : clients[form.getState().values[name]]
              }
              onSubmit={(body) => {
                onSubmit(body).then((data) => {
                  if (data.id) {
                    handleClose(false);
                    setTimeout(() => {
                      setClientOrRollback(data);
                    });
                  }
                });
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">
                    {newClient.name ? "Ajouter" : "Modifier"}
                  </DialogTitle>
                  <DialogContent>
                    {!newClient.name &&
                      isClientImportedAndInvalid(clientValue) && (
                        <Alert severity="warning">
                          Ce client a été importé de Salesforce et certaines
                          informations sont manquantes. Vous devez les compléter
                          avant de pouvoir le sélectionner.
                        </Alert>
                      )}
                    <TextField
                      label="Nom"
                      name="name"
                      margin="dense"
                      required={true}
                      autoFocus
                    />
                    <TextField
                      label="Email"
                      name="email"
                      margin="dense"
                      required={false}
                      type="email"
                    />
                    <TextField
                      label="N° de téléphone"
                      name="phone"
                      margin="dense"
                      required={false}
                      type="tel"
                    />
                    <TextField
                      label="Société"
                      name="company"
                      margin="dense"
                      required={true}
                    />
                    <TextField
                      label="SIRET"
                      name="siret"
                      margin="dense"
                      required={false}
                    />
                    <TextField
                      label="Adresse"
                      name="address"
                      margin="dense"
                      required={false}
                    />
                    <TextField
                      label="Ville"
                      name="city"
                      margin="dense"
                      required={true}
                    />
                    <TextField
                      label="Code postal"
                      name="postCode"
                      margin="dense"
                      required={true}
                    />
                    <Radios
                      label="Type"
                      required={false}
                      name="type"
                      formControlProps={{
                        margin: "normal",
                      }}
                      radioGroupProps={{
                        row: true,
                      }}
                      data={AVAILABLES_TYPES}
                    />
                    {/*<Autocomplete*/}
                    {/*  label="Manager"*/}
                    {/*  name="manager"*/}
                    {/*  options={users}*/}
                    {/*  getOptionValue={(*/}
                    {/*    option*/}
                    {/*  ) => option.id}*/}
                    {/*  getOptionLabel={(*/}
                    {/*    option*/}
                    {/*  ) =>*/}
                    {/*    option.firstName*/}
                    {/*  }*/}
                    {/*/>*/}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Annuler
                    </Button>
                    <Button type={"submit"} color="primary">
                      {newClient.name ? "Ajouter" : "Modifier"}
                    </Button>
                  </DialogActions>
                </form>
              )}
            />
          )}
        </MakeAsyncFunction>
      </Dialog>
    </>
  );
}

ClientSelect.propTypes = {
  defaultClient: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  filterFunction: PropTypes.func,
};
