import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { useParams } from "react-router-dom";
import {
  EDIT_PRODUCT_COLOR_ERROR,
  EDIT_PRODUCT_COLOR_REQUEST,
  EDIT_PRODUCT_COLOR_RESPONSE,
} from "../../../../actions/productColor.actions.js";
import { appActions } from "../../../../actions/app.actions";
import { promiseListener } from "../../../../helpers/store";
import ColorsForm from "./ColorsForm";

export default function ColorsEdit() {
  let { colorId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setNextPage("/thermo/colors"));
  }, [dispatch]);

  let color = useSelector((state) => {
    return state.productColors.all[colorId] || {};
  });

  return (
    <div>
      <MakeAsyncFunction
        listener={promiseListener}
        start={EDIT_PRODUCT_COLOR_REQUEST}
        resolve={EDIT_PRODUCT_COLOR_RESPONSE}
        reject={EDIT_PRODUCT_COLOR_ERROR}
      >
        {(onSubmit) => (
          <Form
            onSubmit={(form) => {
              if (typeof form.extraPrice === 'undefined') {
                form.extraPrice = null;
              } else {
                form.extraPrice = parseFloat(form.extraPrice);
              }

              return onSubmit(form);
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={color}
            render={({ handleSubmit, submitting, form }) => (
              <form onSubmit={handleSubmit}>
                <ColorsForm
                  setValue={form.mutators.setValue}
                />

                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Editer
                  </Button>
                </Box>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    </div>
  );
}
