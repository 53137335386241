import {
  Box,
  CircularProgress,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Grid,
  ListSubheader,
  MenuItem,
  Radio as MuiRadio,
  Paper,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Select } from "mui-rff";
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../../actions/app.actions";
import { productsActions } from "../../../../actions/product.actions";
import { productColorsActions } from "../../../../actions/productColor.actions";
import {
  projectsActions,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  EDIT_PROJECT_ERROR,
} from "../../../../actions/projects.actions";
import StepperButtons from "../../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../../components/projects/StepperHeader";
import { getProjectStep } from "../../../../helpers/project";
import { promiseListener } from "../../../../helpers/store";
import { panelManufacturersActions } from "../../../../actions/panelManufacturers.actions";
import { panelProfilsActions } from "../../../../actions/panelProfils.actions";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { productOptionsActions } from "../../../../actions/productOption.actions";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  profil: {
    '&.invalid': {
      border: "4px solid lightcoral"
    },
    '&.valid': {
      border: "4px solid lightgreen"
    }
  },
  status: {
    '&.ok': {
      backgroundColor: "lightgreen"
    },
    '&.no-test': {
      backgroundColor: "sandybrown"
    },
    '&.specific': {
      backgroundColor: "lightyellow"
    },
    '&.ko': {
      backgroundColor: "lightcoral"
    },
    '&.current': {
      border: "2px solid"
    }
  },
  legend: {
    maxWidth: "250px",
    textAlign: "center",
    padding: "1em"
  },
  radioLabel: {
    margin: 0
  }
}));

export default function Step5() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(4);
  let submit;

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const project = useSelector((state) => {
    return state.projects.all[projectId] || {};
  }, shallowEqual);

  const panelManufacturers = useSelector((state) => {
    return Object.values(state.panelManufacturers.all) || [];
  }, shallowEqual);

  const panelProfils = useSelector((state) => {
    return Object.values(state.panelProfils.all) || [];
  }, shallowEqual);

  const productColors = useSelector((state) => {
    return Object.values(state.productColors.all) || [];
  }, shallowEqual);

  const productOptions = useSelector((state) => {
    return Object.values(state.productOptions.all) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(productsActions.fetchProductsIfNeeded());
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(panelManufacturersActions.fetchPanelManufacturersIfNeeded());
    dispatch(panelProfilsActions.fetchPanelProfilsIfNeeded(projectId));
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
    dispatch(productOptionsActions.fetchProductOptionsIfNeeded());
    dispatch(appActions.setNextPage("/thermo/projects/{currentProject}/step6"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject]);

  if (!isFetchingProject && project.name && getProjectStep(project) < 4) {
    return (
      <Redirect to={`/thermo/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  const currentOption = productOptions?.find(productOption => productOption.id === project.productVariant.product.options.id);
  const currentColor = productColors?.find(productColor => productColor.id === project.productColor);
  let currentSelectedProfil = {};
  let currentPanelThickness = {};

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={{
                manufacturer: project.profil?.range.manufacturer.id,
                rangeId: project.profil?.range.id,
                profil: project.profil?.id.toString()
              }}
              onSubmit={(form) => {
                form.panelThickness = currentPanelThickness?.id;

                return onSubmit(form);
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                form,
                handleSubmit,
                submitting,
                pristine,
                values,
              }) => {
                submit = handleSubmit;

                const currentProfils = typeof values.manufacturer === 'undefined' ? [] : panelProfils.filter(profil => {
                  return profil.range.manufacturer.id == values.manufacturer;
                });

                const categories = {};
                const rangesIds = [];
                if (currentProfils.length > 0) {
                  currentProfils.forEach(profil => {
                    const category = profil.range.category;

                    if (typeof categories[category.id] === 'undefined') {
                      categories[category.id] = {
                        ranges: [],
                        ...category
                      };
                    }

                    if (!rangesIds.includes(profil.range.id)) {
                      categories[category.id].ranges.push(profil.range);
                      rangesIds.push(profil.range.id);
                    }
                  });
                }

                const currentCategoryProfils = [];
                const invalidPanelsIds = [];

                if (typeof values.rangeId !== 'undefined') {
                  currentProfils.forEach(profil => {
                    if (profil.range.id === values.rangeId) {
                      let valid = true;

                      const color = profil.colors.find(panelColor => panelColor.color.id === project.productColor);
                      if (typeof color === 'undefined') {
                        valid = false;
                        invalidPanelsIds.push(profil.id);
                      }

                      if (valid) {
                        const option = profil.thickness.find(panelThickness => panelThickness.productOption.id === project.productVariant.product.options.id);

                        if (typeof option === 'undefined') {
                          valid = false;
                          invalidPanelsIds.push(profil.id);
                        }
                      }

                      profil.valid = valid;
                      currentCategoryProfils.push(profil);
                    }
                  });
                }

                currentCategoryProfils.sort((a, b) => {
                  return (a.valid === b.valid) ? 0 : a.valid ? -1 : 1;
                })

                if (typeof values.profil !== 'undefined') {
                  currentSelectedProfil = currentCategoryProfils.find(profil => profil.id == values.profil);
                  currentPanelThickness = currentSelectedProfil?.thickness.find(thickness => thickness.productOption?.id == currentOption.id);
                }

                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={projectId}
                      submitting={submitting}
                      shouldLink={pristine}
                      disabled={typeof values.profil === 'undefined'}
                    />
                    <Container maxWidth="lg">
                      <form onSubmit={handleSubmit}>
                        <Box
                          mt={2}
                          mb={3}
                          display="flex"
                          flexDirection="column"
                          flexGrow="1"
                        >
                          <Grid container justify="center" spacing={2}>

                            <Grid item xs={12}>
                              <Box>
                                <Typography>Finition choisie : {currentColor?.name}</Typography>
                              </Box>
                              <Box>
                                <Typography>Option choisie : {currentOption?.name}</Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12}>
                              <Select
                                label="Fabricant"
                                name="manufacturer"
                                data={panelManufacturers.map(manufacturer => {
                                  return {
                                    label: manufacturer.name,
                                    value: manufacturer.id
                                  };
                                })}
                              />
                            </Grid>

                            {Object.keys(categories).length > 0 &&
                              <Grid item xs={12}>
                                <Select
                                  disabled={Object.keys(categories).length === 0}
                                  label="Catégorie"
                                  name="rangeId"
                                  children={getSelectComponent(categories)}
                                />
                              </Grid>
                            }

                            {currentCategoryProfils.length > 0 &&
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  justifyContent="space-around"
                                  marginBottom="1em"
                                >
                                  <Paper className={`${classes.legend} ${classes.status} ok`}>
                                    <Typography>Une solution ONDUCLAIR THERMO disponible</Typography>
                                  </Paper>
                                  <Paper className={`${classes.legend} ${classes.status} specific`}>
                                    <Typography>Une pose spécifique à adopter (voir le commentaire)</Typography>
                                  </Paper>
                                  <Paper className={`${classes.legend} ${classes.status} no-test`}>
                                    <Typography>Les tests officiels n'ont pas été réalisés (étude à réaliser)</Typography>
                                  </Paper>
                                  <Paper className={`${classes.legend} ${classes.status} ko`}>
                                    <Typography>Pas de solution ONDUCLAIR THERMO</Typography>
                                  </Paper>
                                </Box>
                              </Grid>
                            }

                            {currentCategoryProfils.map(panel => {
                              return (
                                <>
                                  <Grid item xs={12} key={panel.id}>

                                    <Box flexGrow={1}>
                                      <Paper className={`${classes.profil} ${panel.valid ? 'valid' : 'invalid'}`}>
                                        <RadioGroup>
                                          <FormControlLabel
                                            name="profil"
                                            key={panel.id}
                                            label=""
                                            value={panel.id.toString()}
                                            disabled={false}
                                            className={classes.radioLabel}
                                            control={
                                              <Field
                                                name="profil"
                                                type="radio"
                                                render={({
                                                  input: {
                                                    name,
                                                    value,
                                                    onChange,
                                                    checked,
                                                    disabled,
                                                    ...restInput
                                                  },
                                                }) => (
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    marginLeft="1em"
                                                    width="100%"
                                                  >
                                                    <Typography variant="h6" component="p">{panel.name} | <span style={{color: '#dc2c32'}}>{panel.libelleOnduclair}</span></Typography>
                                                    <Box
                                                      display="flex"
                                                      justifyContent="flex-end"
                                                      alignItems="center"
                                                      m={2}
                                                    >
                                                      {panel.valid ? <MuiRadio
                                                        name={name}
                                                        value={value}
                                                        onChange={(event) => {
                                                          form.mutators.setValue(
                                                            "profil",
                                                            event.target.value
                                                          );

                                                          onChange(event);
                                                        }}
                                                        checked={checked}
                                                        disabled={false}
                                                        required
                                                        inputProps={{
                                                          required: true,
                                                          ...restInput,
                                                        }}
                                                      />
                                                        :
                                                        <NotInterestedIcon />
                                                      }
                                                    </Box>
                                                  </Box>
                                                )}
                                              />
                                            }
                                          />
                                        </RadioGroup>
                                        <ExpansionPanel key={panel.id}>

                                          <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                          >
                                            <Typography>Voir le détail</Typography>
                                          </ExpansionPanelSummary>

                                          <Box
                                            textAlign={'center'}
                                            m={2}
                                          >
                                            <img style={{maxWidth: '100%', maxHeight: '200px'}} src={panel.urlImage ?? require(`../../../../assets/images/profil-placeholder.png`)} />
                                          </Box>

                                          <Box
                                            textAlign={'center'}
                                            m={2}
                                          >
                                            <p>Commentaires : {project.profil?.idcommentOnduline ?? "Aucun commentaire renseigné"}</p>
                                          </Box>

                                          <Box
                                            display="flex"
                                            justifyContent="space-between"
                                          >
                                            <Grid item xs={6}>
                                              <Box
                                                display="flex"
                                                justifyContent="center"
                                                flexDirection="column"
                                                m={2}
                                                textAlign="center"
                                              >
                                                <Typography variant="h6" component="p">Finitions</Typography>
                                                <Box
                                                  display="flex"
                                                  justifyContent="center"
                                                  m={2}
                                                >
                                                  <Grid container justify="center" spacing={2}>
                                                    {productColors.map(color => {
                                                      const panelColor = panel.colors.find(panelColor => panelColor.color.id === color.id);
                                                      const status = typeof panelColor === 'undefined' ? 'ko' : panelColor.status.code;
                                                      const currentClass = color.id === currentColor?.id ? 'current' : '';

                                                      return (
                                                        <Grid item xs={6}>
                                                          <Paper className={`${classes.status} ${status} ${currentClass}`}>
                                                            <Typography>{color.name}</Typography>
                                                            <Typography>{typeof panelColor?.comment === 'undefined' || panelColor?.comment === '' ? '-' : panelColor?.comment}</Typography>
                                                          </Paper>
                                                        </Grid>
                                                      )
                                                    })}
                                                  </Grid>
                                                </Box>
                                              </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Box
                                                display="flex"
                                                justifyContent="center"
                                                flexDirection="column"
                                                m={2}
                                                textAlign="center"
                                              >
                                                <Typography variant="h6" component="p">Options</Typography>
                                                <Box
                                                  display="flex"
                                                  justifyContent="center"
                                                  m={2}
                                                >
                                                  <Grid container justify="center" spacing={2}>
                                                    {productOptions.map(option => {
                                                      const panelOption = panel.thickness.find(panelThickness => panelThickness.productOption.id == option.id);
                                                      const status = typeof panelOption === 'undefined' ? 'ko' : panelOption.status.code;
                                                      const currentClass = option.id === currentOption.id ? 'current' : '';

                                                      return (
                                                        <Grid item xs={6}>
                                                          <Paper className={`${classes.status} ${status} ${currentClass}`}>
                                                            <Typography>{option.name}</Typography>
                                                            <Typography>{typeof panelOption === 'undefined' ? '-' : `de ${panelOption.thicknessMin} à ${panelOption.thicknessMax}`}</Typography>
                                                          </Paper>
                                                        </Grid>
                                                      )
                                                    })}
                                                  </Grid>
                                                </Box>
                                              </Box>
                                            </Grid>
                                          </Box>
                                          <Box
                                            textAlign="center"
                                            padding="1em"
                                          >
                                            {panel.comment !== '' &&
                                              <Typography variant="h6" component="p">{panel.comment}</Typography>
                                            }
                                          </Box>
                                        </ExpansionPanel>
                                      </Paper>
                                    </Box>
                                  </Grid>

                                </>
                              )
                            })}
                          </Grid>
                        </Box>
                        <Container maxWidth="lg">
                          <StepperButtons
                            submit={submit}
                            activeStep={activeStep}
                            projectId={projectId}
                            submitting={submitting}
                            shouldLink={pristine}
                            disabled={typeof values.profil === 'undefined'}
                          />
                        </Container>
                      </form>
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}

function getSelectComponent(categories) {
  const components = [];


  Object.values(categories).forEach(category => {
    components.push(<ListSubheader>{category.name}</ListSubheader>);

    category.ranges.forEach(range => {
      components.push(<MenuItem value={range.id}>{range.name}</MenuItem>);
    })
  });

  return components;
}