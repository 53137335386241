import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { usersActions } from "../actions/users.actions";
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  Grid,
} from "@material-ui/core";
import { VpnKey } from "@material-ui/icons";
import { Link, useParams } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingButton from "../components/LoadingButton";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function PasswordReset(props) {
  let { token } = useParams();
  const classes = useStyles();
  const [form, setform] = React.useState({ email: "" });
  const [submitted, setSubmitted] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.logout());
  }, [dispatch]);

  const sendingPasswordRequest = useSelector((state) => {
    return state.users.sendingPasswordRequest;
  }, shallowEqual);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setform({ ...form, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitted(true);

    if (form.password1 && form.password2) {
      dispatch(usersActions.setPasswordToken(token, form.password1));
    }
  };

  return (
    <div className={"login"}>
      <div className={"left-container"}></div>
      <div className={"right-container"}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justify="center"
          id={"loginFormContainer"}
        >
          <Grid item>
            <img
              src={require("../assets/images/login/onduline-logo.svg")}
              alt={"Logo Onduline"}
              className={"mb-4"}
            />

            <Box mb={4}>
              <p className={"text-center"}>
                Choisissez votre nouveau mot de passe
                <br />
                Vous pourrez ensuite l'utiliser pour vous connecter.
              </p>
            </Box>

            <form name="form" onSubmit={handleSubmit}>
              <Box mb={2} width="100%">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password1">
                    Mot de passe
                  </InputLabel>
                  <OutlinedInput
                    type="password"
                    value={form.password1}
                    name="password1"
                    required
                    onChange={handleChange}
                    aria-describedby="component-error-password1"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <VpnKey />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={100}
                  />
                </FormControl>
              </Box>
              <Box mb={2} width="100%">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password2">
                    Confirmez le mot de passe
                  </InputLabel>
                  <OutlinedInput
                    type="password"
                    value={form.password2}
                    name="password2"
                    required
                    onChange={handleChange}
                    aria-describedby="component-error-password2"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <VpnKey />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={200}
                  />
                </FormControl>
              </Box>

              <Box mt={4} className={"login-footer"}>
                <div className={classes.wrapper}>
                  <LoadingButton
                    loading={sendingPasswordRequest}
                    disabled={submitted}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Valider
                  </LoadingButton>
                </div>
              </Box>
            </form>
          </Grid>

          <p id={"forgottenPassword"}>
            <Link to={"/login"} title={"Cliquez ici"}>
              Revenir à la page de connexion
            </Link>
          </p>
        </Grid>
      </div>
    </div>
  );
}
