import { Box } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import {
  arraysEquals,
  buildConstraintWithStyleForColumn,
  isSelected,
} from "../../../helpers/grid";
import { Cell } from "./Cell";
var deepEqual = require('deep-equal')

export const GridColumn = React.memo(
  (props) => {
    const {
      linesSize,
      column,
      columnValues,
      selected,
      onMouseEnter,
      onMouseDown,
      constraintsDef,
      queue
    } = props;
    let nextRealIndex = 0;

    let constraints = [...columnValues];
    if (constraints.filter((item) => item !== false).length > 0 ||
        queue?.length > 0) {
      constraints = buildConstraintWithStyleForColumn(
        constraints,
        constraintsDef,
        linesSize,
        queue
      );
    }

    return (
      <Box display="flex" flexDirection="column-reverse">
        {constraints.map((cell, key) => {
          let index = nextRealIndex;
          nextRealIndex = nextRealIndex + 1 * (cell.length || 1);
          return (
            <Cell
              key={key}
              cell={cell || {}}
              constraint
              isSelected={isSelected(index, column, selected)}
              x={column}
              y={index}
              onMouseEnter={onMouseEnter}
              onMouseDown={onMouseDown}
            />
          );
        })}
      </Box>
    );
  },
  (oldProps, newProps) => {
    if (!deepEqual(newProps.columnValues, oldProps.columnValues))
      return false;

    let constraints = newProps.columnValues.filter((item) => item !== false);
    if (constraints.length > 0) {
      if (
        !arraysEquals(
          Object.keys(oldProps.constraintsDef),
          Object.keys(newProps.constraintsDef)
        )
      )
        return false;
    }

    if (newProps.selected.filter((item) => item.x === newProps.column).length)
      return false;

    if (oldProps.selected.filter((item) => item.x === oldProps.column).length)
      return false;

    if (oldProps.queue?.length !== newProps.queue?.length)
      return false

    return true;
  }
);

GridColumn.propTypes = {
  linesSize: PropTypes.array.isRequired,
  column: PropTypes.number.isRequired,
  columnValues: PropTypes.array.isRequired,
};
