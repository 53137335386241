import {
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
  headerTitle: {
    backgroundColor: theme.palette.background.primary,
  },
  title: {
    color: theme.palette.stepperHeader.primary,
  },
}));

export default function HeaderTitle(props) {
  const classes = useStyles();
  const { title, children, right, left, bottom, ...boxProps } = props;

  return (
    <Box
      {...boxProps}
      className={classes.headerTitle}
      display="flex"
      flexDirection="column"
    >
      <Box p={2} display="flex">
        <Box
          display="flex"
          alignItems="center"
          flexGrow={1}
          flexBasis={0}
          flexShrink={1}
        >
          {left}
        </Box>
        <Box
          mt={1}
          mb={1}
          display="flex"
          flexGrow={1}
          flexBasis={0}
          flexShrink={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexGrow={1}
          flexBasis={0}
          flexShrink={1}
        >
          {right}
        </Box>
      </Box>
      {bottom && <Box>{bottom}</Box>}
    </Box>
  );
}

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.element,
  left: PropTypes.element,
  bottom: PropTypes.element,
};
