import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { Fragment } from "react";
import {
  EDIT_PRICE_ERROR,
  EDIT_PRICE_REQUEST,
  EDIT_PRICE_RESPONSE,
} from "../../actions/price.actions";
import PriceItem from "./PriceItem";

export default function PricesRow(props) {
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {props.name}
        </TableCell>

        <PriceItem
          start={props.start}
          resolve={props.resolve}
          reject={props.reject}
          entity={props.product ? props.product : props.accessory}
          priceProperty={"transferPrice"}
        />

        {props.prices.map((price, i) => (
          <Fragment key={i}>
            <PriceItem
              start={EDIT_PRICE_REQUEST}
              resolve={EDIT_PRICE_RESPONSE}
              reject={EDIT_PRICE_ERROR}
              entity={price}
              priceProperty={"price"}
            />
          </Fragment>
        ))}
      </TableRow>
    </>
  );
}
