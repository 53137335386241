import React, { useEffect } from "react";
import { Form } from "react-final-form";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../../../helpers/store";
import {
  ADD_INSULATION_ERROR,
  ADD_INSULATION_REQUEST,
  ADD_INSULATION_RESPONSE,
} from "../../../actions/insulations.actions";
import { Button } from "@material-ui/core";
import InsulationsForm from "./InsulationsForm";
import Box from "@material-ui/core/Box";
import { useDispatch } from "react-redux";
import { appActions } from "../../../actions/app.actions";

export default function ClientsNew() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setNextPage("/renov/insulations"));
  }, [dispatch]);
  return (
    <div>
      <MakeAsyncFunction
        listener={promiseListener}
        start={ADD_INSULATION_REQUEST}
        resolve={ADD_INSULATION_RESPONSE}
        reject={ADD_INSULATION_ERROR}
      >
        {(onSubmit) => (
          <Form
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            onSubmit={(form) => {
              form.thickness = parseFloat(form.thickness);
              form.wave = form.wave ? parseFloat(form.wave) : 0;
              return onSubmit(form);
            }}
            render={({ handleSubmit, submitting, values, form, valid }) => (
              <form onSubmit={handleSubmit}>
                <InsulationsForm setValue={form.mutators.setValue} />

                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Ajouter
                  </Button>
                </Box>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    </div>
  );
}
