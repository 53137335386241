import { ADD_ENTITIES } from "../actions/actions";
import { mergeEntitiesWithState, shipping } from "../helpers/schema";

const schema = shipping;

export function shippings(
  state = {
    all: {},
  },
  action
) {
  switch (action.type) {
    case ADD_ENTITIES:
      let data = action.payload.shipping;
      if (!data) return state;

      return {
        ...state,
        all: mergeEntitiesWithState(data, state.all, schema),
      };
    default:
      return state;
  }
}
