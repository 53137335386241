import { ADD_ENTITIES } from "../actions/actions";
import { mergeEntitiesWithState, setting } from "../helpers/schema";
import {
    FETCH_SETTINGS_REQUEST,
    FETCH_SETTINGS_SUCCESS,
    INVALIDATE_SETTINGS
} from "../actions/setting.actions";

const schema = setting;

export function settings(
  state = {
      isFetching: false,
      didInvalidate: false,
      fetched: false,
      all: {}
  },
  action
) {
    switch (action.type) {
        case INVALIDATE_SETTINGS:
            return Object.assign({}, state, {
                ...state,
                didInvalidate: true
            })
        case ADD_ENTITIES:
            let data = action.payload.setting;
            if (!data) return state

            return {
                ...state,
                all: mergeEntitiesWithState(data, state.all, schema)
            };
        case FETCH_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                ...state,
                isFetching: true,
                didInvalidate: false
            })
        case FETCH_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                fetched: true,
                isFetching: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
}
