import { quotation } from "../helpers/schema";
import { CALL_API } from "../middleware/api";
import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction,
} from "./notifications.actions";
import Button from "@material-ui/core/Button";
import React from "react";
// ----------------------
// Project Edit Quotation
// ----------------------
export const EDIT_QUOTATION_REQUEST = "EDIT_QUOTATION_REQUEST";
export const EDIT_QUOTATION_RESPONSE = "EDIT_QUOTATION_RESPONSE";
export const EDIT_QUOTATION_ERROR = "EDIT_QUOTATION_ERROR";

const edit = (form) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        EDIT_QUOTATION_REQUEST,
        EDIT_QUOTATION_RESPONSE,
        EDIT_QUOTATION_ERROR,
      ],
      endpoint: `quotations/` + form.id,
      schema: quotation,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify(form),
      },
      success: (result) => {
        dispatch(
          enqueueSnackbarAction({
            message: "Devis modifié avec succès.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              action: (key) => (
                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                  OK
                </Button>
              ),
            },
          })
        );
      },
      failure: (error) => {
        dispatch(
          enqueueSnackbarAction({
            message:
              "Une erreur s'est produite lors de la modification du devis.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              action: (key) => (
                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                  OK
                </Button>
              ),
            },
          })
        );
      },
    },
  });
};

export const quotationsActions = {
  edit,
};
