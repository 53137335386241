import { CALL_API } from "../middleware/api";
import {productVariant} from "../helpers/schema";
import { getCurrentProductName, shouldFetch } from "../helpers/api";
import {buildNextUrl, history} from "../helpers/history";
import {closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction} from "./notifications.actions";
import Button from "@material-ui/core/Button";
import React from "react";
import { ENDPOINTS } from "../helpers/constante";

// ----------------------
// Product Fetching
// ----------------------
export const FETCH_PRODUCTS_VARIANTS_REQUEST = "FETCH_PRODUCTS_VARIANTS_REQUEST";
export const FETCH_PRODUCTS_VARIANTS_SUCCESS = "FETCH_PRODUCTS_VARIANTS_SUCCESS";
export const FETCH_PRODUCTS_VARIANTS_FAILURE = "FETCH_PRODUCTS_VARIANTS_FAILURE";
export const INVALIDATE_PRODUCTS_VARIANTS    = "INVALIDATE_PRODUCTS_VARIANTS";

function invalidateProductsVariants () {
    return {type: INVALIDATE_PRODUCTS_VARIANTS};
}

function fetchProductsVariantsIfNeeded () {
    return (dispatch, getState) => {
        if (shouldFetch(getState(), "productsVariants")) {
            return dispatch(fetchProductsVariants());
        }
    };
}

const fetchProductsVariants = () => ({
    [CALL_API]: {
        types: [
            FETCH_PRODUCTS_VARIANTS_REQUEST,
            FETCH_PRODUCTS_VARIANTS_SUCCESS,
            FETCH_PRODUCTS_VARIANTS_FAILURE
        ],
        endpoint: `${ENDPOINTS.productsVariants[getCurrentProductName()]}?pagination=false`,
        schema: [productVariant],
    }
});


// ----------------------
// Product Create
// ----------------------

export const ADD_PRODUCT_VARIANT_REQUEST = "ADD_PRODUCT_VARIANT_REQUEST";
export const ADD_PRODUCT_VARIANT_RESPONSE = "ADD_PRODUCT_VARIANT_RESPONSE";
export const ADD_PRODUCT_VARIANT_ERROR = "ADD_PRODUCT_VARIANT_ERROR";

const add = (form) => (dispatch, getState) =>
  dispatch({
      [CALL_API]: {
          types: [
              ADD_PRODUCT_VARIANT_REQUEST,
              ADD_PRODUCT_VARIANT_RESPONSE,
              ADD_PRODUCT_VARIANT_ERROR,
          ],
          endpoint: `${ENDPOINTS.productsVariants[getCurrentProductName()]}`,
          schema: productVariant,
          requestOptions: {
              headers: { "content-type": "application/json" },
              method: "POST",
              body: JSON.stringify(form),
          },
          success: (result) => {
              if (history.location.pathname.includes("productsVariants")) {
                  history.push(buildNextUrl(getState()));
              }
          },
      },
  });


// ----------------------
// Product Edit
// ----------------------

export const EDIT_PRODUCT_VARIANT_REQUEST = "EDIT_PRODUCT_VARIANT_REQUEST";
export const EDIT_PRODUCT_VARIANT_RESPONSE = "EDIT_PRODUCT_VARIANT_RESPONSE";
export const EDIT_PRODUCT_VARIANT_ERROR = "EDIT_PRODUCT_VARIANT_ERROR";

const edit = (form) => (dispatch, getState) => {
    console.log('form ACTION', form);
    return dispatch({
        [CALL_API]: {
            types: [
                EDIT_PRODUCT_VARIANT_REQUEST,
                EDIT_PRODUCT_VARIANT_RESPONSE,
                EDIT_PRODUCT_VARIANT_ERROR,
            ],
            endpoint: `${ENDPOINTS.productsVariants[getCurrentProductName()]}/` + form.id,
            schema: productVariant,
            requestOptions: {
                method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
                headers: { "content-type": "application/json" },
                body: JSON.stringify(form),
            },
            success: (result) => {
                if (history.location.pathname.includes("productsVariants")) {
                    history.push(buildNextUrl(getState()));
                }
            },
        },
    });
}

// ----------------------
// Accessory disable
// ----------------------

export const DISABLE_PRODUCT_VARIANT_REQUEST = "DISABLE_PRODUCT_VARIANT_REQUEST";
export const DISABLE_PRODUCT_VARIANT_RESPONSE = "DISABLE_PRODUCT_VARIANT_RESPONSE";
export const DISABLE_PRODUCT_VARIANT_ERROR = "DISABLE_PRODUCT_VARIANT_ERROR";

const disable = (productId) => (dispatch) =>
  dispatch({
      [CALL_API]: {
          types: [
              DISABLE_PRODUCT_VARIANT_REQUEST,
              DISABLE_PRODUCT_VARIANT_RESPONSE,
              DISABLE_PRODUCT_VARIANT_ERROR,
          ],
          endpoint: `${ENDPOINTS.productsVariants[getCurrentProductName()]}/${productId}`,
          schema: productVariant,
          requestOptions: {
              method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
              headers: { "content-type": "application/json" },
              body: JSON.stringify({ enabled: false }),
          },
          success: (result) => {
              dispatch(
                enqueueSnackbarAction({
                    message: "Produit supprimé avec succès.",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        action: (key) => (
                          <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                              OK
                          </Button>
                        ),
                    },
                })
              );
          },
      },
  });

export const productsVariantsActions = {
    invalidateProductsVariants,
    fetchProductsVariantsIfNeeded,
    add,
    edit,
    disable,
};
