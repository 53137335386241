import { buildNextUrl, history } from "../helpers/history";
import { CALL_API } from "../middleware/api";
import { managedUser, user } from "../helpers/schema";
import { shouldFetch } from "../helpers/api";

import {
    enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction,
} from "./notifications.actions";
import React from "react";
import Button from "@material-ui/core/Button";
import { getUser } from "../helpers/user";

const jwtDecode = require("jwt-decode");

// ----------------------
// Users Login
// ----------------------
export const LOGIN_USERS_REQUEST = "LOGIN_USERS_REQUEST";
export const LOGIN_USERS_SUCCESS = "LOGIN_USERS_SUCCESS";
export const LOGIN_USERS_FAILURE = "LOGIN_USERS_FAILURE";
export const LOGOUT_USERS        = "LOGOUT_USERS";

const login = (email, password) => dispatch => dispatch(({
    [CALL_API]: {
        types: [
            LOGIN_USERS_REQUEST,
            LOGIN_USERS_SUCCESS,
            LOGIN_USERS_FAILURE
        ],
        endpoint: `authentication_token`,
        schema: user,
        requestOptions: {
            method: "POST",
            body: JSON.stringify({
                email,
                password
            }),
            headers: {
                "Content-Type": "application/json"
            }
        },
        success: user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            user.decoded = jwtDecode(user.token);
            localStorage.setItem("user", JSON.stringify(user));

            history.push("/");
        },
        failure: error => {
            if (error.code === 401) {

                dispatch(enqueueSnackbarAction({
                    message: "Email ou mot de passe incorrect.",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                        action: key => (<Button onClick={() => dispatch(closeSnackbarAction(key))}>OK</Button>),
                    },
                }));
            }
        }
    }
}));

function logout () {
    localStorage.removeItem("user");
    sessionStorage.removeItem("currentProduct");
    return {type: LOGOUT_USERS};
}

// ----------------------
// Users Fetching
// ----------------------

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const INVALIDATE_USERS    = "INVALIDATE_USERS";

function invalidateUsers () {
    return {type: INVALIDATE_USERS};
}

function fetchUsersIfNeeded () {
    return (dispatch, getState) => {
        if (shouldFetch(getState(), "users")) {
            return dispatch(fetchUsers());
        }
    };
}

const fetchUsers = () => ({
    [CALL_API]: {
        types: [
            FETCH_USERS_REQUEST,
            FETCH_USERS_SUCCESS,
            FETCH_USERS_FAILURE
        ],
        endpoint: `users?pagination=false`,
        schema: [managedUser],
    }
});

// ----------------------
// user Fetching
// ----------------------

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

const fetchUser = (id) => ({
    [CALL_API]: {
        types: [
            FETCH_USER_REQUEST,
            FETCH_USER_SUCCESS,
            FETCH_USER_FAILURE
        ],
        endpoint: `users/${id}`,
        schema: user,
    }
});

// ----------------------
// logged user Fetching
// ----------------------

function fetchLoggedUserIfNeeded () {
    return (dispatch, getState) => {
        const userId = getUser().decoded.userId;

        if (!getState().users.all[userId]) {
            return dispatch(fetchUser(userId));
        }
    };
}

// ----------------------
// User Edit
// ----------------------

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_RESPONSE = "EDIT_USER_RESPONSE";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

const edit = (form) => (dispatch, getState) => dispatch({
    [CALL_API]: {
        types: [
            EDIT_USER_REQUEST,
            EDIT_USER_RESPONSE,
            EDIT_USER_ERROR
        ],
        endpoint: `users/` + form.id,
        schema: user,
        requestOptions: {
            method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
            headers: { "content-type": "application/json" },
            body: JSON.stringify(form)
        },
        success: result => {
            history.push(buildNextUrl(getState()));
        },
        failure: error => {
            console.log(error);
        }
    }
});

// ----------------------
// User Add
// ----------------------

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_RESPONSE = "ADD_USER_RESPONSE";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

const add = (form) => (dispatch, getState) => dispatch({
    [CALL_API]: {
        types: [
            ADD_USER_REQUEST,
            ADD_USER_RESPONSE,
            ADD_USER_ERROR
        ],
        endpoint: `users`,
        schema: managedUser,
        requestOptions: {
            headers: {"content-type": "application/json"},
            method: "POST",
            body: JSON.stringify(form)
        },
        success: result => {
            history.push(buildNextUrl(getState()));
        }
    }
});

// ----------------------
// User request password reset token
// ----------------------

export const PASSWORD_TOKEN_USER_REQUEST = "PASSWORD_TOKEN_USER_REQUEST";
export const PASSWORD_TOKEN_USER_RESPONSE = "PASSWORD_TOKEN_USER_RESPONSE";
export const PASSWORD_TOKEN_USER_ERROR = "PASSWORD_TOKEN_USER_ERROR";

const requestPasswordToken = (email) => (dispatch, getState) => dispatch({
    [CALL_API]: {
        types: [
            PASSWORD_TOKEN_USER_REQUEST,
            PASSWORD_TOKEN_USER_RESPONSE,
            PASSWORD_TOKEN_USER_ERROR
        ],
        endpoint: `reset_password`,
        requestOptions: {
            headers: {"content-type": "application/json"},
            method: "POST",
            body: JSON.stringify({
                email,
                resetUrl: process.env.REACT_APP_URL + "/reset_password/{token}"
            })
        },
        success: result => {
            dispatch(enqueueSnackbarAction({
                message: "Si cette adresse email est enregistrée, un email vient d'y être envoyé.",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "info",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    action: key => (<Button onClick={() => dispatch(closeSnackbarAction(key))}>OK</Button>),
                },
            }));

            setTimeout(() => history.push("/login"), 200)
        }
    }
});

// ----------------------
// User set password from token
// ----------------------

export const SET_PASSWORD_TOKEN_USER_REQUEST = "SET_PASSWORD_TOKEN_USER_REQUEST";
export const SET_PASSWORD_TOKEN_USER_RESPONSE = "SET_PASSWORD_TOKEN_USER_RESPONSE";
export const SET_PASSWORD_TOKEN_USER_ERROR = "SET_PASSWORD_TOKEN_USER_ERROR";

const setPasswordToken = (token, plainPassword) => (dispatch, getState) => dispatch({
    [CALL_API]: {
        types: [
            SET_PASSWORD_TOKEN_USER_REQUEST,
            SET_PASSWORD_TOKEN_USER_RESPONSE,
            SET_PASSWORD_TOKEN_USER_ERROR
        ],
        endpoint: `reset_password/${token}`,
        requestOptions: {
            headers: {"content-type": "application/json"},
            method: "POST",
            body: JSON.stringify({
                plainPassword
            })
        },
        success: result => {
            dispatch(enqueueSnackbarAction({
                message: "Mot de passe modifié. Vous pouvez vous connecter.",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    action: key => (<Button onClick={() => dispatch(closeSnackbarAction(key))}>OK</Button>),
                },
            }));

            setTimeout(() => history.push("/login"), 200)
        },
        failure: error => {
            dispatch(enqueueSnackbarAction({
                message: "Jeton invalide ou expiré",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    action: key => (<Button onClick={() => dispatch(closeSnackbarAction(key))}>OK</Button>),
                },
            }));

            setTimeout(() => history.push("/login"), 200)
        }
    }
});

// ----------------------
// User disable
// ----------------------

export const DISABLE_USER_REQUEST = "DISABLE_USER_REQUEST";
export const DISABLE_USER_RESPONSE = "DISABLE_USER_RESPONSE";
export const DISABLE_USER_ERROR = "DISABLE_USER_ERROR";

const disable = (userId) => (dispatch) => dispatch({
    [CALL_API]: {
        types: [
            DISABLE_USER_REQUEST,
            DISABLE_USER_RESPONSE,
            DISABLE_USER_ERROR
        ],
        endpoint: `users/${userId}`,
        schema: user,
        requestOptions: {
            method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
            headers: { "content-type": "application/json" },
            body: JSON.stringify({enabled: false})
        },
        success: result => {
            dispatch(enqueueSnackbarAction({
                message: "Utilisateur supprimé avec succès.",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    action: key => (<Button onClick={() => dispatch(closeSnackbarAction(key))}>OK</Button>),
                },
            }));
        }
    }
});

export const usersActions = {
    login,
    logout,
    invalidateUsers,
    fetchUsersIfNeeded,
    fetchLoggedUserIfNeeded,
    edit,
    add,
    disable,
    requestPasswordToken,
    setPasswordToken
};
