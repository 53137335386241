import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import PriceItem from "./PriceItem";
import { AREAS } from "../../../helpers/constante";
import withStyles from "@material-ui/core/styles/withStyles";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height:  "81px"
  },
}))(TableRow);

export default function PricesRow(props) {
  const thicknesses = {};

  for (let i = 0, l = props.product.variants.length; i < l; i++) {
    const variant = props.product.variants[i];

    if (typeof thicknesses[`${variant.pitch}`] === 'undefined') {
      thicknesses[`${variant.pitch}`] = {};
    }

    if (typeof thicknesses[`${variant.pitch}`][`${variant.thickness}`] !== 'undefined') {
      thicknesses[`${variant.pitch}`][`${variant.thickness}`].push(variant);
    } else {
      thicknesses[`${variant.pitch}`][`${variant.thickness}`] = [variant];
    }
  }

  return (
    <>
      {Object.keys(thicknesses).map((pitch, index) => (
        <TableRow>
          <TableCell component="th" scope="row">
            {pitch === '0' ? '250 / 333' : pitch}mm
          </TableCell>

          <TableCell component="th" scope="row">
            {Object.keys(thicknesses[pitch]).map(thickness => (
              <StyledTableRow>
              <TableRow>
                <TableCell component="th" scope="row" height="3em">
                  {thickness}mm
                </TableCell>
              </TableRow>
              </StyledTableRow>
            ))}
          </TableCell>

          {AREAS.map((area, i) => (
            <TableCell component="th" scope="row">
              {Object.keys(thicknesses[pitch]).map((thickness) => 
                <TableRow>
                    <TableCell component="th" scope="row">
                      <PriceItem
                          start={props.start}
                          resolve={props.resolve}
                          reject={props.reject}
                          entity={thicknesses[pitch][thickness].find(variant => area.area === `${variant.areaMin}_${variant.areaMax}`)}
                          priceProperty={"price"}
                        />
                    </TableCell> 
                </TableRow>
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
