import { denormalize, schema } from "normalizr";

export const quotation = new schema.Entity("quotation");
export const user = new schema.Entity("user");
export const media = new schema.Entity("media");
export const price = new schema.Entity("price");
export const priceEstimated = new schema.Entity("priceEstimated");
export const shipping = new schema.Entity("shipping");
export const productColor = new schema.Entity("productColor");
export const productOption = new schema.Entity("productOption");
export const accessoryCategory = new schema.Entity("accessoryCategory");
export const slope = new schema.Entity("slope");
export const setting = new schema.Entity("setting");
export const panelManufacturer = new schema.Entity("panelManufacturer");
export const panelProfil = new schema.Entity("panelProfil");

export const client = new schema.Entity("client", {
  manager: user,
  image: media,
});

export const product = new schema.Entity("product", {
  prices: [price],
  pricesEstimated: [priceEstimated],
  colors: [productColor],
  options: [productOption]
});

export const accessory = new schema.Entity("accessory", {
  image: media,
  pdf: media,
  prices: [price],
  pricesEstimated: [priceEstimated],
  category: accessoryCategory,
});

export const productVariant = new schema.Entity("productVariant", {
  product: product,
});

export const insulation = new schema.Entity("insulation", {
  prices: [price],
  pricesEstimated: [priceEstimated],
});

product.define({
  variants: [productVariant],
  colors: [productColor],
  options: [productOption]
});

user.define({
  manager: user,
});

export const managedUser = new schema.Entity("managedUser", {
  manager: user,
});

export const project = new schema.Entity("project", {
  user: user,
  client: client,
  finalClient: client,
  image: media,
  productColor: productColor,
  productOption: productOption,
  // productVariant: productVariant,
  insulation: insulation,
  quotations: [quotation],
  lastQuotation: quotation,
  attachedFiles: [media],
  slopes: [slope],
});

// selectors
export const selectUser = (state, id) => selectEntity(state, user, id);
export const selectProject = (state, id) => selectEntity(state, project, id);
export const selectProduct = (state, id) => selectEntity(state, product, id);
export const selectProductColor = (state, id) =>
  selectEntity(state, productColor, id);
export const selectProductOption = (state, id) =>
  selectEntity(state, productOption, id);
export const selectClient = (state, id) => selectEntity(state, client, id);
export const selectAccessory = (state, id) =>
  selectEntity(state, accessory, id);
export const selectAccessoryCategory = (state, id) =>
  selectEntity(state, accessoryCategory, id);
export const selectInsulation = (state, id) =>
  selectEntity(state, insulation, id);
export const selectPrice = (state, id) => selectEntity(state, price, id);
export const selectPriceEstimated = (state, id) =>
  selectEntity(state, priceEstimated, id);
export const selectShipping = (state, id) => selectEntity(state, shipping, id);
export const selectPanelManufacturer = (state, id) => selectEntity(state, panelManufacturer, id);
export const selectPanelProfil = (state, id) => selectEntity(state, panelProfil, id);

export const selectProjects = (state) =>
  selectEntity(
    state,
    { project: [project] },
    { project: Object.keys(state.projects.all) }
  ).project;

export const selectInsulations = (state) =>
  selectEntity(
    state,
    { insulation: [insulation] },
    { insulation: Object.keys(state.insulations.all) }
  ).insulation;

export const selectClients = (state) =>
  selectEntity(
    state,
    { client: [client] },
    { client: Object.keys(state.clients.all) }
  ).client;

export const selectUsers = (state) =>
  selectEntity(state, { user: [user] }, { user: Object.keys(state.users.all) })
    .user;

export const selectAccessories = (state) =>
  selectEntity(
    state,
    { accessory: [accessory] },
    {
      accessory: Object.keys(state.accessories.all),
    }
  ).accessory;

export const selectAccessoryCategories = (state) =>
  selectEntity(
    state,
    { accessoryCategory: [accessoryCategory] },
    {
      accessoryCategory: Object.keys(state.accessoryCategories.all),
    }
  ).accessoryCategory;

export const selectProducts = (state) =>
  selectEntity(
    state,
    { product: [product] },
    {
      product: Object.keys(state.products.all),
    }
  ).product;

export const selectSettings = (state) =>
  selectEntity(
    state,
    { setting: [setting] },
    {
      setting: Object.keys(state.settings.all),
    }
  ).setting;

export const selectProductColors = (state) =>
  selectEntity(
    state,
    { productColor: [productColor] },
    {
      productColor: Object.keys(state.productColors.all),
    }
  ).productColor;

export const selectProductOptions = (state) =>
  selectEntity(
    state,
    { productOption: [productOption] },
    {
      productOption: Object.keys(state.productOptions.all),
    }
  ).productOption;

export const selectProductVariants = (state) =>
  selectEntity(
    state,
    { productVariant: [productVariant] },
    {
      productVariant: Object.keys(state.productVariants.all),
    }
  ).productVariant;

export const selectPrices = (state) =>
  selectEntity(
    state,
    { price: [price] },
    {
      price: Object.keys(state.prices.all),
    }
  ).price;

export const selectPricesEstimated = (state) =>
  selectEntity(
    state,
    { priceEstimated: [priceEstimated] },
    {
      priceEstimated: Object.keys(state.pricesEstimated.all),
    }
  ).priceEstimated;

export const selectShippings = (state) =>
  selectEntity(
    state,
    { shipping: [shipping] },
    {
      shipping: Object.keys(state.shippings.all),
    }
  ).shipping;

export const selectPanelManufacturers = (state) =>
  selectEntity(
    state,
    { panelManufacturer: [panelManufacturer] },
    {
      panelManufacturer: Object.keys(state.panelManufacturers.all),
    }
  ).panelManufacturer;

export const selectPanelProfils = (state) =>
  selectEntity(
    state,
    { panelProfil: [panelProfil] },
    {
      panelProfil: Object.keys(state.panelProfils.all),
    }
  ).panelProfil;

export const selectEntity = (state, entity, id) =>
  denormalize(id, entity, {
    accessory: state.accessories.all,
    accessoryCategory: state.accessoryCategories.all,
    client: state.clients.all,
    finalClient: state.clients.all,
    productColor: state.productColors.all,
    productOption: state.productOptions.all,
    productVariant: state.productVariants.all,
    product: state.products.all,
    insulation: state.insulations.all,
    price: state.prices.all,
    priceEstimated: state.pricesEstimated.all,
    shipping: state.shippings.all,
    user: state.users.all,
    project: state.projects.all,
    media: state.medias.all,
    quotation: state.quotations.all,
    slope: state.slopes.all,
    setting: state.settings.all,
    panelManufacturer: state.panelManufacturers.all,
    panelProfil: state.panelProfils.all,
  });

export function mergeEntitiesWithState(toMerge, Previous, schema) {
  Object.keys(toMerge || {}).forEach((index) => {
    toMerge[index] = normalizeId(toMerge[index], schema);
  });

  return Object.entries(toMerge).reduce((merged, [id, item]) => {
    if (Object.keys(item).length === 1) return merged;
    return {
      ...merged,
      [id]: {
        ...(merged[id] || {}),
        ...item,
      },
    };
  }, Previous);
}

export function normalizeId(item, schema) {
  Object.keys(schema.schema).forEach((index) => {
    if (typeof item[index] === "string" && item[index].includes("/")) {
      let split = item[index].split("/");
      item[index] = parseInt(split[split.length - 1]);
    }
  });

  return item;
}

export const filterElement = (item, keys, search) => {
  let result = false;

  keys.forEach((key) => {
    if(key.includes(".")) {
      const before = key.substring(0, key.indexOf('.'));
      const after = key.substring(key.indexOf('.') + 1);

      if (typeof item?.[before]?.[after] === "string") {
        if (item?.[before]?.[after].toUpperCase().includes(search.toUpperCase()))
        result = true;
      }
       if (item?.[before]?.[after].includes(search)) result = true;
    }
    if (!!item[key]) {
      if (typeof item?.[key] === "string") {
        if (item[key].toUpperCase().includes(search.toUpperCase()))
        result = true;
      }
      if (item?.[key].includes(search)) result = true;
      
    }
  });

  return result;
};
