import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import FaceIcon from "@material-ui/icons/Face";
import React from "react";
import { useDispatch } from "react-redux";
import { clientsActions } from "../../actions/clients.actions";
import { humanReadableTypes } from "../../helpers/constante";
import MenuActions from "../MenuActions";
import ValidationModal from "../ValidationModal";
import { getCurrentProductName } from "../../helpers/api";

const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default function ClientsList(props) {
  const classes = useStyles();
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseMenuList = (option, client) => {
    if (option === "Supprimer") {
      setSelectedClient(client);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClientDelete = () => {
    setDeleting(true);

    dispatch(clientsActions.disable(selectedClient.id)).then(() => {
      setDeleting(false);
      setOpenModal(false);
    });
  };

  return (
    <>
      <ValidationModal
        loading={deleting}
        onClose={handleCloseModal}
        onClick={handleClientDelete}
        open={openModal}
        title={"Êtes-vous sûr de vouloir supprimer ce client ?"}
        paragraph={"Sa suppression est définitive."}
      />

      <List>
        {props.clients.map((client, i) => (
          <div key={i}>
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  {client.image ? (
                    <img
                      height="100%"
                      alt={`${client.name} logo`}
                      src={
                        process.env.REACT_APP_API_URL + client.image.contentUrl
                      }
                    />
                  ) : (
                    <FaceIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {!!client?.company && !!client?.name && (
                      <>
                        <Typography noWrap>
                          {client.company}
                          {client?.company && client?.name && (
                            <>&nbsp;-&nbsp;</>
                          )}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={"textSecondary"}
                          style={{ fontStyle: "italic" }}
                          noWrap
                        >
                          {client.name}&nbsp;
                        </Typography>
                      </>
                    )}
                    {!!client?.name && !client?.company && (
                      <Typography noWrap>{client.name}&nbsp;</Typography>
                    )}
                  </div>
                }
                secondary={
                  <Box display="flex">
                    <Grid className={classes.textSecondary} container>
                      <Grid item xs={3}>
                        <Typography variant={"body2"}>
                          {client.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        {client.phone}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant={"body2"}>
                          {client?.city.toUpperCase()}{" "}
                          {client?.postCode && `- ${client.postCode}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        {humanReadableTypes[client.type] && (
                          <Chip
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={humanReadableTypes[client.type]}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                <MenuActions
                  onClose={(option) => handleCloseMenuList(option, client)}
                  options={{
                    show: {
                      name: "Voir",
                      link: `/${getCurrentProductName()}/clients/${client.id}/show`,
                    },
                    edit: {
                      name: "Editer",
                      link: `/${getCurrentProductName()}/clients/${client.id}/edit`,
                    },
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        ))}
      </List>
    </>
  );
}
