import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function LoadingButton(props) {
    const {loading, children, ...buttonProps} = props;
    const classes = useStyles();

    return (<div className={classes.wrapper}>
        <Button
            variant="contained"
            color="primary"
            disabled={loading}
            type="submit"
            {...buttonProps}
        >
            {children}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>)
}

LoadingButton.propTypes = {
    loading: PropTypes.bool.isRequired
}