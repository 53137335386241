import { Box, Button, CircularProgress } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { productsActions } from "../../../../actions/product.actions";
import HeaderTitle from "../../../../components/HeaderTitle";
import ProductsList from "../../../../components/products/ProductsList";
import SearchList from "../../../../components/searchList";
import ProductsEdit from "./ProductsEdit";
import { filterElement, selectProducts } from "../../../../helpers/schema";

export default function Index(props) {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = React.useState("");
  const [loadItems, _setLoadItems] = React.useState(50);

  const loadItemsRef = React.useRef(loadItems);
  const setLoadItems = (data) => {
    loadItemsRef.current = data;
    _setLoadItems(data);
  };

  const isFetching = useSelector((state) => {
    return state.products.isFetching;
  }, shallowEqual);

  const products = useSelector((state) => {
    return Object.values(selectProducts(state)) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(productsActions.fetchProductsIfNeeded());
  });

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(productsActions.invalidateProducts());
    dispatch(productsActions.fetchProductsIfNeeded());
  };

  const onSearch = (search) => {
    setSearchFilter(search);
    setLoadItems(50);
  };

  const onScrollEnd = () => {
    setLoadItems(loadItemsRef.current + 50);
  };

  return (
    <Switch>
      <Route exact path="/thermo/products">
        <HeaderTitle
          mb={3}
          title="Produits"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
          bottom={<SearchList onScrollEnd={onScrollEnd} onSearch={onSearch} />}
        />
        {isFetching && Object.entries(products).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        <div className={"products-container"}>
          {!isFetching && Object.entries(products).length === 0 && (
            <h2>Aucune donnée disponible.</h2>
          )}

          {Object.entries(products).length > 0 && (
            <ProductsList
              products={Object.values(products)
                .filter((item) => filterElement(item, ["name"], searchFilter))
                .slice(0, loadItems)}
            />
          )}
        </div>
      </Route>
      <Route path={"/thermo/products/:productId/edit"} children={<ProductsEdit />} />
      <Route>
        <Redirect to={"/thermo/products"} />
      </Route>
    </Switch>
  );
}
