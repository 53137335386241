import { shouldFetch } from "../helpers/api";
import { buildNextUrl, history } from "../helpers/history";
import { price } from "../helpers/schema";
import { CALL_API } from "../middleware/api";

// ----------------------
// Price Fetching
// ----------------------
export const FETCH_PRICES_REQUEST = "FETCH_PRICES_REQUEST";
export const FETCH_PRICES_SUCCESS = "FETCH_PRICES_SUCCESS";
export const FETCH_PRICES_FAILURE = "FETCH_PRICES_FAILURE";
export const INVALIDATE_PRICES = "INVALIDATE_PRICES";

function invalidatePrices() {
  return { type: INVALIDATE_PRICES };
}

function fetchPricesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "prices")) {
      return dispatch(fetchPrices());
    }
  };
}

const fetchPrices = () => ({
  [CALL_API]: {
    types: [FETCH_PRICES_REQUEST, FETCH_PRICES_SUCCESS, FETCH_PRICES_FAILURE],
    endpoint: `prices?pagination=false`,
    schema: [price],
  },
});

// ----------------------
// Price Edit
// ----------------------

export const EDIT_PRICE_REQUEST = "EDIT_PRICE_REQUEST";
export const EDIT_PRICE_RESPONSE = "EDIT_PRICE_RESPONSE";
export const EDIT_PRICE_ERROR = "EDIT_PRICE_ERROR";

const edit = (form) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      types: [EDIT_PRICE_REQUEST, EDIT_PRICE_RESPONSE, EDIT_PRICE_ERROR],
      endpoint: `prices/` + form.id,
      schema: price,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify(form),
      },
      success: (result) => {
        history.push(buildNextUrl(getState()));
      },
    },
  });
};

export const pricesActions = {
  invalidatePrices,
  fetchPricesIfNeeded,
  edit,
};
