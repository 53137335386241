import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Radio as MuiRadio,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Radios, Select } from "mui-rff";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../../actions/app.actions";
import { productsActions } from "../../../../actions/product.actions";
import { productColorsActions } from "../../../../actions/productColor.actions";
import {
  PROJECT_THERMO_CALCULATE_ESTIMATE_REQUEST,
  PROJECT_THERMO_CALCULATE_ESTIMATE_SUCCESS,
  PROJECT_THERMO_CALCULATE_ESTIMATE_FAILURE,
  projectsActions,
} from "../../../../actions/projects.actions";
import StepperButtons from "../../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../../components/projects/StepperHeader";
import { getProjectStep } from "../../../../helpers/project";
import { promiseListener } from "../../../../helpers/store";
import { AREAS, PITCHES, THICKNESSES } from "../../../../helpers/constante";
import { productOptionsActions } from "../../../../actions/productOption.actions";
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  thicknessSelect: {
    width: "200px"
  }
}));

export default function Step3() {
  let { projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(2);
  let submit;

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const productColors = useSelector((state) => {
    return Object.values(state.productColors.all) || [];
  }, shallowEqual);

  const project = useSelector((state) => {
    return state.projects.all[projectId] || {};
  }, shallowEqual);

  const productOptions = useSelector((state) => {
    return Object.values(state.productOptions.all) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(productsActions.fetchProductsIfNeeded());
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
    dispatch(productOptionsActions.fetchProductOptionsIfNeeded());
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(appActions.setNextPage("/thermo/projects/{currentProject}/step4"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject]);

  if (!isFetchingProject && project.name && getProjectStep(project) < 3) {
    return (
      <Redirect to={`/thermo/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  const missingValues = values => {
    return typeof values.pitch === 'undefined' ||
      typeof values.thickness === 'undefined' ||
      typeof values.area === 'undefined_undefined' ||
      typeof values.productColor === 'undefined' ||
      typeof values.productOptionId === 'undefined';
  }

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && productOptions.length > 0 && productColors.length > 0 && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={PROJECT_THERMO_CALCULATE_ESTIMATE_REQUEST}
          resolve={PROJECT_THERMO_CALCULATE_ESTIMATE_SUCCESS}
          reject={PROJECT_THERMO_CALCULATE_ESTIMATE_FAILURE}
        >
          {(onSubmit) => (
            <Form
              initialValues={{
                projectId: parseInt(projectId, 10),
                pitch: project.pitch?.toString(),
                thickness: project.productVariant?.thickness.toString(),
                area: `${project.productVariant?.areaMin}_${project.productVariant?.areaMax}`,
                productColor: project.productColor?.toString(),
                productOptionId: project.productVariant?.product?.options?.id.toString()
              }}
              onSubmit={(form) => {
                return onSubmit(form);
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                form,
                handleSubmit,
                submitting,
                pristine,
                values,
              }) => {
                submit = handleSubmit;

                let currentProductOption = {};
                if (typeof values.productOptionId !== 'undefined') {
                  currentProductOption = productOptions.find(option => option.id == values.productOptionId);
                }

                return (
                  <div className={classes.root}>
                    <StepperHeader
                      submit={submit}
                      activeStep={activeStep}
                      projectId={parseInt(projectId, 10)}
                      submitting={submitting}
                      shouldLink={pristine}
                      disabled={missingValues(values)}
                    />
                    <Container maxWidth="lg">
                      <form onSubmit={handleSubmit}>
                        <Box
                          mt={2}
                          mb={3}
                          display="flex"
                          flexDirection="column"
                          flexGrow="1"
                        >
                          <Grid container justify="center" spacing={2}>

                            <Grid item xs={12}>
                              <Box p={2}
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                              >
                                <Radios
                                  label="Pas du produit"
                                  name="pitch"
                                  data={PITCHES.map(pitch => {
                                    return {
                                      label: `${pitch}mm`,
                                      value: pitch
                                    };
                                  })}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12}>
                              <Box p={2}
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                              >
                                <Radios
                                  label="Finition du produit"
                                  name="productColor"
                                  data={productColors.map(color => {
                                    return {
                                      label: color.name,
                                      value: color.id.toString()
                                    };
                                  })}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12}>
                              <Box p={2}
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                              >
                                <Radios
                                  label="Option du produit"
                                  name="productOptionId"
                                  data={productOptions.map(option => {
                                    return {
                                      label: option.name,
                                      value: option.id.toString()
                                    };
                                  })}
                                />
                              </Box>
                            </Grid>

                            {Object.keys(currentProductOption).length > 0 && ['C', 'T'].includes(currentProductOption.reference) &&
                              <Grid item xs={12}>
                                <Box
                                  p={2}
                                  display="flex"
                                  textAlign="center"
                                  justifyContent="center"
                                >
                                  <WarningIcon />
                                  <Typography>Si la portée maximale est supérieure à 1m50, alors vous devrez opter pour la solution renforcée</Typography>
                                </Box>
                              </Grid>
                            }

                            <Grid item xs={12}>
                              <Box p={2}
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                              >

                                <Select
                                  label="Epaisseur choisie"
                                  name="thickness"
                                  formControlProps={{fullWidth: false}}
                                  className={classes.thicknessSelect}
                                  data={THICKNESSES.map(thickness => {
                                    return {
                                      label: `${thickness}mm`,
                                      value: thickness
                                    };
                                  })}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12}>
                              <Box p={2}
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                              >
                                <Radios
                                  label="Quantité en m²"
                                  name="area"
                                  data={AREAS.map(area => {
                                    return {
                                      label: area.label,
                                      value: area.area
                                    };
                                  })}
                                />
                              </Box>
                            </Grid>

                          </Grid>
                        </Box>
                        <Container maxWidth="lg">
                          <StepperButtons
                            submit={submit}
                            activeStep={activeStep}
                            projectId={parseInt(projectId, 10)}
                            submitting={submitting}
                            shouldLink={pristine}
                            disabled={missingValues(values)}
                          />
                        </Container>
                      </form>
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
