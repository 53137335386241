import { Box, Button, CircularProgress, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { insulationsActions } from "../../../actions/insulations.actions";
import HeaderTitle from "../../../components/HeaderTitle";
import InsulationsList from "../../../components/insulations/InsulationsList";
import SearchList from "../../../components/searchList";
import InsulationsEdit from "../../../containers/admin/insulations/InsulationsEdit";
import InsulationsNew from "../../../containers/admin/insulations/InsulationsNew";
import InsulationsShow from "../../../containers/admin/insulations/InsulationsShow";
import { filterElement, selectInsulations } from "../../../helpers/schema";

export default function Index(props) {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = React.useState("");
  const [loadItems, _setLoadItems] = React.useState(50);

  const loadItemsRef = React.useRef(loadItems);
  const setLoadItems = (data) => {
    loadItemsRef.current = data;
    _setLoadItems(data);
  };

  const isFetching = useSelector((state) => {
    return state.insulations.isFetching;
  }, shallowEqual);

  const insulations = useSelector((state) => {
    return selectInsulations(state) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(insulationsActions.fetchInsulationsIfNeeded());
  });

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(insulationsActions.invalidateInsulations());
    dispatch(insulationsActions.fetchInsulationsIfNeeded());
  };

  const onSearch = (search) => {
    setSearchFilter(search);
    setLoadItems(50);
  };

  const onScrollEnd = () => {
    setLoadItems(loadItemsRef.current + 50);
  };

  return (
    <Switch>
      <Route exact path="/renov/insulations">
        <HeaderTitle
          mb={3}
          title="Isolations"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
          bottom={<SearchList onScrollEnd={onScrollEnd} onSearch={onSearch} />}
        />
        {isFetching && Object.entries(insulations).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        <div className={"insulations-container"}>
          {!isFetching && Object.entries(insulations).length === 0 && (
            <h2>Aucune donnée disponible.</h2>
          )}

          {Object.entries(insulations).length > 0 && (
            <InsulationsList
              insulations={Object.values(insulations)
                .filter((item) => filterElement(item, ["name"], searchFilter))
                .slice(0, loadItems)}
            />
          )}

          <Box position="fixed" bottom={30} right={30}>
            <Fab
              color="primary"
              aria-label="add"
              component={Link}
              to={"/renov/insulations/new"}
            >
              <AddIcon />
            </Fab>
          </Box>
        </div>
      </Route>
      <Route
        path={"/renov/insulations/:insulationId/show"}
        children={<InsulationsShow />}
      />
      <Route path={"/renov/insulations/new"} children={<InsulationsNew />} />
      <Route
        path={"/renov/insulations/:insulationId/edit"}
        children={<InsulationsEdit />}
      />
      <Route>
        <Redirect to={"/renov/insulations"} />
      </Route>
    </Switch>
  );
}
