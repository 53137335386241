import { makeStyles } from "@material-ui/core";
import { getCurrentProductName } from "./api";

export function getProjectStep(project) {
  const product = getCurrentProductName();

  if (!project.name)
    return 1;

  if (product === 'thermo') {
    if (typeof project.productVariant === 'undefined')
      return 3;

    if (typeof project.profil === 'undefined')
      return 4;

    if (typeof project.dimensions === 'undefined' || project.dimensions.length === 0)
      return 6;

    return 7;
  } else {
    if (
      project?.lastQuotation &&
      !project.calepinageImagesOutdated &&
      !project.calepinageOutdated
    )
      return 9;
    if (
      project?.accessories?.length > 0 &&
      !project.calepinageImagesOutdated &&
      !project.calepinageOutdated
    )
      return 8;
    if (project?.slopes?.[0] && project.slopes?.[0]?.plates?.length > 0) 
      return 7;
    if (project?.slopes?.[0] && project.slopes?.[0]?.linesSize?.length > 0)
      return 6;
    if (project?.slopes?.length > 0)
      return 5;
    if (project?.productColor)
      return 3;
    if (project.name)
      return 2;
    return 1;
  }
}

export function getSteps(project = null) {
  const product = getCurrentProductName();

  if (product === 'thermo') {
    return ["", "", "", "", "", "", "", ""];
  }

  return ["", "", "", "", "", "", "", "", ""];
}

export function getStepContent(stepIndex) {
  const product = getCurrentProductName();

  if (product === 'thermo') {
    switch (stepIndex) {
      case 0:
        return "Nouveau projet";
      case 1:
        return "Informations projet";
      case 2:
        return "Produit";
      case 3:
        return "Devis estimatif";
      case 4:
        return "Panneau métallique";
      case 5:
        return "Dimensionnement";
      case 6:
        return "Accessoires";
      case 7:
        return "Récapitulatif";
      default:
        return "Étape inconnue";
    }
  } else {
    switch (stepIndex) {
      case 0:
        return "Nouveau projet";
      case 1:
        return "Informations projet";
      case 2:
        return "Produit et isolant";
      case 3:
        return "Dimensions des versants";
      case 4:
        return "Devis estimatif";
      case 5:
        return "Dimensions des rangées et points singuliers";
      case 6:
        return "Calepinage";
      case 7:
        return "Accessoires";
      case 8:
        return "Récapitulatif";
      default:
        return "Étape inconnue";
    }
  }
}

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 250,
  },
  tableHead: {
    backgroundColor: theme.palette.background.primary,
  },
  tableHeadLabel: {
    color: theme.palette.common.white,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formStep3: {
    marginBottom: 40,
    display: "flex",
    flexDirection: "row",
  },
  p0: {
    padding: 0,
  },
  imgResponsive: {
    maxWidth: "100%",
    height: "auto",
  },
  vCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mb4: {
    marginBottom: 40,
  },
  relative: {
    position: "relative",
  },
  btnUpload: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  image: {
    maxWidth: "100%",
    height: "100px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginBottom: 15,
    marginTop: 15,
  },
  bgLightGrey: {
    maxWidth: "100%",
    height: "100px",
    marginBottom: 15,
    marginTop: 15,
    backgroundColor: "lightgray",
  },
  boxSize: {
    width: "33.33%",
  },
  mt1: {
    marginTop: 10,
  },
}));
