import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export const useStyles = makeStyles((theme) => ({
  p0: {
    padding: 0,
  },
  stepperContainer: {
    backgroundColor: theme.palette.background.primary,
  },
  stepperNav: {
    color: theme.palette.stepperHeader.primary,
  },
  buttonIcon: {
    fontSize: "2rem",
  },
  cell: {
    height: 40,
    width: 30,
    border: "1px solid",
  },
  activeCell: {
    backgroundColor: "green",
  },
  selected: {
    backgroundColor: "blue",
  },
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const Cell = React.memo(
  (props) => {
    const classes = useStyles();
    const { cell, x, y, isSelected, onMouseEnter, onMouseDown } = props;
    let style = {};
    let styleNametag = {
      width: "80%",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      color: "white",
      borderRadius: "4px",
    };

    if (cell.length) {
      style = {
        height: 40 * cell.length,
      };
    }
    if (!cell.new && cell.length) {
      style.backgroundColor = cell.color

      style =
        cell.type === "constraint"
          ? {
              ...style,
              backgroundSize: `30px ${40 * cell.length}`,
              background: `repeating-linear-gradient(45deg, ${cell.color}, ${cell.color} 10px, ${cell.color}52 10px, ${cell.color}52 20px)`,
            }
          : style;

      style =
        isSelected && cell.type === "constraint"
          ? {
              ...style,
              background: `repeating-linear-gradient(45deg, ${cell.color}, ${cell.color} 10px, ${cell.color}dd 10px, ${cell.color}dd 20px)`,
            }
          : style;

      style =
        cell.type === "enlightment"
          ? {
              ...style,
              backgroundSize: `30px ${40 * cell.length}`,
              background: `repeating-radial-gradient(circle, ${cell.color}, ${cell.color} 5px, ${cell.color}52 0, ${cell.color}52 12px)`,
            }
          : style;

      style =
        isSelected && cell.type === "enlightment"
          ? {
              ...style,
              background: `repeating-radial-gradient(circle, ${cell.color}, ${cell.color} 5px, ${cell.color}dd 0, ${cell.color}dd 12px)`,
            }
          : style;

      styleNametag = isSelected
        ? {
            ...styleNametag,
            border: "#dc2c32 3px solid",
          }
        : styleNametag;
    }

    return (
      <Box
        style={style}
        className={`${classes.cell} ${isSelected ? classes.selected : ""}`}
        key={y}
        onMouseEnter={() => onMouseEnter(y, x)}
        onMouseDown={() => onMouseDown(y, x)}
        display="flex"
        justifyContent="center"
      >
        <>
          {cell.new && <Box className={classes.root} display="flex" alignSelf="center">
            <CircularProgress size={20}/>
          </Box>}
          {!cell.new && cell.name && <Box style={styleNametag} display="flex" alignSelf="center">
            {cell.name}
          </Box>}
        </>
      </Box>
    );
  },
  (oldProps, newProps) => {
    if (oldProps.isSelected !== newProps.isSelected) return false;
    if (oldProps.cell.length !== newProps.cell.length) return false;
    if (oldProps.cell.color !== newProps.cell.color) return false;
    if (oldProps.onMouseEnter !== newProps.onMouseEnter) return false;

    return true;
  }
);
