import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import { InsertDriveFile } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAccessory } from "../../../helpers/schema";

function createData(label, value) {
  return {
    label,
    value,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default function AccessoriesShow() {
  let { accessoryId } = useParams(),
    rows = [];

  const classes = useStyles();
  const accessoryByIdFromState = useSelector((state) => {
    return selectAccessory(state, accessoryId);
  });

  // TODO: persister l'arborescence d'état redux lors de l'actualisation

  if (typeof accessoryByIdFromState !== "undefined") {
    if (typeof accessoryByIdFromState.reference !== "undefined") {
      rows.push(createData("Référence", accessoryByIdFromState.reference));
    }

    if (typeof accessoryByIdFromState.name !== "undefined") {
      rows.push(createData("Nom", accessoryByIdFromState.name));
    }

    if (typeof accessoryByIdFromState.pdf !== "undefined") {
      rows.push(
        createData(
          "Pdf",
          <a
            href={
              accessoryByIdFromState?.pdf?.contentUrl &&
              process.env.REACT_APP_API_URL +
                accessoryByIdFromState.pdf.contentUrl
            }
            target="_blank"
            rel="noopener"
            download
          >
            <InsertDriveFile color="primary" fontSize="medium" />{" "}
          </a>
        )
      );
    }

    if (typeof accessoryByIdFromState.autoCalc !== "undefined") {
      rows.push(
        createData(
          "Méthode",
          accessoryByIdFromState.autoCalc ? "Auto" : "Manuel"
        )
      );
    }
  }

  return accessoryId ? (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              Accessoire
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.label}>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              {accessoryByIdFromState?.image && (
                <img
                  alt={accessoryByIdFromState.name + "_icon"}
                  width={"50%"}
                  src={
                    process.env.REACT_APP_API_URL +
                    accessoryByIdFromState.image.contentUrl
                  }
                ></img>
              )}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  ) : (
    <div>
      <h3>Accessoire introuvable</h3>
    </div>
  );
}
