import { normalize } from "normalizr";
import { apiFetch } from "../helpers/api";
import { ADD_ENTITIES } from "../actions/actions";
import { getUser } from "../helpers/user";
import { usersActions } from "../actions/users.actions";
import { formReturnHandler } from "../helpers/form";

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "Call API";

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let { endpoint, requestOptions } = callAPI;
  const { schema, types, success, failure } = callAPI;

  if (!requestOptions) requestOptions = {};

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }

  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  return apiFetch(endpoint, requestOptions).then(
    (response) => {
      const action = { type: successType };

      if (schema && response.status < 400) {
        let normalized = normalize(response.payload, schema);
        next(
          actionWith({
            payload: Object.assign({}, normalized.entities),
            type: ADD_ENTITIES,
          })
        );

        action.payload = response.payload;
        next(actionWith(action));
      } else if (!schema && response.status < 400) {
        action.payload = response.payload;
        next(actionWith(action));
      } else {
        action.endpoint = endpoint;
        action.payload = formReturnHandler(response);
        next(actionWith(action));

        if (failure) failure(response.payload);
      }

      if (response.status < 400 && success) success(response.payload);

      return response;
    },
    (error) => {
      next(
        actionWith({
          type: failureType,
          error: error.message || error.detail || "Something bad happened",
          endpoint,
        })
      );

      if (error.status === 401) {
        // auto logout if 401 response returned from api
        // todo : maybe not reload page ?
        let user = getUser();
        if (user && user.token && user.decoded.exp < Date.now()) {
          next(usersActions.logout());
          window.location.reload(true);
        }
      }

      if (failure) failure(error);

      return Promise.reject(error);
    }
  );
};
