import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const SearchField = withStyles({
  root: {
    "& label": {
      color: "white",
    },
    "& input": {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiFilledInput-underline": {
      borderBottomColor: "white",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiFilledInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

export default function SearchList(props) {
  let searchTimeout = 0;

  return (
    <Grid item container justify={"center"} xs={12}>
      <Grid item lg={6} xs={12}>
        <SearchField
          id="filled-basic"
          label="Rechercher"
          variant="filled"
          margin="none"
          fullWidth
          onChange={(e) => {
            let text = e.target.value;
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              props.onSearch(text);
            }, 500);
          }}
        />
      </Grid>
    </Grid>
  );
}

SearchList.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
