import React from "react";
import ListContainer from "../../containers/projects/Index";
import AccessoriesIndex from "../../containers/admin/accessories/Index";
import ArchivesIndex from "../../containers/admin/archives/Index";
import ClientsIndex from "../../containers/admin/clients/Index";
import InsulationsIndex from "../../containers/admin/insulations/Index";
import PricesEstimatedIndex from "../../containers/admin/prices-estimated/Index";
import PricesIndex from "../../containers/admin/prices/Index";
import ProductsIndex from "../../containers/admin/products/Index";
import SettingsIndex from "../../containers/admin/settings/Index";
import ShippingIndex from "../../containers/admin/shipping/Index";
import ColorsIndex from "../../containers/admin/colors/Index";
import { Route, Redirect } from "react-router-dom";
import { getCurrentProductName } from "../../helpers/api";

export default function RenovRoutes() {
    const productPrefix = getCurrentProductName();

    return <>
        <Route path={`/${productPrefix}/projects`}>
            <ListContainer />
        </Route>
        <Route path={`/${productPrefix}/archives`}>
            <ArchivesIndex />
        </Route>
        <Route path={`/${productPrefix}/clients`}>
            <ClientsIndex />
        </Route>
        <Route path={`/${productPrefix}/products`}>
            <ProductsIndex />
        </Route>
        <Route path={`/${productPrefix}/colors`}>
            <ColorsIndex />
        </Route>
        <Route path={`/${productPrefix}/insulations`}>
            <InsulationsIndex />
        </Route>
        <Route path={`/${productPrefix}/accessories`}>
            <AccessoriesIndex />
        </Route>
        <Route path={`/${productPrefix}/prices`}>
            <PricesIndex />
        </Route>
        <Route path={`/${productPrefix}/prices-estimated`}>
            <PricesEstimatedIndex />
        </Route>
        <Route path={`/${productPrefix}/shipping`}>
            <ShippingIndex />
        </Route>
        <Route path={`/${productPrefix}/settings`}>
            <SettingsIndex />
        </Route>
        <Route>
            <Redirect to={`/${productPrefix}/projects`} />
        </Route>
    </>
}