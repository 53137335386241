import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Form } from "react-final-form";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../../helpers/store";
import { Button } from "@material-ui/core";
import {
  EDIT_USER_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_RESPONSE,
  usersActions,
} from "../../actions/users.actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderTitle from "../../components/HeaderTitle";
import UsersForm, { validate } from "../admin/users/UsersForm";

export default function AccountEdit(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.fetchLoggedUserIfNeeded());
  }, [dispatch]);

  let userByIdFromState = useSelector((state) => {
    return state.users.all[props.currentUser.userId];
  });

  if (!userByIdFromState) {
    userByIdFromState = {cpInCharge: []};
  }

  let values = Object.assign({}, userByIdFromState);

  if (Array.isArray(values.roles)) {
    values.roles = values.roles[0];
  }

  return (
    <>
      <HeaderTitle mb={3} title="Modifier mon compte"/>
      {props.currentUser && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_USER_REQUEST}
          resolve={EDIT_USER_RESPONSE}
          reject={EDIT_USER_ERROR}
        >
          {(onSubmit) => (
            <Form
              mutators={{
                setValue: ([field, value], state, {changeValue}) => {
                  changeValue(state, field, () => value);
                },
              }}
              onSubmit={(form) => {
                form.sellMargin = parseFloat(form.sellMargin);
                form.buyMargin = parseFloat(form.buyMargin);
                form.roles = [form.roles];

                if (form.roles !== "ROLE_SALESPERSON") {
                  form.cpInCharge = [];
                }

                return onSubmit(form);
              }}
              validate={validate}
              initialValues={values}
              render={({handleSubmit, submitting, values, form, valid}) => (
                <form onSubmit={handleSubmit}>
                  <UsersForm
                    values={values}
                    setValue={form.mutators.setValue}
                    account
                  />
                  <Box mt={1} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || !valid}
                    >
                      Editer
                    </Button>
                  </Box>
                </form>
              )}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
