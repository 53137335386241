import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { usersActions } from "../actions/users.actions";
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  Grid,
  Box,
} from "@material-ui/core";
import { AlternateEmail } from "@material-ui/icons";
import { Link } from "react-router-dom";
// import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingButton from "../components/LoadingButton";

// const useStyles = makeStyles((theme) => ({
//   wrapper: {
//     margin: theme.spacing(1),
//     position: "relative",
//   },
//   buttonProgress: {
//     color: theme.palette.primary,
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     marginTop: -12,
//     marginLeft: -12,
//   },
// }));

export default function PasswordResetRequest() {
  const [form, setform] = React.useState({ email: "" });
  const [submitted, setSubmitted] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.logout());
  }, [dispatch]);

  const sendingTokenRequest = useSelector((state) => {
    return state.users.sendingTokenRequest;
  }, shallowEqual);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setform({ ...form, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitted(true);

    if (form.email) {
      dispatch(usersActions.requestPasswordToken(form.email));
    }
  };

  return (
    <div className={"login"}>
      <div className={"left-container"}></div>
      <div className={"right-container"}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justify="center"
          id={"loginFormContainer"}
        >
          <Grid item>
            <img
              src={require("../assets/images/login/onduline-logo.svg")}
              alt={"Logo Onduline"}
              className={"mb-4"}
            />

            <Box mb={4}>
              <p className={"text-center"}>
                Entrez l'adresse email associé à votre compte
                <br />
                un mail vous sera envoyé vous permettant de réinitialiser votre
                mot de passe.
              </p>
            </Box>

            <form name="form" onSubmit={handleSubmit}>
              <Box mb={2} width="100%">
                <FormControl
                  variant="outlined"
                  error={submitted && !form.email}
                >
                  <InputLabel htmlFor="outlined-adornment-text">
                    Email
                  </InputLabel>
                  <OutlinedInput
                    type={"email"}
                    value={form.email}
                    name="email"
                    required
                    onChange={handleChange}
                    aria-describedby="component-error-email"
                    labelWidth={40}
                    error={submitted && !form.email}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <AlternateEmail />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {submitted && !form.email && (
                    <FormHelperText id="component-helper-email">
                      Veuillez renseigner votre email.
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              <Box mt={4} className={"login-footer"}>
                <LoadingButton
                  ldisabled={submitted}
                  oading={sendingTokenRequest}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Réinitialiser
                </LoadingButton>
              </Box>
            </form>
          </Grid>

          <p id={"forgottenPassword"}>
            <Link to={"/login"} title={"Cliquez ici"}>
              Revenir à la page de connexion
            </Link>
          </p>
        </Grid>
      </div>
    </div>
  );
}
