import { ADD_ENTITIES } from "../actions/actions";
import {
  FETCH_ACCESSORY_CATEGORIES_REQUEST,
  FETCH_ACCESSORY_CATEGORIES_SUCCESS,
  INVALIDATE_ACCESSORY_CATEGORIES,
  FETCH_ACCESSORY_CATEGORIES_FAILURE,
} from "../actions/accessoryCategories.actions";
import { accessory, mergeEntitiesWithState } from "../helpers/schema";

const schema = accessory;

export function accessoryCategories(
  state = {
    isFetching: false,
    didInvalidate: false,
    fetched: false,
    all: {},
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_ACCESSORY_CATEGORIES:
      return Object.assign({}, state, {
        ...state,
        didInvalidate: true,
      });
    case ADD_ENTITIES:
      let data = action.payload.accessoryCategory;
      if (!data) return state

      return {
        ...state,
        all: mergeEntitiesWithState(data, state.all, schema),
      };
    case FETCH_ACCESSORY_CATEGORIES_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_ACCESSORY_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
      });
    case FETCH_ACCESSORY_CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}
