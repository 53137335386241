import React from "react";
import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {
    Archive as ArchiveIcon,
    People as PeopleIcon,
    ViewList as ViewListIcon,
    ListAlt as ListAltIcon,
    AttachMoney as AttachMoneyIcon,
    Palette as PaletteIcon,
    Texture as TextureIcon
} from "@material-ui/icons";
import { getCurrentProductName } from "../../helpers/api";

const useStyles = makeStyles((theme) => ({
    listItem: {
        "&:hover": {
            color: "red",
        },
        "&:focus": {
            color: "red",
        },
    },
}));

export default function RenovItems({ isAdmin }) {
    const classes = useStyles();
    const productPrefix = getCurrentProductName();

    return <>
        <List>
            <ListItem
                className={classes.listItem}
                component={NavLink}
                activeClassName="Mui-selected"
                to={`/${productPrefix}/projects`}
                button
            >
                <ListItemIcon>
                    <ViewListIcon />
                </ListItemIcon>
                <ListItemText primary="Projets" />
            </ListItem>

            <ListItem
                className={classes.listItem}
                component={NavLink}
                activeClassName="Mui-selected"
                to={`/${productPrefix}/archives`}
                button
            >
                <ListItemIcon>
                    <ArchiveIcon />
                </ListItemIcon>
                <ListItemText primary="Archives" />
            </ListItem>

            {isAdmin && <>
                <Divider />

                <ListItem
                    className={classes.listItem}
                >
                    <ListItemText primary="ADMINISTRATEUR" />
                </ListItem>

                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    activeClassName="Mui-selected"
                    to={`/${productPrefix}/clients`}
                    button
                >
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clients" />
                </ListItem>

                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    activeClassName="Mui-selected"
                    to={`/${productPrefix}/products`}
                    button
                >
                    <ListItemIcon>
                        <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Produits" />
                </ListItem>

                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    activeClassName="Mui-selected"
                    to={`/${productPrefix}/prices`}
                    button
                >
                    <ListItemIcon>
                        <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tarifs" />
                </ListItem>

                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    activeClassName="Mui-selected"
                    to={`/${productPrefix}/options`}
                    button
                >
                    <ListItemIcon>
                        <TextureIcon />
                    </ListItemIcon>
                    <ListItemText primary="Options" />
                </ListItem>

                <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    activeClassName="Mui-selected"
                    to={`/${productPrefix}/colors`}
                    button
                >
                    <ListItemIcon>
                        <PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Finitions" />
                </ListItem>
            </>
            }
        </List>
    </>
}