import { ADD_ENTITIES } from "../actions/actions";
import { mergeEntitiesWithState, productColor } from "../helpers/schema";
import { FETCH_PRODUCT_COLORS_REQUEST, FETCH_PRODUCT_COLORS_SUCCESS, INVALIDATE_PRODUCT_COLORS } from '../actions/productColor.actions';

const schema = productColor;

export function productColors(
  state = {
    isFetching: false,
    didInvalidate: false,
    fetched: false,
    all: {}
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_PRODUCT_COLORS:
      state.all = {};
      return Object.assign({}, state, {
        ...state,
        didInvalidate: true
      });
    case ADD_ENTITIES:
      let data = action.payload.productColor;
      if (!data) return state;

      return {
        ...state,
        all: mergeEntitiesWithState(data, state.all, schema),
      };
    case FETCH_PRODUCT_COLORS_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false
      });
    case FETCH_PRODUCT_COLORS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt
      });
    default:
      return state;
  }
}
