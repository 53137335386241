import { Box, Button, CircularProgress } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RefreshIcon from "@material-ui/icons/Refresh";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { projectsActions } from "../../../actions/projects.actions";
import ArchivesList from "../../../components/archives/ArchivesList";
import HeaderTitle from "../../../components/HeaderTitle";
import SearchList from "../../../components/searchList";
import { filterElement } from "../../../helpers/schema";


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastUpdated: props.lastUpdated,
      search: "",
      projects: [],
      fetching: false,
      page: 1,
    };
  }

  render() {
    return (
      <Switch>
        <Route exact path="/renov/archives">
          <ProjectsIndex lastUpdated={this.state.lastUpdated} />
        </Route>
      </Switch>
    );
  }
}

Index.propTypes = {
  users: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { lastUpdated } = state.users || {
    isFetching: true,
  };

  const isFetching = state.users.isFetching;

  const users = state.users.all || [];

  return {
    users,
    isFetching,
    lastUpdated,
  };
}

const useStyles = makeStyles({
  container: {
    maxHeight: "78vh",
  },
});

const ProjectsIndex = ({ lastUpdated }) => {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = React.useState("");
  const [isFetching, setSetFetching] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const dispatch = useDispatch();

  const getProjects = async (givenProjects) => {
    setSetFetching(true);
    const toAddProjects = givenProjects ? givenProjects : projects;

    let newProjects = [];
    try {
      const response = await dispatch(
        projectsActions.fetchProjects(true, true, page, 10, search, true)
      );
      newProjects = response.payload;
    } catch (e) {
      console.error(e);
    }

    newProjects = [...toAddProjects, ...newProjects];

    setProjects(newProjects);
    setSetFetching(false);
  };

  const handleRefreshClick = () => {
    getProjects([]);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const onSearch = (search) => {
    setSearchFilter(search);
  };

  useEffect(() => {
    getProjects();
  }, [search, page]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const id = open ? "help-popover" : undefined;

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <Switch>
      <Route exact path="/renov/archives">
        <HeaderTitle
          mb={3}
          title="Archives"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
          bottom={<SearchList onSearch={onSearch} />}
        />
        {isFetching && Object.entries(projects).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        <div className={"accessories-container"} style={{ height: "100%" }}>
          {!isFetching && Object.entries(projects).length === 0 && (
            <h2>Aucune donnée disponible.</h2>
          )}

          {Object.entries(projects).length > 0 && (
            <ArchivesList
              projects={Object.values(projects).filter((item) =>
                {
                  return filterElement(item, ["name", "won", "customRef", "finalClient.name", "client.name"], searchFilter)
                }
              )}
            />
          )}
        </div>
      </Route>
    </Switch>
  );
}

export default connect(mapStateToProps)(Index);