import { ADD_ENTITIES } from "../actions/actions";
import {
  DISABLE_USER_ERROR,
  DISABLE_USER_REQUEST,
  DISABLE_USER_RESPONSE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  INVALIDATE_USERS,
  PASSWORD_TOKEN_USER_ERROR,
  PASSWORD_TOKEN_USER_REQUEST,
  PASSWORD_TOKEN_USER_RESPONSE,
  SET_PASSWORD_TOKEN_USER_ERROR,
  SET_PASSWORD_TOKEN_USER_REQUEST,
  SET_PASSWORD_TOKEN_USER_RESPONSE,
} from "../actions/users.actions";
import { mergeEntitiesWithState, user } from "../helpers/schema";

const schema = user;

export function users(
  state = {
    isFetching: false,
    isEditing: false,
    sendingTokenRequest: false,
    sendingPasswordRequest: false,
    didInvalidate: false,
    fetched: false,
    all: {},
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_USERS:
      return Object.assign({}, state, {
        ...state,
        didInvalidate: true,
      });
    case ADD_ENTITIES:
      let data = action.payload.user;
      let managedUsers = action.payload.managedUser || {};

      if (managedUsers) {
        let managedUsersFormatted = {};

        Object.values(managedUsers).forEach(
          (user) =>
            (managedUsersFormatted[user.id] = {
              ...user,
              managed: true,
            })
        );

        state = {
          ...state,
          all: mergeEntitiesWithState(managedUsersFormatted, state.all, schema),
        };
      }

      if (data) {
        state = {
          ...state,
          all: mergeEntitiesWithState(data, state.all, schema),
        };
      }

      return state;
    case FETCH_USERS_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_USERS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        fetched: true,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
      });
    case PASSWORD_TOKEN_USER_REQUEST:
      return Object.assign({}, state, {
        ...state,
        sendingTokenRequest: true,
      });
    case PASSWORD_TOKEN_USER_RESPONSE:
      return Object.assign({}, state, {
        ...state,
        sendingTokenRequest: false,
      });
    case PASSWORD_TOKEN_USER_ERROR:
      return Object.assign({}, state, {
        ...state,
        sendingTokenRequest: false,
      });
    case SET_PASSWORD_TOKEN_USER_REQUEST:
      return Object.assign({}, state, {
        ...state,
        sendingPasswordRequest: true,
      });
    case SET_PASSWORD_TOKEN_USER_RESPONSE:
      return Object.assign({}, state, {
        ...state,
        sendingPasswordRequest: false,
      });
    case SET_PASSWORD_TOKEN_USER_ERROR:
      return Object.assign({}, state, {
        ...state,
        sendingPasswordRequest: false,
      });
    case DISABLE_USER_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isEditing: true,
      });
    case DISABLE_USER_RESPONSE:
      return Object.assign({}, state, {
        ...state,
        isEditing: false,
      });
    case DISABLE_USER_ERROR:
      return Object.assign({}, state, {
        ...state,
        isEditing: false,
      });
    default:
      return state;
  }
}
