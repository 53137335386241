import { Box, CircularProgress } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Archive as ArchiveIcon } from "@material-ui/icons";
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { csvActions } from "../../actions/csv.actions";

export default function ExportCsvButton(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(false);
  }, [dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<ArchiveIcon />} onClick={handleClickOpen}>
        {props.children}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Exporter</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Etes-vous sûr de vouloir exporter ces données ?
          </DialogContentText>
          <Button component="label" variant="outlined" color="primary" disabled={loading} onClick={() => {
            dispatch(csvActions.download(props.entity)).then(result => {
              var link = document.createElement("a");
              link.setAttribute("href", window.URL.createObjectURL(result.payload));
              link.setAttribute("download", `${props.fileName}.csv`);
              document.body.appendChild(link); // Required for FF
  
              link.click();
            })
          }}>
            Exporter
          </Button>
          {loading &&
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
            >
              <CircularProgress />
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
