import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { appActions } from "../../../../actions/app.actions";
import {
  productsActions
} from "../../../../actions/product.actions";
import {
  EDIT_PRODUCT_VARIANT_ERROR,
  EDIT_PRODUCT_VARIANT_REQUEST,
  EDIT_PRODUCT_VARIANT_RESPONSE,
} from "../../../../actions/productVariant.actions";
import HeaderTitle from "../../../../components/HeaderTitle";
import PricesRow from "../../../../components/prices/thermo/PricesRow";
import {
  selectProducts
} from "../../../../helpers/schema";
import ExportCsvButton from "../../../settings/ExportCsvButton";
import ImportCsvButton from "../../../settings/ImportCsvButton";
import { AREAS } from "../../../../helpers/constante";

const useStyles = makeStyles({
  container: {
    maxHeight: "78vh",
  },
});

export default function  Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadItems, _setLoadItems] = React.useState(50);

  const loadItemsRef = React.useRef(loadItems);
  const setLoadItems = (data) => {
    loadItemsRef.current = data;
    _setLoadItems(data);
  };

  const isFetching = useSelector((state) => {
    return state.products.isFetching || state.accessories.isFetching;
  }, shallowEqual);

  const products = useSelector((state) => {
    return Object.values(selectProducts(state)) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(productsActions.fetchProductsIfNeeded());
    dispatch(appActions.setNextPage("/thermo/prices"));
  }, [dispatch]);

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(productsActions.invalidateProducts());
    dispatch(productsActions.fetchProductsIfNeeded());
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      "& td": {
        color: "white",
        fontWeight: "bold",
      },
    },
  }))(TableRow);

  return (
    <Switch>
      <Route exact path="/thermo/prices">
        <TableContainer>
          <TableRow
          >
            <TableCell align={"center"}>
              <ImportCsvButton entity="prices">Importer</ImportCsvButton>
            </TableCell>
            <TableCell align={"center"}>
              <ExportCsvButton entity="prices" fileName="Tarifs">Exporter</ExportCsvButton>
            </TableCell>
          </TableRow>
        </TableContainer>
        <HeaderTitle
          mb={3}
          title="Tarifs"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
        />

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Matière</TableCell>
                <TableCell>Epaisseur (mm)</TableCell>
                {AREAS.map(area => (
                  <TableCell align={"center"}>{area.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {products
                .filter((product) => product.variants?.length > 0)
                .map((product) => (
                  <>
                    <StyledTableRow>
                      <TableCell colSpan={7}>{product.name}</TableCell>
                    </StyledTableRow>
                    <PricesRow
                      product={product}
                      name={product.name}
                      key={product.name}
                      start={EDIT_PRODUCT_VARIANT_REQUEST}
                      resolve={EDIT_PRODUCT_VARIANT_RESPONSE}
                      reject={EDIT_PRODUCT_VARIANT_ERROR}
                    />
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Route>
    </Switch>
  );
}
