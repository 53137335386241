import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { Form } from "react-final-form";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../../../helpers/store";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { appActions } from "../../../actions/app.actions";
import {
  EDIT_USER_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_RESPONSE,
} from "../../../actions/users.actions";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import UsersForm, { validate } from "./UsersForm";
import HeaderTitle from "../../../components/HeaderTitle";

export default function UserEdit() {
  let { userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setNextPage("/users"));
  }, [dispatch]);

  let userByIdFromState = useSelector((state) => {
    return state.users.all[userId];
  });

  const isFetching = useSelector((state) => {
    return state.users.isFetching;
  });

  if (!userByIdFromState) {
    userByIdFromState = { cpInCharge: [] };
  }

  let values = Object.assign({}, userByIdFromState);
  values.products = [];
  let role = '';

  if (Array.isArray(values.roles)) {
    for (let i = 0; i < values.roles.length; i++) {
      if (values.roles[i].startsWith('ROLE_PRODUCT')) {
        values.products.push(values.roles[i]);
      } else if (role === '') {
        role = values.roles[i];
      }
    }
  }

  values.roles = role;

  let title;
  if (userByIdFromState.firstName)
    title = `Editer l'utilisateur ${userByIdFromState?.firstName} ${userByIdFromState?.lastName}`;
  else
    title = "Editer l'utilisateur";

  return (
    <>
      <HeaderTitle mb={3} title={title}/>
      {isFetching && !userByIdFromState.firstName && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetching && !userByIdFromState.firstName && (
        <Alert severity="error">Utilisateur non trouvé !</Alert>
      )}
      {userByIdFromState.firstName && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_USER_REQUEST}
          resolve={EDIT_USER_RESPONSE}
          reject={EDIT_USER_ERROR}
        >
          {(onSubmit) => (
            <Form
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              onSubmit={(form) => {
                form.sellMargin = parseFloat(form.sellMargin);
                form.buyMargin = parseFloat(form.buyMargin);
                form.roles = [form.roles];

                if (form.roles !== "ROLE_SALESPERSON") {
                  form.cpInCharge = [];
                }

                return onSubmit(form);
              }}
              validate={validate}
              initialValues={values}
              render={({ handleSubmit, submitting, form, values, valid }) => (
                <form onSubmit={handleSubmit}>
                  <UsersForm
                    values={values}
                    setValue={form.mutators.setValue}
                    edit
                  />

                  <Box mt={1} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || !valid}
                    >
                      Editer
                    </Button>
                  </Box>
                </form>
              )}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
