import { ADD_ENTITIES } from "../actions/actions";
import { media, mergeEntitiesWithState } from "../helpers/schema";

const schema = media;

export function medias(
    state = {
        all: {}
    },
    action
) {
    switch (action.type) {
        case ADD_ENTITIES:
            let data = action.payload.media;
            if (!data) return state

            return {
                ...state,
                all: mergeEntitiesWithState(data, state.all, schema)
            };
        default:
            return state
    }
}