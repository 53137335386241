export const SET_NEXT_PAGE       = "SET_NEXT_PAGE";
export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";

function setNextPage (nextPage) {
    return {
        type: SET_NEXT_PAGE,
        nextPage
    };
}

function setCurrentProject (currentProject) {
    return {
        type: SET_CURRENT_PROJECT,
        currentProject
    };
}

function setCurrentProduct (currentProduct) {
    return {
        type: SET_CURRENT_PRODUCT,
        currentProduct
    };
}

export const appActions = {
    setNextPage,
    setCurrentProject,
    setCurrentProduct
};