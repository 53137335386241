import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
  accessoriesActions,
  EDIT_ACCESSORY_ERROR,
  EDIT_ACCESSORY_REQUEST,
  EDIT_ACCESSORY_RESPONSE
} from "../../../actions/accessories.actions";
import { appActions } from "../../../actions/app.actions";
import {
  EDIT_INSULATION_ERROR,
  EDIT_INSULATION_REQUEST,
  EDIT_INSULATION_RESPONSE,
  insulationsActions
} from "../../../actions/insulations.actions";
import {
  EDIT_PRODUCT_ERROR,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_RESPONSE,
  productsActions
} from "../../../actions/product.actions";
import HeaderTitle from "../../../components/HeaderTitle";
import PricesRow from "../../../components/prices/PricesRow";
import {
  selectAccessories,
  selectInsulations,
  selectProducts
} from "../../../helpers/schema";
import ExportCsvButton from "../../settings/ExportCsvButton";
import ImportCsvButton from "../../settings/ImportCsvButton";

const useStyles = makeStyles({
  container: {
    maxHeight: "78vh",
  },
});

export default function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadItems, _setLoadItems] = React.useState(50);

  const loadItemsRef = React.useRef(loadItems);
  const setLoadItems = (data) => {
    loadItemsRef.current = data;
    _setLoadItems(data);
  };

  const isFetching = useSelector((state) => {
    return state.products.isFetching || state.accessories.isFetching;
  }, shallowEqual);

  const products = useSelector((state) => {
    return Object.values(selectProducts(state)) || [];
  }, shallowEqual);

  const insulations = useSelector((state) => {
    return Object.values(selectInsulations(state)) || [];
  }, shallowEqual);

  const accessories = useSelector((state) => {
    return Object.values(selectAccessories(state)) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(productsActions.fetchProductsIfNeeded());
    dispatch(accessoriesActions.fetchAccessoriesIfNeeded());
    dispatch(insulationsActions.fetchInsulationsIfNeeded());
    dispatch(appActions.setNextPage("/renov/prices"));
  }, [dispatch]);

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(productsActions.invalidateProducts());
    dispatch(accessoriesActions.invalidateAccessories());
    dispatch(insulationsActions.invalidateInsulations());
    dispatch(productsActions.fetchProductsIfNeeded());
    dispatch(insulationsActions.fetchInsulationsIfNeeded());
    dispatch(accessoriesActions.fetchAccessoriesIfNeeded());
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      "& td": {
        color: "white",
        fontWeight: "bold",
      },
    },
  }))(TableRow);

  return (
    <Switch>
      <Route exact path="/renov/prices">
        <TableContainer>
          <TableRow
          >
            <TableCell align={"center"}>
              <ImportCsvButton entity="prices">Importer</ImportCsvButton>
            </TableCell>
            <TableCell align={"center"}>
              <ExportCsvButton entity="prices" fileName="Tarifs">Exporter</ExportCsvButton>
            </TableCell>
          </TableRow>
        </TableContainer>
        <HeaderTitle
          mb={3}
          title="Tarifs"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
        />

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Désignation</TableCell>
                <TableCell align={"center"}>Prix de cession</TableCell>
                <TableCell align={"center"}>&lt; 100m²</TableCell>
                <TableCell align={"center"}>101 - 300m²</TableCell>
                <TableCell align={"center"}>301 - 600m²</TableCell>
                <TableCell align={"center"}>601 - 1000m²</TableCell>
                <TableCell align={"center"}>&gt; 1001m²</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell colSpan={7}>Produits</TableCell>
              </StyledTableRow>
              {products
                .filter((product) => product.prices?.length > 0)
                .map((product) => (
                  <PricesRow
                    product={product}
                    prices={product.prices}
                    name={product.name}
                    key={product.name}
                    start={EDIT_PRODUCT_REQUEST}
                    resolve={EDIT_PRODUCT_RESPONSE}
                    reject={EDIT_PRODUCT_ERROR}
                  />
                ))}

              <StyledTableRow>
                <TableCell colSpan={7}>Isolants</TableCell>
              </StyledTableRow>
              {insulations
                .filter((insulation) => insulation.prices?.length > 0)
                .map((insulation) => (
                  <PricesRow
                    accessory={insulation}
                    prices={insulation.prices}
                    name={insulation.name}
                    key={insulation.name}
                    start={EDIT_INSULATION_REQUEST}
                    resolve={EDIT_INSULATION_RESPONSE}
                    reject={EDIT_INSULATION_ERROR}
                  />
                ))}
              <StyledTableRow>
                <TableCell colSpan={7}>Accessoires</TableCell>
              </StyledTableRow>
              {accessories
                .filter((accessory) => accessory.prices?.length > 0)
                .map((accessory) => (
                  <PricesRow
                    accessory={accessory}
                    prices={accessory.prices}
                    name={accessory.name}
                    key={accessory.name}
                    start={EDIT_ACCESSORY_REQUEST}
                    resolve={EDIT_ACCESSORY_RESPONSE}
                    reject={EDIT_ACCESSORY_ERROR}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Route>
    </Switch>
  );
}
