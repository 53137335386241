export const ADD_ENTITIES = "ADD_ENTITIES";
export const RESET = "RESET";

export const addEntities  = (entities) => ({
    type: ADD_ENTITIES,
    payload: entities,
});

export const reset = () => ({
    type: RESET
});