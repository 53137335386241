import React, { useEffect } from "react";
import { Form } from "react-final-form";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../../../helpers/store";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import {
  EDIT_CLIENT_ERROR,
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_RESPONSE,
} from "../../../actions/clients.actions";
import { appActions } from "../../../actions/app.actions";
import ClientsForm from "./ClientsForm";
import Box from "@material-ui/core/Box";
import { history } from "../../../helpers/history";

export default function ClientsEdit() {
  let { clientId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setNextPage("/renov/clients"));
  }, [dispatch]);

  let client = useSelector((state) => {
    return state.clients.all[clientId] || {};
  });

  let image = useSelector((state) => {
    return state.medias.all[client.image] || {};
  });

  return (
    <div>
      <MakeAsyncFunction
        listener={promiseListener}
        start={EDIT_CLIENT_REQUEST}
        resolve={EDIT_CLIENT_RESPONSE}
        reject={EDIT_CLIENT_ERROR}
      >
        {(onSubmit) => (
          <Form
            onSubmit={(form) => {
              onSubmit(form).then(() => history.push("/renov/clients"))
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={client}
            render={({ handleSubmit, submitting, form }) => (
              <form onSubmit={handleSubmit}>
                <ClientsForm
                  image={image.contentUrl || null}
                  setValue={form.mutators.setValue}
                />

                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Editer
                  </Button>
                </Box>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    </div>
  );
}
