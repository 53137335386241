import PropTypes from "prop-types";
import React from "react";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "../helpers/store";

const handleChange = (event, func, onChange, cb) => {
  if (typeof onChange === "function") onChange();
  func(event.target.files[0]).then(cb);
};

export default function FileUploader(props) {
  let { onResult, ...asyncEvents } = props;

  return (
    <MakeAsyncFunction listener={promiseListener} {...asyncEvents}>
      {(asyncFunc) => (
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(event) => {
            handleChange(event, asyncFunc, props.onChange, onResult);
          }}
        />
      )}
    </MakeAsyncFunction>
  );
}

FileUploader.propTypes = {
  onResult: PropTypes.func.isRequired,
  start: PropTypes.string.isRequired,
  resolve: PropTypes.string.isRequired,
  reject: PropTypes.string.isRequired,
};
