import { SET_CURRENT_PRODUCT, SET_CURRENT_PROJECT, SET_NEXT_PAGE } from "../actions/app.actions";

export function app(
    state = {
        nextPage: "/",
        currentProject: "",
        currentProduct: sessionStorage.getItem('currentProduct')
    }, action) {
    switch (action.type) {
        case SET_NEXT_PAGE:
            return {
                ...state,
                nextPage: action.nextPage
            };
        case SET_CURRENT_PROJECT:
            return {
                ...state,
                currentProject: action.currentProject
            };
        case SET_CURRENT_PRODUCT:
            return {
                ...state,
                currentProduct: action.currentProduct
            };
        default:
            return state
    }
}