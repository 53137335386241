import { store } from "..";
import { humanReadableRoles } from "./constante";

export function getCurrentProductName() {
    return humanReadableRoles[store.getState().app.currentProduct]?.toLowerCase();
}

export function apiFetch(endpoint, args = {}) {
    args.headers = authHeader(Object.assign({}, args.headers));

    // if (!('Content-Type' in args.headers)) {
    //     args.headers['Content-Type'] = 'application/json';
    // }

    if (!('Accept' in args.headers)) {
        args.headers['Accept'] = 'application/json';
    }

    if (!('method' in args)) {
        args['method'] = 'GET';
    }

    return fetch(process.env.REACT_APP_API_URL + "/" + endpoint, args)
        .then(response => handleResponse(response, args["method"], args.headers['Accept']));
}

function handleResponse(response, method, type) {
    let next;
    if (type === 'application/pdf' || type === 'text/csv') {
        next = response.blob();
    } else {
        next = response.json();
    }

    return next.then(data => {
        if (response.status >= 500 || response.status === 401 || (!response.ok && method === "GET")) {
            const error = data || response.statusText;
            return Promise.reject({ status: response.status, ...error });
        }

        return {status: response.status, payload: data};
    });
}

export function authHeader(header = {}) {
    // return authorization header with jwt token
    let user = getUser();

    if (user && user.token) {
        header['Authorization'] = 'Bearer ' + user.token
        return header;
    } else {
        return header;
    }
}

function getUser() {
    return JSON.parse(localStorage.getItem('user'));
}

export function shouldFetch(state, entity) {
    if (state[entity].isFetching) {
        return false
    } else if (!state[entity].fetched) {
        return true
    } else {
        return state[entity].didInvalidate
    }
}

export function getProjectIdFromEndpoint(endpoint) {
    return endpoint.split("projects/")[1] || -1;
}

