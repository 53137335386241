import { CALL_API } from "../middleware/api";
import { productOption } from "../helpers/schema";
import { shouldFetch } from "../helpers/api";
import {buildNextUrl, history} from "../helpers/history";
import {closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction} from "./notifications.actions";
import Button from "@material-ui/core/Button";
import React from "react";


// ----------------------
// Product Fetching
// ----------------------
export const FETCH_PRODUCT_OPTIONS_REQUEST = "FETCH_PRODUCT_OPTIONS_REQUEST";
export const FETCH_PRODUCT_OPTIONS_SUCCESS = "FETCH_PRODUCT_OPTIONS_SUCCESS";
export const FETCH_PRODUCT_OPTIONS_FAILURE = "FETCH_PRODUCT_OPTIONS_FAILURE";
export const INVALIDATE_PRODUCT_OPTIONS = "INVALIDATE_PRODUCT_OPTIONS";

function invalidateProductOptions() {
    return { type: INVALIDATE_PRODUCT_OPTIONS };
}

function fetchProductOptionsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetch(getState(), "productOptions")) {
            return dispatch(fetchProductOptions());
        }
    };
}

const fetchProductOptions = () => ({
    [CALL_API]: {
        types: [
            FETCH_PRODUCT_OPTIONS_REQUEST,
            FETCH_PRODUCT_OPTIONS_SUCCESS,
            FETCH_PRODUCT_OPTIONS_FAILURE
        ],
        endpoint: `product_option_thermos?pagination=false`,
        schema: [productOption],
    }
});

// ----------------------
// Option Create
// ----------------------

export const ADD_PRODUCT_OPTION_REQUEST = "ADD_PRODUCT_OPTION_REQUEST";
export const ADD_PRODUCT_OPTION_RESPONSE = "ADD_PRODUCT_OPTION_RESPONSE";
export const ADD_PRODUCT_OPTION_ERROR = "ADD_PRODUCT_OPTION_ERROR";

const add = (form) => (dispatch, getState) =>
    dispatch({
        [CALL_API]: {
            types: [
                ADD_PRODUCT_OPTION_REQUEST,
                ADD_PRODUCT_OPTION_RESPONSE,
                ADD_PRODUCT_OPTION_ERROR,
            ],
            endpoint: 'product_option_thermos',
            schema: productOption,
            requestOptions: {
                headers: { "content-type": "application/json" },
                method: "POST",
                body: JSON.stringify(form),
            },
            success: (result) => {
                if (history.location.pathname.includes("options")) {
                    history.push(buildNextUrl(getState()));
                }
            },
        },
    });

// ----------------------
// Option Edit
// ----------------------

export const EDIT_PRODUCT_OPTION_REQUEST = "EDIT_PRODUCT_OPTION_REQUEST";
export const EDIT_PRODUCT_OPTION_RESPONSE = "EDIT_PRODUCT_OPTION_RESPONSE";
export const EDIT_PRODUCT_OPTION_ERROR = "EDIT_PRODUCT_OPTION_ERROR";

const edit = (form) => (dispatch, getState) =>
    dispatch({
        [CALL_API]: {
            types: [
                EDIT_PRODUCT_OPTION_REQUEST,
                EDIT_PRODUCT_OPTION_RESPONSE,
                EDIT_PRODUCT_OPTION_ERROR,
            ],
            endpoint: `product_option_thermos/` + form.id,
            schema: productOption,
            requestOptions: {
                method: "PUT",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(form),
            },
            success: (result) => {
                if (history.location.pathname.includes("options")) {
                    history.push(buildNextUrl(getState()));
                }
            },
        },
    });

// ----------------------
// Option disable
// ----------------------

export const DISABLE_PRODUCT_OPTION_REQUEST = "DISABLE_PRODUCT_OPTION_REQUEST";
export const DISABLE_PRODUCT_OPTION_RESPONSE = "DISABLE_PRODUCT_OPTION_RESPONSE";
export const DISABLE_PRODUCT_OPTION_ERROR = "DISABLE_PRODUCT_OPTION_ERROR";

const disable = (optionId) => (dispatch) =>
    dispatch({
        [CALL_API]: {
            types: [
                DISABLE_PRODUCT_OPTION_REQUEST,
                DISABLE_PRODUCT_OPTION_RESPONSE,
                DISABLE_PRODUCT_OPTION_ERROR,
            ],
            endpoint: `product_option_thermos/${optionId}`,
            schema: productOption,
            requestOptions: {
                method: "PUT",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ enabled: false }),
            },
            success: (result) => {
                dispatch(
                    enqueueSnackbarAction({
                        message: "Option supprimée avec succès.",
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            action: (key) => (
                                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                                    OK
                                </Button>
                            ),
                        },
                    })
                );
            },
        },
    });

export const productOptionsActions = {
    invalidateProductOptions,
    fetchProductOptionsIfNeeded,
    add,
    edit,
    disable
};
