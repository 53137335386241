import { TableCell, TableRow, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import {
  EDIT_PRICEESTIMATED_ERROR,
  EDIT_PRICEESTIMATED_REQUEST,
  EDIT_PRICEESTIMATED_RESPONSE,
} from "../../actions/priceEstimated.actions";
import PriceEstimatedItem from "./PriceEstimatedItem";

export default function PricesEstimatedRow(props) {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {props.productVariantName}
        <Typography
          variant="body2"
          color={"textSecondary"}
          style={{ fontStyle: "italic" }}
          noWrap
        >
          {props.insulationName}&nbsp;
        </Typography>
      </TableCell>
      {props.priceRow.map((priceRow, i) => (
        <Fragment key={i}>
          <PriceEstimatedItem
            start={EDIT_PRICEESTIMATED_REQUEST}
            resolve={EDIT_PRICEESTIMATED_RESPONSE}
            reject={EDIT_PRICEESTIMATED_ERROR}
            entity={priceRow}
            priceProperty={"priceEstimated"}
          />
        </Fragment>
      ))}
    </TableRow>
  );
}
