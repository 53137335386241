import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Root from "./containers/Root";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./helpers/store";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import Notifier from "./containers/Notifier";

import "react-virtualized/styles.css";

export const store = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#dc2c32",
    },
    secondary: {
      main: "#000",
    },
    background: {
      primary: "#dc2c32",
    },
    stepperHeader: {
      primary: "#ffffff",
      primaryText: "#dc2c32",
      disabled: "rgba(0, 0, 0, 0.50)",
      active: "rgba(0, 0, 0, 0.20)",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <Notifier />
          <Root />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
