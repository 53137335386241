import { shouldFetch } from "../helpers/api";
import { buildNextUrl, history } from "../helpers/history";
import { shipping } from "../helpers/schema";
import { CALL_API } from "../middleware/api";

// ----------------------
// Shipping Fetching
// ----------------------
export const FETCH_SHIPPINGS_REQUEST = "FETCH_SHIPPINGS_REQUEST";
export const FETCH_SHIPPINGS_SUCCESS = "FETCH_SHIPPINGS_SUCCESS";
export const FETCH_SHIPPINGS_FAILURE = "FETCH_SHIPPINGS_FAILURE";
export const INVALIDATE_SHIPPINGS = "INVALIDATE_SHIPPINGS";

function invalidateShippings() {
  return { type: INVALIDATE_SHIPPINGS };
}

function fetchShippingsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "shippings")) {
      return dispatch(fetchShippings());
    }
  };
}

const fetchShippings = () => ({
  [CALL_API]: {
    types: [
      FETCH_SHIPPINGS_REQUEST,
      FETCH_SHIPPINGS_SUCCESS,
      FETCH_SHIPPINGS_FAILURE,
    ],
    endpoint: `price_shippings?pagination=false`,
    schema: [shipping],
  },
});

// ----------------------
// Shipping Edit
// ----------------------

export const EDIT_SHIPPING_REQUEST = "EDIT_SHIPPING_REQUEST";
export const EDIT_SHIPPING_RESPONSE = "EDIT_SHIPPING_RESPONSE";
export const EDIT_SHIPPING_ERROR = "EDIT_SHIPPING_ERROR";

const edit = (form) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      types: [
        EDIT_SHIPPING_REQUEST,
        EDIT_SHIPPING_RESPONSE,
        EDIT_SHIPPING_ERROR,
      ],
      endpoint: `price_shippings/` + form.id,
      schema: shipping,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify(form),
      },
      success: (result) => {
        history.push(buildNextUrl(getState()));
      },
    },
  });
};

export const shippingsActions = {
  invalidateShippings,
  fetchShippingsIfNeeded,
  edit,
};
