import { ADD_ENTITIES } from "../actions/actions";
import {
  EDIT_QUOTATION_REQUEST,
  EDIT_QUOTATION_ERROR,
  EDIT_QUOTATION_RESPONSE,
} from "../actions/quotations.actions";
import { mergeEntitiesWithState, quotation } from "../helpers/schema";

const schema = quotation;

export function quotations(
  state = {
    all: {},
  },
  action
) {
  switch (action.type) {
    case ADD_ENTITIES:
      let data = action.payload.quotation;
      if (!data) return state;

      return {
        ...state,
        all: mergeEntitiesWithState(data, state.all, schema),
      };
    case EDIT_QUOTATION_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isUpdating: true,
      });
    case EDIT_QUOTATION_RESPONSE:
      return Object.assign({}, state, {
        ...state,
        isUpdating: false,
      });
    case EDIT_QUOTATION_ERROR:
      return Object.assign({}, state, {
        ...state,
        isUpdating: false,
      });
    default:
      return state;
  }
}
