import { CALL_API } from "../middleware/api";
import {productColor} from "../helpers/schema";
import { getCurrentProductName, shouldFetch } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constante";
import {buildNextUrl, history} from "../helpers/history";
import {closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction} from "./notifications.actions";
import Button from "@material-ui/core/Button";
import React from "react";

// ----------------------
// Product Fetching
// ----------------------
export const FETCH_PRODUCT_COLORS_REQUEST = "FETCH_PRODUCT_COLORS_REQUEST";
export const FETCH_PRODUCT_COLORS_SUCCESS = "FETCH_PRODUCT_COLORS_SUCCESS";
export const FETCH_PRODUCT_COLORS_FAILURE = "FETCH_PRODUCT_COLORS_FAILURE";
export const INVALIDATE_PRODUCT_COLORS    = "INVALIDATE_PRODUCT_COLORS";

function invalidateProductColors () {
    return {type: INVALIDATE_PRODUCT_COLORS};
}

function fetchProductColorsIfNeeded () {
    return (dispatch, getState) => {
        if (shouldFetch(getState(), "productColors")) {
            return dispatch(fetchProductColors());
        }
    };
}

const fetchProductColors = () => ({
    [CALL_API]: {
        types: [
            FETCH_PRODUCT_COLORS_REQUEST,
            FETCH_PRODUCT_COLORS_SUCCESS,
            FETCH_PRODUCT_COLORS_FAILURE
        ],
        endpoint: `${ENDPOINTS.productsColors[getCurrentProductName()]}?pagination=false`,
        schema: [productColor],
    }
});

// ----------------------
// Color Create
// ----------------------

export const ADD_PRODUCT_COLOR_REQUEST = "ADD_PRODUCT_COLOR_REQUEST";
export const ADD_PRODUCT_COLOR_RESPONSE = "ADD_PRODUCT_COLOR_RESPONSE";
export const ADD_PRODUCT_COLOR_ERROR = "ADD_PRODUCT_COLOR_ERROR";

const add = (form) => (dispatch, getState) =>
    dispatch({
        [CALL_API]: {
            types: [
                ADD_PRODUCT_COLOR_REQUEST,
                ADD_PRODUCT_COLOR_RESPONSE,
                ADD_PRODUCT_COLOR_ERROR,
            ],
            endpoint: ENDPOINTS.productsColors[getCurrentProductName()],
            schema: productColor,
            requestOptions: {
                headers: { "content-type": "application/json" },
                method: "POST",
                body: JSON.stringify(form),
            },
            success: (result) => {
                if (history.location.pathname.includes("colors")) {
                    history.push(buildNextUrl(getState()));
                }
            },
        },
    });

// ----------------------
// Color Edit
// ----------------------

export const EDIT_PRODUCT_COLOR_REQUEST = "EDIT_PRODUCT_COLOR_REQUEST";
export const EDIT_PRODUCT_COLOR_RESPONSE = "EDIT_PRODUCT_COLOR_RESPONSE";
export const EDIT_PRODUCT_COLOR_ERROR = "EDIT_PRODUCT_COLOR_ERROR";

const edit = (form) => (dispatch, getState) => 
    dispatch({
        [CALL_API]: {
            types: [
                EDIT_PRODUCT_COLOR_REQUEST,
                EDIT_PRODUCT_COLOR_RESPONSE,
                EDIT_PRODUCT_COLOR_ERROR,
            ],
            endpoint: `${ENDPOINTS.productsColors[getCurrentProductName()]}/` + form.id,
            schema: productColor,
            requestOptions: {
                method: "PUT",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(form),
            },
            success: (result) => {
                if (history.location.pathname.includes("colors")) {
                    history.push(buildNextUrl(getState()));
                }
            },
        },
    });

// ----------------------
// Color disable
// ----------------------

export const DISABLE_PRODUCT_COLOR_REQUEST = "DISABLE_PRODUCT_COLOR_REQUEST";
export const DISABLE_PRODUCT_COLOR_RESPONSE = "DISABLE_PRODUCT_COLOR_RESPONSE";
export const DISABLE_PRODUCT_COLOR_ERROR = "DISABLE_PRODUCT_COLOR_ERROR";

const disable = (colorId) => (dispatch) =>
    dispatch({
        [CALL_API]: {
            types: [
                DISABLE_PRODUCT_COLOR_REQUEST,
                DISABLE_PRODUCT_COLOR_RESPONSE,
                DISABLE_PRODUCT_COLOR_ERROR,
            ],
            endpoint: `${ENDPOINTS.productsColors[getCurrentProductName()]}/${colorId}`,
            schema: productColor,
            requestOptions: {
                method: "PUT",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ enabled: false }),
            },
            success: (result) => {
                dispatch(
                    enqueueSnackbarAction({
                        message: "Finition supprimée avec succès.",
                        colors: {
                            key: new Date().getTime() + Math.random(),
                            variant: "success",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            action: (key) => (
                                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                                    OK
                                </Button>
                            ),
                        },
                    })
                );
            },
        },
    });

export const productColorsActions = {
    invalidateProductColors,
    fetchProductColorsIfNeeded,
    add,
    edit,
    disable
};
