import { shouldFetch } from "../helpers/api";
import { setting } from "../helpers/schema";
import { CALL_API } from "../middleware/api";
import { projectsActions } from "./projects.actions";

// ----------------------
// Settings Fetching
// ----------------------

export const FETCH_SETTINGS_REQUEST = "FETCH_SETTINGS_REQUEST";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
export const INVALIDATE_SETTINGS = "INVALIDATE_SETTINGS";

function invalidateSettings() {
  return { type: INVALIDATE_SETTINGS };
}

function fetchSettingsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "settings")) {
      return dispatch(fetchSettings());
    }
  };
}

const fetchSettings = () => ({
  [CALL_API]: {
    types: [
      FETCH_SETTINGS_REQUEST,
      FETCH_SETTINGS_SUCCESS,
      FETCH_SETTINGS_FAILURE,
    ],
    endpoint: `settings?pagination=false`,
    schema: [setting],
  },
});

// ----------------------
// setting Fetching
// ----------------------

export const FETCH_SETTING_REQUEST = "FETCH_SETTING_REQUEST";
export const FETCH_SETTING_SUCCESS = "FETCH_SETTING_SUCCESS";
export const FETCH_SETTING_FAILURE = "FETCH_SETTING_FAILURE";

const fetchSetting = (id) => ({
  [CALL_API]: {
    types: [
      FETCH_SETTING_REQUEST,
      FETCH_SETTING_SUCCESS,
      FETCH_SETTING_FAILURE,
    ],
    endpoint: `settings/${id}`,
    schema: setting,
  },
});

// ----------------------
// Setting Edit
// ----------------------

export const EDIT_SETTING_REQUEST = "EDIT_SETTING_REQUEST";
export const EDIT_SETTING_RESPONSE = "EDIT_SETTING_RESPONSE";
export const EDIT_SETTING_ERROR = "EDIT_SETTING_ERROR";

const edit = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [EDIT_SETTING_REQUEST, EDIT_SETTING_RESPONSE, EDIT_SETTING_ERROR],
      endpoint: `settings/` + form.id,
      schema: setting,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify(form),
      },
      success: (result) => {
        dispatch(projectsActions.invalidateProjects());
        dispatch(projectsActions.fetchProjectsIfNeeded());
      },
      failure: (error) => {
        console.log(error);
      },
    },
  });

export const settingsActions = {
  invalidateSettings,
  fetchSettingsIfNeeded,
  edit,
};
