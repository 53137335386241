import { Box, CircularProgress } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Publish } from "@material-ui/icons";
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { UPLOAD_CSV_ERROR, UPLOAD_CSV_REQUEST, UPLOAD_CSV_RESPONSE, UPLOAD_ESTIMATIF_CSV_ERROR, UPLOAD_ESTIMATIF_CSV_REQUEST, UPLOAD_ESTIMATIF_CSV_RESPONSE } from "../../actions/csv.actions";
import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction
} from "../../actions/notifications.actions";
import FileUploader from "../../components/fileUploader";

export default function ImportCsvButton(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(false);
  }, [dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<Publish />} onClick={handleClickOpen}>
        {props.children}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Téléverser</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Attention, le téléversement du fichier implique la modification irréversible des tarifs dont la référence est présente dans ce fichier.
          </DialogContentText>
          <Button component="label" variant="outlined" color="primary" disabled={loading}>
            Sélectionner un fichier CSV
            <FileUploader
              start={props.entity === 'prices' ? UPLOAD_CSV_REQUEST : UPLOAD_ESTIMATIF_CSV_REQUEST}
              resolve={props.entity === 'prices' ? UPLOAD_CSV_RESPONSE : UPLOAD_ESTIMATIF_CSV_RESPONSE}
              reject={props.entity === 'prices' ? UPLOAD_CSV_ERROR : UPLOAD_ESTIMATIF_CSV_ERROR}
              onChange={(event) => {
                setLoading(true);
              }}
              onResult={(result) => {
                setLoading(false);
                if (result.error) {
                  enqueueSnackbarAction({
                    message: "Une erreur est survenue lors de l'import.",
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      action: (key) => (
                        <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                          OK
                        </Button>
                      ),
                    },
                  })
                }
                else {
                  setOpen(false);
                  dispatch(
                    enqueueSnackbarAction({
                      message: `Import réussi.`,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        action: (key) => (
                          <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                            OK
                          </Button>
                        ),
                      },
                    })
                  );
                }
                window.location.reload();
              }}
            />
          </Button>
          {loading &&
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
            >
              <CircularProgress />
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
