import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import FaceIcon from "@material-ui/icons/Face";
import React from "react";
import { useDispatch } from "react-redux";
import { usersActions } from "../../actions/users.actions";
import { humanReadableRoles } from "../../helpers/constante";
import MenuActions from "../MenuActions";
import ValidationModal from "../ValidationModal";

const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default function UsersList(props) {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseMenuList = (option, user) => {
    if (option === "Supprimer") {
      setSelectedUser(user);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUserDelete = () => {
    setEditing(true);

    dispatch(usersActions.disable(selectedUser.id)).then(() => {
      setEditing(false);
      setOpenModal(false);
    });
  };

  return (
    <>
      <ValidationModal
        loading={editing}
        onClose={handleCloseModal}
        onClick={handleUserDelete}
        open={openModal}
        title={"Êtes-vous sûr de vouloir supprimer ce client ?"}
        paragraph={"Sa suppression est définitive."}
      />

      <List>
        {props.users.map((user, i) => (
          <div key={i}>
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <FaceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Typography>
                    {`${user.firstName} ${user.lastName}`}
                  </Typography>
                }
                secondary={
                  <Box display="flex">
                    <Grid className={classes.textSecondary} container>
                      <Grid item xs={4}>
                        <Typography variant={"body2"}>{user.email}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {user.phone}
                      </Grid>
                      <Grid item xs={2}>
                        {user.roles.map(role => {
                          if (!role.startsWith('ROLE_PRODUCT') && humanReadableRoles[role]) {
                            return <Chip
                              variant="outlined"
                              color="primary"
                              size="small"
                              label={humanReadableRoles[role]}
                            />
                          }
                        })}
                        {!humanReadableRoles[user.roles[0]]
                          ? console.log(user)
                          : ""}
                      </Grid>
                      <Grid item xs={4}>
                        {user.roles.map(role => {
                          if (role.startsWith('ROLE_PRODUCT') && humanReadableRoles[role]) {
                            return <Chip
                              variant="outlined"
                              color="secondary"
                              size="small"
                              label={humanReadableRoles[role].toUpperCase()}
                            />
                          }
                        })}
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                <MenuActions
                  onClose={(option) => handleCloseMenuList(option, user)}
                  options={{
                    show: {
                      name: "Voir",
                      link: `/users/${user.id}/show`,
                    },
                    edit: {
                      name: "Editer",
                      link: `/users/${user.id}/edit`,
                    },
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        ))}
      </List>
    </>
  );
}
