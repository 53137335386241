import { getCurrentProductName } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constante";
import { media } from "../helpers/schema";
import { CALL_API } from "../middleware/api";

// ----------------------
// Media Add
// ----------------------

export const ADD_MEDIA_REQUEST = "ADD_MEDIA_REQUEST";
export const ADD_MEDIA_RESPONSE = "ADD_MEDIA_RESPONSE";
export const ADD_MEDIA_ERROR = "ADD_MEDIA_ERROR";

const add =
  (file, fileName = null, projectId = null) =>
  (dispatch) => {
    let data = new FormData();
    data.append("file", file, fileName || file.name);
    data.append("projectId", projectId);
    return dispatch({
      [CALL_API]: {
        types: [ADD_MEDIA_REQUEST, ADD_MEDIA_RESPONSE, ADD_MEDIA_ERROR],
        endpoint: `${ENDPOINTS.mediaObjects[getCurrentProductName()]}`,
        schema: media,
        requestOptions: {
          method: "POST",
          body: data,
        },
      },
    });
  };

export const mediaActions = {
  add,
};
