import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { Radios, Select, TextField } from "mui-rff";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch } from "react-redux";
import { productsActions } from "../../../../actions/product.actions";
import ImgUploader from "../../../../components/imgUploader";
import { AREAS, PITCHES, THICKNESSES } from "../../../../helpers/constante";

const formFields = [
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <TextField label="Reference" name="reference" margin="none" required />
    ),
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: <TextField label="Nom" name="name" margin="none" required />,
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: <TextField label="Longueur maximum" name="maxLength" margin="none" type="number" required />,
  },
];

export default function ProductsForm(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productsActions.fetchProductsIfNeeded());
  }, [dispatch]);

  const optionsData = props.productOptions.map(option => {
    return {
      label: option.name,
      value: option.id.toString()
    };
  });

  const pitchData = PITCHES.flatMap(pitch => {
    if (pitch === '0') return [];

    return [{
      label: `${pitch}mm`,
      value: pitch
    }];
  });

  const thicknessData = THICKNESSES.map(thickness => {
    return {
      label: `${thickness}mm`,
      value: thickness
    };
  });

  const areaData = AREAS.map(area => {
    return {
      label: area.label,
      value: area.area
    };
  });

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={8} container spacing={2}>
        {formFields.map((item, idx) => (
          <Grid
            className={"mb-4"}
            item
            md={item.mdSize}
            xs={item.xsSize}
            key={idx}
          >
            {item.field}
          </Grid>
        ))}

        <Grid
          className={"mb-4"}
          xs={12}
        >
          <Radios
            label="Option"
            name="options"
            formControlProps={{
              margin: "normal",
            }}
            radioGroupProps={{
              row: true,
            }}
            data={optionsData}
          />

        </Grid>
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        container
        alignItems={"center"}
        justify={"center"}
      >
        <Grid item lg={8} md={10} xs={8}>
          <ImgUploader
            image={props.image}
            beforeText="Ajouter un icon"
            afterText="Modifier l'icon"
            onResult={(result) => props.setValue("image", result.id)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <FieldArray name="variants">
          {({ fields }) => (
            <>
              {fields.map((variantName, variantIndex) => (
                <ExpansionPanel key={variantIndex}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Variante :&nbsp;
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => `${values.variants[variantIndex].name} - ${AREAS.find(e => e.area === values.variants[variantIndex].area)?.label}`}
                      </FormSpy>
                    </Typography>
                  </ExpansionPanelSummary>
                  <Box p={2}>
                    <TextField
                      label="Nom"
                      name={`${variantName}.name`}
                      margin="none"
                      required
                    />
                    <TextField
                      label="Référence"
                      name={`${variantName}.reference`}
                      margin="none"
                      required
                    />
                    <Radios
                      label="Pas du produit"
                      name={`${variantName}.pitch`}
                      data={pitchData}
                      radioGroupProps={{
                        row: true,
                      }}
                    />
                    <Select
                      label="Epaisseur"
                      name={`${variantName}.thickness`}
                      data={thicknessData}
                      required
                    />
                    <Radios
                      label="Quantité en m²"
                      name={`${variantName}.area`}
                      data={areaData}
                    />
                  </Box>
                </ExpansionPanel>
              ))}
            </>
          )}
        </FieldArray>
      </Grid>
    </Grid>
  );
}

ProductsForm.propTypes = {
  setValue: PropTypes.func.isRequired,
};
