import Button from "@material-ui/core/Button";
import React from "react";
import { getCurrentProductName, shouldFetch } from "../helpers/api";
import { buildNextUrl, history } from "../helpers/history";
import { accessory } from "../helpers/schema";
import { CALL_API } from "../middleware/api";
import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction,
} from "./notifications.actions";
import { ENDPOINTS } from "../helpers/constante";

// ----------------------
// Accessories Fetching
// ----------------------
export const FETCH_ACCESSORIES_REQUEST = "FETCH_ACCESSORIES_REQUEST";
export const FETCH_ACCESSORIES_SUCCESS = "FETCH_ACCESSORIES_SUCCESS";
export const FETCH_ACCESSORIES_FAILURE = "FETCH_ACCESSORIES_FAILURE";
export const INVALIDATE_ACCESSORIES = "INVALIDATE_ACCESSORIES";

function invalidateAccessories() {
  return { type: INVALIDATE_ACCESSORIES };
}

function fetchAccessoriesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "accessories")) {
      return dispatch(fetchAccessories());
    }
  };
}

// sub actions
const fetchAccessories = () => ({
  [CALL_API]: {
    types: [
      FETCH_ACCESSORIES_REQUEST,
      FETCH_ACCESSORIES_SUCCESS,
      FETCH_ACCESSORIES_FAILURE,
    ],
    endpoint: `${ENDPOINTS.accessories[getCurrentProductName()]}?pagination=false`,
    schema: [accessory],
  },
});

// ----------------------
// Accessory Create
// ----------------------

export const ADD_ACCESSORY_REQUEST = "ADD_ACCESSORY_REQUEST";
export const ADD_ACCESSORY_RESPONSE = "ADD_ACCESSORY_RESPONSE";
export const ADD_ACCESSORY_ERROR = "ADD_ACCESSORY_ERROR";

const add = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        ADD_ACCESSORY_REQUEST,
        ADD_ACCESSORY_RESPONSE,
        ADD_ACCESSORY_ERROR,
      ],
      endpoint: ENDPOINTS.accessories[getCurrentProductName()],
      schema: accessory,
      requestOptions: {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify(form),
      },
      success: (result) => {
        if (history.location.pathname.includes("accessories")) {
          history.push(buildNextUrl(getState()));
        }
      },
    },
  });

// ----------------------
// Accessory Edit
// ----------------------

export const EDIT_ACCESSORY_REQUEST = "EDIT_ACCESSORY_REQUEST";
export const EDIT_ACCESSORY_RESPONSE = "EDIT_ACCESSORY_RESPONSE";
export const EDIT_ACCESSORY_ERROR = "EDIT_ACCESSORY_ERROR";

const edit = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        EDIT_ACCESSORY_REQUEST,
        EDIT_ACCESSORY_RESPONSE,
        EDIT_ACCESSORY_ERROR,
      ],
      endpoint: `${ENDPOINTS.accessories[getCurrentProductName()]}/` + form.id,
      schema: accessory,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify(form),
      },
      success: (result) => {
        if (history.location.pathname.includes("accessories")) {
          history.push(buildNextUrl(getState()));
        }
      },
    },
  });

// ----------------------
// Accessory disable
// ----------------------

export const DISABLE_ACCESSORY_REQUEST = "DISABLE_ACCESSORY_REQUEST";
export const DISABLE_ACCESSORY_RESPONSE = "DISABLE_ACCESSORY_RESPONSE";
export const DISABLE_ACCESSORY_ERROR = "DISABLE_ACCESSORY_ERROR";

const disable = (accessoryId) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      types: [
        DISABLE_ACCESSORY_REQUEST,
        DISABLE_ACCESSORY_RESPONSE,
        DISABLE_ACCESSORY_ERROR,
      ],
      endpoint: `${ENDPOINTS.accessories[getCurrentProductName()]}/${accessoryId}`,
      schema: accessory,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ enabled: false }),
      },
      success: (result) => {
        dispatch(
          enqueueSnackbarAction({
            message: "Accessoire supprimé avec succès.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              action: (key) => (
                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                  OK
                </Button>
              ),
            },
          })
        );
      },
    },
  });

export const accessoriesActions = {
  invalidateAccessories,
  fetchAccessoriesIfNeeded,
  add,
  edit,
  disable,
};
