import { LOGIN_USERS_REQUEST, LOGIN_USERS_FAILURE, LOGIN_USERS_SUCCESS, LOGOUT_USERS } from '../actions/users.actions';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USERS_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case LOGIN_USERS_SUCCESS:
            return {
                loggedIn: true,
                loggingIn: false,
                user: action.user
            };
        case LOGIN_USERS_FAILURE:
            return {};
        case LOGOUT_USERS:
            return {};
        default:
            return state
    }
}