import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field } from "react-final-form";
import {
  ADD_MEDIA_ERROR,
  ADD_MEDIA_REQUEST,
  ADD_MEDIA_RESPONSE,
} from "../actions/media.actions";
import FileUploader from "./fileUploader";

const useStyles = makeStyles((theme) => ({
  imgResponsive: {
    maxWidth: "100%",
    height: "auto",
  },
  btnUpload: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  relative: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function ImgUploader(props) {
  const classes = useStyles();
  const [image, setImage] = useState("");
  let { onResult, beforeText, afterText } = props;

  if (!image && !image.endsWith(props.image) && props.image) {
    setImage(process.env.REACT_APP_API_URL + props.image);
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      {image && (
        <img alt={"thumbnail"} src={image} className={classes.imgResponsive} />
      )}
      <Box mt={image ? 1 : 0} display="flex" justifyContent="center">
        <Field name="image" component="input" type="hidden" />
        <Button component="label" variant="outlined" color="primary">
          {image ? afterText : beforeText}
          <FileUploader
            start={ADD_MEDIA_REQUEST}
            resolve={ADD_MEDIA_RESPONSE}
            reject={ADD_MEDIA_ERROR}
            onResult={(result) => {
              // form.mutators.setValue("image", result.id);
              onResult(result);
              setImage(process.env.REACT_APP_API_URL + result.contentUrl);
            }}
          />
        </Button>
      </Box>
    </Box>
  );
}

ImgUploader.propTypes = {
  onResult: PropTypes.func.isRequired,
  beforeText: PropTypes.string.isRequired,
  afterText: PropTypes.string.isRequired,
};
