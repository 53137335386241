import { shouldFetch } from "../helpers/api";
import { accessory } from "../helpers/schema";
import { CALL_API } from "../middleware/api";

// ----------------------
// Accessories Fetching
// ----------------------
export const FETCH_ACCESSORY_CATEGORIES_REQUEST =
  "FETCH_ACCESSORY_CATEGORIES_REQUEST";
export const FETCH_ACCESSORY_CATEGORIES_SUCCESS =
  "FETCH_ACCESSORY_CATEGORIES_SUCCESS";
export const FETCH_ACCESSORY_CATEGORIES_FAILURE =
  "FETCH_ACCESSORY_CATEGORIES_FAILURE";
export const INVALIDATE_ACCESSORY_CATEGORIES =
  "INVALIDATE_ACCESSORY_CATEGORIES";

function invalidateAccessoryCategories() {
  return { type: INVALIDATE_ACCESSORY_CATEGORIES };
}

function fetchAccessoryCategoriesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "accessoryCategories")) {
      return dispatch(fetchAccessoryCategories());
    }
  };
}

// sub actions
const fetchAccessoryCategories = () => ({
  [CALL_API]: {
    types: [
      FETCH_ACCESSORY_CATEGORIES_REQUEST,
      FETCH_ACCESSORY_CATEGORIES_SUCCESS,
      FETCH_ACCESSORY_CATEGORIES_FAILURE,
    ],
    endpoint: `accessory_categories?pagination=false`,
    schema: [accessory],
  },
});

export const accessoryCategoriesActions = {
  invalidateAccessoryCategories,
  fetchAccessoryCategoriesIfNeeded,
};
