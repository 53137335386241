import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, Box, CircularProgress, Fab } from "@material-ui/core";
import { productOptionsActions } from "../../../../actions/productOption.actions";
import OptionsList from "../../../../components/options/OptionsList";
import OptionsEdit from "./OptionsEdit";
import HeaderTitle from "../../../../components/HeaderTitle";
import SearchList from "../../../../components/searchList";
import { filterElement } from "../../../../helpers/schema";

export default function Index(props) {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = React.useState("");

  const isFetching = useSelector((state) => {
    return state.productOptions.isFetching;
  }, shallowEqual);

  const productOptions = useSelector((state) => {
    return Object.values(state.productOptions.all) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(productOptionsActions.fetchProductOptionsIfNeeded());
  }, [dispatch, isFetching]);

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(productOptionsActions.invalidateProductOptions());
    dispatch(productOptionsActions.fetchProductOptionsIfNeeded());
  };

  const onSearch = (search) => {
    setSearchFilter(search);
  };

  return (
    <Switch>
      <Route exact path="/thermo/options">
        <HeaderTitle
          mb={3}
          title="Options"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
          bottom={<SearchList onSearch={onSearch} />}
        />
        {isFetching && Object.entries(productOptions).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        <div className={"options-container"} style={{ height: "100%" }}>
          {!isFetching && Object.entries(productOptions).length === 0 && (
            <h2>Aucune donnée disponible.</h2>
          )}

          {Object.entries(productOptions).length > 0 && (
            <OptionsList
              options={Object.values(productOptions).filter((item) =>
                filterElement(item, ["name"], searchFilter)
              )}
            />
          )}
        </div>
      </Route>
      <Route
        path={"/thermo/options/:optionId/edit"}
        children={<OptionsEdit />}
      />
      <Route>
        <Redirect to={"/thermo/options"} />
      </Route>
    </Switch>
  );
}
