import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Paper,
  Popover,
  Slider,
  Typography
} from "@material-ui/core";
import {
  GetApp,
  Help
} from "@material-ui/icons";
import WarningIcon from '@material-ui/icons/Warning';
import Alert from "@material-ui/lab/Alert";
import { TextField } from "mui-rff";
import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { Document, Page } from "react-pdf";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { useParams } from "react-router-dom";
import { appActions } from "../../../../actions/app.actions";
import { productColorsActions } from "../../../../actions/productColor.actions";
import { productOptionsActions } from "../../../../actions/productOption.actions";
import {
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_RESPONSE,
  projectsActions,
} from "../../../../actions/projects.actions";
import SendMailModal from "../../../../components/SendMailModal";
import StepperButtons from "../../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../../components/projects/StepperHeader";
import { useStyles } from "../../../../helpers/project";
import { selectProject } from "../../../../helpers/schema";
import { promiseListener } from "../../../../helpers/store";
import { getUser } from "../../../../helpers/user";

export default function Step8() {
  let { projectId } = useParams();
  let submit;

  const classes = useStyles();

  const dispatch = useDispatch();
  const [activeStep] = React.useState(7);
  const [quotationPdf, setQuotationPdf] = useState({});
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfPagesNum, setPdfPagesNum] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [outdated, setOutdated] = useState(true);
  const [generatingQuotation, setGeneratingQuotation] = useState(false);
  const [displayAlertOutdated, setDisplayAlertOutdated] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showConfirmRecalculateModal, setShowConfirmRecalculateModal] =
    useState(false);
  const [showSendMailModal, setShowSendMailModal] = useState(false);

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const isGeneratingQuotation = useSelector((state) => {
    return state.projects.isGeneratingQuotation;
  });

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  }, shallowEqual);

  const product = project.productVariant?.product;

  const formatterEuro = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  });

  const productColors = useSelector((state) => {
    return Object.values(state.productColors.all) || [];
  }, shallowEqual);

  const productOptions = useSelector((state) => {
    return Object.values(state.productOptions.all) || [];
  }, shallowEqual);

  const currentOption = productOptions?.find(productOption => productOption.id === project.productVariant.product.options.id);
  const currentColor = project.productColor;

  useEffect(() => {
    dispatch(appActions.setCurrentProject(projectId));
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(productColorsActions.fetchProductColorsIfNeeded());
    dispatch(productOptionsActions.fetchProductOptionsIfNeeded());
  }, [dispatch, projectId, isFetchingProject, project.priceMargin]);

  const isUpdatingProject = useSelector((state) => {
    return state.projects.isUpdating;
  });

  const handleClose = () => {
    setOpen(false);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setPdfPagesNum(numPages);
  }

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {!isFetchingProject && project.name && (
        <MakeAsyncFunction
          listener={promiseListener}
          start={EDIT_PROJECT_REQUEST}
          resolve={EDIT_PROJECT_RESPONSE}
          reject={EDIT_PROJECT_ERROR}
        >
          {(onSubmit) => (
            <Form
              initialValues={{ priceMargin: project.priceMargin, shippingPrice: project.shippingPrice ? project.shippingPrice : project.productVariant.areaMin === 300 ? 0 : 140 }}
              onSubmit={(form) => {
                form.shippingPrice = parseFloat(form.shippingPrice);

                return onSubmit(form);
              }}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                form,
                handleSubmit,
                submitting,
                values,
                pristine,
              }) => {
                submit = handleSubmit;

                return (
                  <div className={classes.root}>
                    <StepperHeader
                      last
                      submit={submit}
                      activeStep={activeStep}
                      projectId={parseInt(projectId)}
                      submitting={submitting}
                      shouldLink={pristine}
                    />
                    <Container maxWidth="lg">
                      <Grid className={"mb-4"} item xs={12}>
                        <Box
                          mt={2}
                          mb={4}
                          display="flex"
                          justifyContent="center"
                        >
                          <Chip
                            label={project.name + " (#" + projectId + ")"}
                            color="primary"
                            size="medium"
                            variant={"outlined"}
                          />
                        </Box>

                        <Grid container spacing={2} justify="center">
                          <Grid item xs={12} md={6}>
                            <Paper>
                              <Box display="flex" flexDirection="column">
                                <Box
                                  mt={1}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Typography variant="body1">
                                    Produit
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    display="flex"
                                    flexGrow={1}
                                    justifyContent="center"
                                    alignItems="start"
                                    flexDirection="column"
                                    m={2}
                                  >
                                    <strong>
                                      {product.name}
                                    </strong>
                                    <p className={classes.mt1}>
                                      Pas : {project.pitch}mm<br/>
                                      Finition : {currentOption?.name}<br/>
                                      Option : {currentColor.name}<br/>
                                      Epaisseur : {project.productVariant.thickness}mm
                                    </p>
                                  </Box>
                                </Box>
                              </Box>
                            </Paper>

                            {['R', 'TR'].includes(currentOption?.reference) &&
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  marginTop="1em"
                                >
                                  <WarningIcon />
                                  <Typography>Panneau renforcé choisi, la vérification des portées restera à faire en demandant la fiche technique du panneau retenu.</Typography>
                                </Box>
                              </Grid>
                            }

                            {!['R', 'TR'].includes(currentOption?.reference) &&
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  marginTop="1em"
                                >
                                  <WarningIcon />
                                  <Typography>{currentColor.name.includes('PLR') ? 'Prendre le tableau de portée Polyester (Point H de la fiche de renseignement)' : 'Prendre le tableau de portée Polycarbonate (Point I de la fiche de renseignement)'}</Typography>
                                </Box>
                              </Grid>
                            }
                          </Grid>
                        </Grid>

                        <Box mt={2} mb={2}>
                          <Divider m={3} />
                        </Box>


                        <Grid container className={"mb-4"} item xs={12}>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow={1}
                          >

                            <Box
                              display="flex"
                              justifyContent="center"
                              textAlign="center"
                              m={1}
                            >
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                pr={2}
                                flexBasis={0}
                                flexGrow={1}
                              >
                                <Button
                                  aria-describedby={id}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleClickPopover}
                                >
                                  <Help /> <Box ml={1}>Aide</Box>
                                </Button>
                              </Box>
                              <Typography variant="h6">
                                Frais de transport
                              </Typography>
                              <Box flexBasis={0} flexGrow={1}></Box>
                              <Popover
                                id={id}
                                open={popoverOpen}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Box p={2}>
                                  <Box textAlign="center">
                                    <span>
                                      Les frais sont offerts si le nombre de m² est supérieur à 300
                                    </span>
                                  </Box>
                                </Box>
                              </Popover>
                            </Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="center"
                              flexGrow={1}
                            >
                              <Grid item md={4} xs={4} offset={4}>
                                <Box p={2} textAlign="center">
                                  <TextField
                                      label="Prix"
                                      name="shippingPrice"
                                    />
                                </Box>
                              </Grid>
                            </Box>
                          </Box>
                          </Grid>
                        </Grid>

                        <Box mt={2} mb={2}>
                          <Divider m={3} />
                        </Box>

                                  <div className={classes.root}>
                                    <Grid item xs={12} md={12}>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Box
                                          mt={1}
                                          mb={5}
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <Typography variant="h6">
                                            Marge appliquée
                                          </Typography>
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <Slider
                                            aria-labelledby="price-margin"
                                            valueLabelDisplay="on"
                                            value={values.priceMargin}
                                            onChange={(event, newValue) =>
                                              form.mutators.setValue(
                                                "priceMargin",
                                                newValue
                                              )
                                            }
                                            min={-10}
                                            max={100}
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <Box mt={2} mb={2}>
                                        <Divider m={3} />
                                      </Box>
                                    </Grid>


                                    <Box
                                      md={12}
                                      mt={4}
                                      mb={4}
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <Box mr={1}>
                                        <Button
                                          variant="contained"
                                          disabled={
                                            loadingPdf
                                          }
                                          color={"primary"}
                                          onClick={() => {
                                            setLoadingPdf(true);
                                            dispatch(
                                              projectsActions.getQuotation(
                                                projectId
                                              )
                                            ).then((data) => {
                                              setQuotationPdf({
                                                type: "QUOTATION_CODIFIED",
                                                file: window.URL.createObjectURL(
                                                  data.payload
                                                ),
                                                name: `${project.name}-${project.id}-devis-definitif.pdf`,
                                              });
                                              setOpen(true);
                                              setLoadingPdf(false);
                                            }).catch(error => setLoadingPdf(false));
                                          }}
                                        >
                                          {!loadingPdf && (
                                            <>
                                              <GetApp /> Fiche de renseignement
                                            </>
                                          )}
                                          {loadingPdf && (
                                            <CircularProgress size={20} />
                                          )}
                                        </Button>
                                      </Box>
                                    </Box>
                                  </div>

                        <StepperButtons
                          submit={submit}
                          activeStep={activeStep}
                          projectId={projectId}
                          submitting={submitting}
                          last
                        />

                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={false}
                        maxWidth={"xl"}
                      >
                        <DialogContent>
                          {quotationPdf?.file && (
                            <>
                              <Document
                                file={quotationPdf?.file}
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                {[...Array(pdfPagesNum)].map((x, i) => (
                                  <Fragment key={i}>
                                    <Page pageNumber={i + 1} />
                                    {i + 1 < pdfPagesNum && <hr />}
                                  </Fragment>
                                ))}
                              </Document>
                            </>
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              setShowSendMailModal(true);
                              handleClose();
                            }}
                            color="primary"
                          >
                            Envoyer par mail
                          </Button>
                          <Button
                            onClick={() => {
                              let anchor = document.createElement("a");
                              anchor.download = quotationPdf?.name;
                              anchor.href = quotationPdf?.file;
                              anchor.dataset.downloadurl = [
                                "application/pdf",
                                anchor.download,
                                anchor.href,
                              ].join(":");
                              anchor.click();
                            }}
                            color="primary"
                          >
                            Télécharger
                          </Button>
                          <Button
                            onClick={handleClose}
                            color="primary"
                            autoFocus
                          >
                            Fermer
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <SendMailModal
                        open={showSendMailModal}
                        defaultValue={getUser()?.decoded?.username}
                        type={quotationPdf?.type}
                        projectId={projectId}
                        onClose={() => setShowSendMailModal(false)}
                      />
                      </Grid>
                    </Container>
                  </div>
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      )}
    </>
  );
}
