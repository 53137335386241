import { Grid, InputAdornment } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { Checkboxes, TextField } from "mui-rff";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch } from "react-redux";
import { productsActions } from "../../../actions/product.actions";
import ImgUploader from "../../../components/imgUploader";
import { history } from "../../../helpers/history";

const endAdornment = <InputAdornment position="start">€</InputAdornment>;

const formFields = [
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <TextField label="Reference" name="reference" margin="none" required />
    ),
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: <TextField label="Nom" name="name" margin="none" required />,
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: <TextField label="Longueur maximum" name="maxLength" margin="none" type="number" required />,
  },
];

export default function ProductsForm(props) {
  const dispatch = useDispatch();
  const editing = history.location.pathname.includes("edit");

  useEffect(() => {
    dispatch(productsActions.fetchProductsIfNeeded());
  }, [dispatch]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={8} container spacing={2}>
        {formFields.map((item, idx) => (
          <Grid
            className={"mb-4"}
            item
            md={item.mdSize}
            xs={item.xsSize}
            key={idx}
          >
            {item.field}
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        container
        alignItems={"center"}
        justify={"center"}
      >
        <Grid item lg={8} md={10} xs={8}>
          <ImgUploader
            image={props.image}
            beforeText="Ajouter un icon"
            afterText="Modifier l'icon"
            onResult={(result) => props.setValue("image", result.id)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Checkboxes
          label="Couleurs"
          name="colors"
          color="primary"
          required={false}
          data={props.productColors.map((color) => ({
            label: color.name,
            value: color.id,
          }))}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FieldArray name="variants">
          {({ fields }) => (
            <>
              {fields.map((variantName, variantIndex) => (
                <ExpansionPanel key={variantIndex}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Variante :&nbsp;
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => values.variants[variantIndex].name}
                      </FormSpy>
                    </Typography>
                  </ExpansionPanelSummary>
                  <Box p={2}>
                    <TextField
                      label="Nom"
                      name={`${variantName}.name`}
                      margin="none"
                      required
                    />
                    <TextField
                      label="Référence"
                      name={`${variantName}.reference`}
                      margin="none"
                      required
                    />
                    <TextField
                      label="Largeur visible"
                      name={`${variantName}.visibleWidth`}
                      margin="none"
                      required
                    />
                    <TextField
                      label="Largeur"
                      name={`${variantName}.width`}
                      margin="none"
                      required
                    />
                    <TextField
                      label="Ondes"
                      name={`${variantName}.wave`}
                      margin="none"
                      required
                    />
                  </Box>
                </ExpansionPanel>
              ))}
            </>
          )}
        </FieldArray>
      </Grid>
    </Grid>
  );
}

ProductsForm.propTypes = {
  setValue: PropTypes.func.isRequired,
};
