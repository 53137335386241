import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from '../reducers/_root.reducer'
import * as schema from './schema';
import createReduxPromiseListener from 'redux-promise-listener'
import api from "../middleware/api";
import form from "../middleware/form";
import { composeWithDevTools } from "redux-devtools-extension";

const reduxPromiseListener = createReduxPromiseListener()
const loggerMiddleware = createLogger()

let middlewares = [
    form,
    thunkMiddleware.withExtraArgument({ schema }),
    api,
    reduxPromiseListener.middleware
]

if (process.env.NODE_ENV !== 'production') {
    middlewares = [ ...middlewares, loggerMiddleware ]
}

export default function configureStore(preloadedState) {
    return createStore(
        rootReducer,
        preloadedState,
        composeWithDevTools(applyMiddleware(
            ...middlewares
        ))
    )
}

export const promiseListener = reduxPromiseListener;